import { AuditLogUtils } from "./../../../utils/audit-log.util";
import { PrivMaskGuard } from "./../../../guards/access-privs.guard";
import {
    Component,
    OnInit,
    Input,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    Output,
    EventEmitter,
    HostListener
} from "@angular/core";
import { PatientInfo } from "src/app/models/patient-Info.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    FormGroup,
    FormBuilder,
    FormControl,
    FormArray,
    Validators
} from "@angular/forms";
import { NotesLogService, AlertService, UserService, CommonService, ReportsService, DrugService } from "src/app/services";
import { ActivatedRoute, Router } from "@angular/router";
import {
    Note,
    NotesLog,
    NoteCat,
    AccCategoryE,
    PatientNotesLogE,
    PrescriberNotesLogE,
    DrugNotesLogE,
    InsuranceNotesLogE,
    EPatient,
    EPrescriber,
    EDrug, RxNotesLogE, eRxRphNotesE,
    PaginationModel
} from "src/app/models";
import * as moment from "moment";
import { Observable, Subject } from "rxjs";
import {
    debounceTime,
    distinctUntilChanged,
    map,
    filter,
    takeUntil
} from "rxjs/operators";
// import { FilterPipe } from "ngx-filter-pipe";
import * as _ from "lodash";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { searchTrackPatientCriteria } from "../../../models/track-patient.model";
import { CommonUtil, NewAuditUtils, ValidateFormFieldsUtils } from "src/app/utils";
import { MsgConfig } from "src/app";
import { DomSanitizer } from "@angular/platform-browser";
import { saveAs } from 'file-saver';
import { PrintService } from "../../../services/print.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { FormatsUtil } from "src/app/utils/formats.util";
import { MsgHoverOverConfig } from "src/app/app.hover-over";
@Component({
    selector: "app-notes-log",
    templateUrl: "./notes-log.component.html"
})
export class NotesLogComponent implements OnInit, AfterViewInit, OnDestroy {
    paginationModel: PaginationModel = new PaginationModel();
    prescriberInfo: any;
    prescriberId: number;
    openAsModal = false;
    rxInfo;
    popUpScreenList =  [{Name:'All', Id: 0, type:'all'}, {Name: 'Edit Rx', Id: 4, type:'er'}, {Name:'Enter Rx', Id: 1, type:'nr'}, {Name: 'Refill', Id: 2, type:'rf'},{Name: 'Create New', Id: 4, type:'cn'}]
    any;
    accessPrivCate: any;
    PatnotesPrivs: any;
    DrugnotesPrivs: any;
    InsnotesPrivs: any;
    PrenotesPrivs: any;
    ErxnotesPrivs: any;
    originalData: any;
    totalcount = 0;
    today: string;
    exportFG: FormGroup;
    minDate: { year: number; month: number; day: number };
    startdate: string;
    userFullName: any;
    IsSubmitted = false;
    categoryId: any;
    popupType: any;
    privType: any;
    openOverride: boolean;
    savedValue: any;
    overrideFor: any;
    overrideEndpoint: any;
    exportNotes: boolean;
    IsDeleteNotes: boolean;
    hasADDInsuNotesPrivs: boolean;
    hasADDPatNotesPrivs: boolean;
    hasADDPrescNotesPrivs: boolean;
    hasADDDrugNotesPrivs: boolean;
    hasChangInsNotesPrivs: boolean;
    hasChangDrugNotesPrivs: boolean;
    hasChangPatNotesPrivs: boolean;
    hasChangPresNotesPrivs: boolean;
    hasDeltInsuNotesPrivs: boolean;
    hasDeltDrugNotesPrivs: boolean;
    hasDeltPatNotesPrivs: boolean;
    hasDeltPresNotesPrivs: boolean;
    hasEditErxNotesPrivs : boolean;
    hasDeleteErxNotesPrivs : boolean;
    hasADDErxNotesPrivs :boolean;
    notesInfoFor:string;
    modalRefPrint: any;
    modalRefNote: any;
    notesLogFormattedData: any[] = [];
    hasADDRxNotesPrivs: boolean;
    hasDeltRxNotesPrivs: boolean;
    hasChangRxNotesPrivs: boolean;
    isprint: boolean;
    exportedDataFG: FormGroup;
    exportData: any;
    showPreview: boolean;
    pdfContent: any;
    exportedData: any;
    PrescAddress: any;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();
    subCatList: any = [];
    notesList: any = [];
    
    @Input()
    set OpenAsModal(oa: boolean) {
        this.openAsModal = oa;
    }

    @Input()
    set RxInfo(ri) {
        this.rxInfo = ri;
        if(this.rxInfo && this.rxInfo.Prescription){
            this.notesInfoFor =
            this.rxInfo.Prescription.PrescNum + "-" + this.rxInfo.PrescReFill.ReFillNum +
            (this._commonServ.checkIsPartialFill(this.rxInfo.PrescReFill, this.rxInfo.Prescription) ? "-" + this.rxInfo.PrescReFill.PartialFillNo : " ");
        }
    }

    @Input()
    set PatientInfo(pi: any) {
        this.patientInfo = pi;
        if (this.patientInfo && this.patientInfo.Person && this.patientInfo.Person.LastName) {
            this.notesInfoFor = this.patientInfo.Person.LastName + ", " + this.patientInfo.Person.FirstName;
        } else if (this.patientInfo && this.patientInfo.patientid) {
            this.notesInfoFor = this.patientInfo.lastname + ", " + this.patientInfo.firstname;
        }
    }

    @Input()
    set PrescriberInfo(pi: any) {
        this.prescriberInfo = pi;
    }
    @Input()
    set ErxInfo(ei: any) {
        this.erxInfo = ei;
        if (this.erxInfo) {
            this.notesInfoFor = this.erxInfo?.PatientName;
            this.Id = this.erxInfo.Id;
        }
    }

    @Input()
    set InsuGp(ig: any) {
        this.insuInfo = ig;
    }

    @Input()
    set DrugInfo(drugInfo: any) {
        this.drugInfo = drugInfo;
    }

    @Input()
    set NotesInfoFor(id: any) {
        this.notesInfoFor = id;
    }

    @Input() NotesFr: any;
    @Input() IsFromRxHistory: any;
    @Input() NotesListBeforeSave: any;
    @Input() IsFromRxSave: any;
    NotesAvailability: boolean = true;
    @Input()  NotesListForRxAnnotation: any;
  
    
    @Output()
    CloseNotesValue = new EventEmitter();

    @Output()
    CloseNotesValueWithList = new EventEmitter();
    @Output()
    CloseNotesValueList = new EventEmitter();

    @Output()
    emitFocusOnCancel = new EventEmitter<any>();

    @Output()
    RefreshRxHistory = new EventEmitter<any>();

    @Output()  CloseEditMode = new EventEmitter<any>();

    @ViewChild("categoryEdit")
    categoryEdit: ElementRef;
    @ViewChild("patientnoteslog")
    patientnoteslog: ElementRef;
    @ViewChild("notesData", { static: true })
    flex: wjcGrid.FlexGrid;

    @ViewChild("notesLogModal", { static: true })
    notesLogModal: any;

    @ViewChild("addnoteslog", { static: true })
    addnoteslog: any;

    modelRef: any;
    patientInfo: any;
    erxInfo: any;
    notesLogFG: FormGroup;
    patientId: number;
    xmlFiledId: number;
    planId: number;
    notesLogInfo: any;
    notesSubcatList: any;
    categoryName: string[] = [];
    addmode = false;
    editmode = false;
    index: number;
    showEdit: any  =[];
    showAdd: any  =[];
    showTrackChanges: any  =[];
    userFilter: any = { xNote: "" };
    getCatgryName: string;
    router: string;
    parentPage: string;
    Id: number;
    insuInfo: any;
    insuPlanId: number;
    catgryName: string;
    transistion = false;
    drugInfo: any;
    drugId: number;
    notesLogWJ: CollectionView;
    showForm = false;
    modalrefSM: any;
    noteId: number;
    dateFrom = "1973-01-01";
    dateTo: string = null;
    searchParams: searchTrackPatientCriteria;
    notesLogFilteredWJ: CollectionView;
    hideWJ = false;
    hasFilteredData: boolean;
    modalRef: any;
    categoryModel: any = null;
    notesTrackHistory: any  = [];

    categoryList: any  = [];
    addbuttonHover:any;
editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '50px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Please Enter Notes',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      toolbarHiddenButtons: [
        ['undo',
        'redo',
        'underline',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'],
        [
            'fontSize',
            'textColor',
            'backgroundColor',
            'customClasses',
            'link',
            'unlink',
            'insertImage',
            'insertVideo',
            'insertHorizontalRule',
            'removeFormat',
            'toggleEditorMode'
          ]
      ]
};

    get Note(): FormArray {
        return this.notesLogFG.get("Note") as FormArray;
    }

    get NoteCat(): FormArray {
        return this.notesLogFG.get("NoteCat") as FormArray;
    }

    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent) {
        //In Edit Mode, if we edit the any notes with space it shouldn't allow 
        if(event?.code === 'Backspace' && this.showEdit?.length)  {
            event.preventDefault();
        }
        if (event.which === 27 && this.modalService.hasOpenModals()) {
            if (this.modalRef) {
                this.closeModal();
                event.preventDefault();
            } else if (this.modalrefSM) {
                this.modalrefSM.close();
                this.IsDeleteNotes = false;
                this.modalrefSM = null;
                event.preventDefault();
            } else if (this.modalRefPrint) {
                // this.exportExcel();
                this.modalRefPrint.close();
                this.modalRefPrint = null;
            } else if (this.modalRefNote) {
                this.modalRefNote.close();
                this.modalRefNote = null;
                event.preventDefault();
            }else if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
                event.preventDefault();
            }
        }
    }

    constructor(
        private _fb: FormBuilder,
        private modalService: NgbModal,
        private _notesLogSvc: NotesLogService,
        private _actvRoute: ActivatedRoute,
        private _alertService: AlertService,
        private _cdr: ChangeDetectorRef,
        private _userService: UserService,
        private _privMaskGuard: PrivMaskGuard,
        private _newAuditUtils: NewAuditUtils,
        private _commonServ: CommonService,
        private _formUtils: ValidateFormFieldsUtils,
        private _sanitizer: DomSanitizer,
        private _printServ: PrintService,
        private _commUtil: CommonUtil,
        public _formatsUtil:FormatsUtil,
        private _drugServ: DrugService
    ) {
        this.accessPrivCate = AccCategoryE;
        this.PatnotesPrivs = PatientNotesLogE;
        this.PrenotesPrivs = PrescriberNotesLogE;
        this.DrugnotesPrivs = DrugNotesLogE;
        this.InsnotesPrivs = InsuranceNotesLogE;
        this.ErxnotesPrivs = eRxRphNotesE;
        this.router = window.location.href;
    }

    ngOnInit() {
        this.addbuttonHover = MsgHoverOverConfig;
        this.categoryList = this._commonServ.notesCatList$["source"]["value"];
        const techName = this._userService.getToken("TechUserName");
        const pharName = this._userService.getToken("PhUserName");
        this.userFullName = techName ? _.toUpper(techName) : _.toUpper(pharName);
        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.startdate = moment(this.today)
            .subtract(7, "days")
            .format("MM/DD/YYYY");
        this.createNotesFG();
        this.searchParams = {
            SearchText: null,
            SearchBy: null,
            OrderByColumn: null,
            SortDirection: null,
            PageNumber: 1,
            PageSize: 30,
            PrescrefillId: null
        };
        if (this.openAsModal && this.patientInfo) {
            this.Id = this.patientInfo.patientid;
            this.parentPage = "Patient";
        } else if (this.NotesFr === "Patient") {
            this.Id = this.patientInfo["Patient"] && this.patientInfo["Patient"]["Id"] ? this.patientInfo["Patient"]["Id"] : null;
            this.parentPage = "Patient";
        } else if (this.NotesFr === "Insurance") {
            this.parentPage = "Insurance";
            this.popUpScreenList.push({Name: 'Edit Rx', Id: 4, type:'er'});
            this.insuPlanId = this.insuInfo["InsuPlan"] && this.insuInfo["InsuPlan"]["Id"] ? this.insuInfo["InsuPlan"]["Id"] : null;
            this.Id = this.insuPlanId;
        } else if ((this.openAsModal && this.rxInfo) && this.NotesFr === "Annotation") {
            this.parentPage = "Annotation";
            this.Id = this.rxInfo.Prescription.Id;
        }  else if (this.rxInfo && this.NotesFr === "RxAnnotation") {
            this.parentPage = "RxAnnotation";
            this.Id = this.rxInfo.Prescription.Id;
        }  else if ((this.openAsModal && this.rxInfo) || (this.rxInfo && this.NotesFr === "Rx")) {
            this.parentPage = "Rx";
            this.Id = this.rxInfo.Prescription.Id;
        } else if (this.openAsModal && this.drugInfo) {
            this.Id = this.drugInfo.id;
            this.parentPage = "Drug";
        } else if (this.NotesFr === "Drug") {
            this.parentPage = "Drug";
            this.Id = this.drugInfo["Drug"] && this.drugInfo["Drug"]["Id"] ? this.drugInfo["Drug"]["Id"] : null;
        } else if (this.openAsModal && this.prescriberInfo) {
            this.Id = this.prescriberInfo.prescriberid;
            this.parentPage = "Prescriber";
        } else if (this.NotesFr === "Prescriber") {
            this.parentPage = "Prescriber";
            this.Id = this.prescriberInfo["Prescriber"] && this.prescriberInfo["Prescriber"]["Id"] ? this.prescriberInfo["Prescriber"]["Id"] : null;
        } else if (this.router.includes("/editpatient")) {
            this.parentPage = "Patient";
            this._actvRoute.parent.parent.params
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                params => (this.patientId = +params["pid"])
            );
            this.Id = this.patientId;
        } else if (this.router.includes("/eRX")) {
            this.parentPage = "Erx";
        } else if (this.router.includes("/Insurance")) {
            this.parentPage = "Insurance";
            this._actvRoute.parent.params
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                params => (this.insuPlanId = +params["pid"])
            );
            this.Id = this.insuPlanId;
        } else if (this.router.includes("/drug")) {
            this.parentPage = "Drug";
            this._actvRoute.parent.params
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                params => (this.drugId = +params["pid"])
            );
            this.Id = this.drugId;
        } else if (this.router.includes("/prescriber")) {
            this.parentPage = "Prescriber";
            this._actvRoute.parent.params
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                params => (this.prescriberId = +params["prescriberid"])
            );
            this.Id = this.prescriberId;
        }
        this._cdr.detectChanges();
        this.getNotes(true);
        if (this.openAsModal) {
            this.modalRefNote = this.modalService.open(this.notesLogModal, {
                windowClass:'max-modal-79',
                keyboard: false,
                backdrop: false,
                centered : true
            });
        }
        if (this.accessPrivCate) {
            this.hasADDInsuNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.InsuranceNotesLog, this.InsnotesPrivs.Add);
            this.hasChangInsNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.InsuranceNotesLog, this.InsnotesPrivs.Edit);
            this.hasDeltInsuNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.InsuranceNotesLog, this.InsnotesPrivs.Delete);
            this.hasADDPatNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.PatientNotesLog, this.PatnotesPrivs.Add);
            this.hasChangPatNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.PatientNotesLog, this.PatnotesPrivs.Edit);
            this.hasDeltPatNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.PatientNotesLog, this.PatnotesPrivs.Delete);
            this.hasADDPrescNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.PrescriberNotesLog, this.PrenotesPrivs.Add);
            this.hasChangPresNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.PrescriberNotesLog, this.PrenotesPrivs.Edit);
            this.hasDeltPresNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.PrescriberNotesLog, this.PrenotesPrivs.Delete);
            this.hasADDDrugNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.DrugNotesLog, this.DrugnotesPrivs.Add);
            this.hasChangDrugNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.DrugNotesLog, this.DrugnotesPrivs.Edit);
            this.hasDeltDrugNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.DrugNotesLog, this.DrugnotesPrivs.Delete);
            this.hasADDErxNotesPrivs = this.checkHasPrivsToAccess( this.accessPrivCate.ERxRphNotes, this.ErxnotesPrivs.Add);
            this.hasEditErxNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.ERxRphNotes,this.ErxnotesPrivs.Edit);
            this.hasDeleteErxNotesPrivs = this.checkHasPrivsToAccess(this.accessPrivCate.ERxRphNotes,this.ErxnotesPrivs.Delete);
            this.hasADDRxNotesPrivs = this.checkHasPrivsToAccess(AccCategoryE["RxNotesLog"], RxNotesLogE["Add"]);
            this.hasChangRxNotesPrivs = this.checkHasPrivsToAccess(AccCategoryE["RxNotesLog"], RxNotesLogE["Edit"]);
            this.hasDeltRxNotesPrivs = this.checkHasPrivsToAccess(AccCategoryE["RxNotesLog"], RxNotesLogE["Delete"]);
        }
       
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.IsFromRxHistory = false;
    }

    focusOutFromSelect(event) {
        if (document.getElementById("Yes")) {
            event.preventDefault();
            document.getElementById("Yes").focus();
        }
    }

    focusOutFrmAdd(event) {
        if (!this.totalcount && !this.showForm) {
            this.emitTabFrmGrid(event);
        }
    }

    focusOutAdd(event) {
        if (!this.totalcount) {
            this.emitTabFrmGrid(event);
        }
    }

    emitTabFrmGrid(event) {
        event.preventDefault();
        this.emitFocusOnCancel.emit(true);
    }

    createNotesFG() {
        this.notesLogFG = this._fb.group({
            Note: this._fb.array([]),
            NoteCat: [],
            AnnotationNotes: this._fb.array([]),
            PrescrefillId : this.rxInfo?.PrescReFill?.Id
        });
        this.exportFG = this._fb.group({
            FromDate: this.startdate,
            ToDate: this.today
        });
        if (this.NotesFr === "Patient") {
            this.exportedDataFG = this._fb.group({
                PatientName: this.notesInfoFor,
                Address: this.patientInfo ? (this.patientInfo?.Addresses ?
                     this.patientInfo?.Addresses?.AddressLine1 + " " +
                      (this.patientInfo?.Addresses?.AddressLine2 ? this.patientInfo.Addresses?.AddressLine2 : "") :
                 this.patientInfo.address1 + " " + (this.patientInfo?.address2 ? this.patientInfo?.address2 : " ")) : null,
                City: this.patientInfo ? (this.patientInfo?.Addresses ? this.patientInfo?.Addresses?.CityName : this.patientInfo.city) : null,
                State: this.patientInfo ? (this.patientInfo?.Addresses ? this.patientInfo.Addresses?.StateName :
                     this.patientInfo.state) : null,
                Zip: this.patientInfo ? (this.patientInfo?.Addresses ? this.patientInfo?.Addresses?.ZipCode : this.patientInfo?.zipcode) : null,
            });
        } else if (this.NotesFr === "Prescriber") {
            if (this.prescriberInfo && this.prescriberInfo.PrescriberAddresses) {
                if (this.prescriberInfo.PrescriberAddresses.length > 1) {
                    this.prescriberInfo.PrescriberAddresses.map(value => {
                        if (value["Id"] === this.prescriberInfo.primeAddressId) {
                            this.PrescAddress = value;
                        }
                    });
                } else {
                    this.PrescAddress =  this.prescriberInfo.PrescriberAddresses["0"];
                }
            }
        this.exportedDataFG = this._fb.group({
            PrescriberName: this.prescriberInfo ? (this.prescriberInfo.Person ?
                 this.prescriberInfo.Person.LastName + ", " +
                 this.prescriberInfo.Person.FirstName : this.prescriberInfo.prescriberlastname + ", " +
                  this.prescriberInfo.prescriberfirstname) : null,
            Address: this.prescriberInfo?.Address ? (this.prescriberInfo.Address ?
                 this.prescriberInfo.Address.AddressLine1 + " " +
                 (this.prescriberInfo.Address.AddressLine2 ? this.prescriberInfo.Address.AddressLine2 : "") :
            (this.PrescAddress && this.PrescAddress.AddressLine1 ? this.PrescAddress.AddressLine1 : " " )+ " " + (this.PrescAddress && this.PrescAddress.AddressLine2 ?
                 this.PrescAddress.AddressLine2 : " ")) : null,
            City: this.prescriberInfo?.Address ? (this.prescriberInfo.Address ?
                this.prescriberInfo.Address?.CityName : this.PrescAddress?.CityName) : null,
            State: this.prescriberInfo && this.prescriberInfo?.Address  ? (this.prescriberInfo.Address ?
                this.prescriberInfo.Address.StateName : this.PrescAddress.StateName) : null,
            Zip: this.prescriberInfo?.Address ? (this.prescriberInfo.Address ?
                 this.prescriberInfo.Address.ZipCode : this.PrescAddress.ZipCode) : null,
        });
    } else if (this.NotesFr === "Drug") {
         this.exportedDataFG = this._fb.group({
            DrugName: this.drugInfo ? (this.drugInfo.Drug ? (this.drugInfo.Drug.Name + ", " + this.drugInfo.Drug.Strength +
             ", " + this.drugInfo.Drug.DrugFormName) :
             this.drugInfo.drugname + ", " + this.drugInfo.strength + ", " + this.drugInfo.drugformname) : null,
            Size: this.drugInfo ? (this.drugInfo.Drug ? this.drugInfo.Drug.QtyPack : this.drugInfo.qtypack) : null,
            Awp: this.drugInfo ? (this.drugInfo.Drug ? this.drugInfo.Drug.AWPPack : this.drugInfo.awppack) : null
        });
    } else if (this.NotesFr === "Insurance") {
        const addressLine1 = (this.insuInfo && this.insuInfo.Address && this.insuInfo.Address.AddressLine1) ? this.insuInfo.Address.AddressLine1 : "";
        const addressLine2 = (this.insuInfo.Address && this.insuInfo.Address.AddressLine2 && this.insuInfo.Address.AddressLine2) ? this.insuInfo.Address.AddressLine2  : "";
            this.exportedDataFG = this._fb.group({
                Insurance : (this.insuInfo && this.insuInfo.Organization && this.insuInfo.Organization.Name) ? this.insuInfo.Organization.Name : "",
                InsuranceCode: (this.insuInfo && this.insuInfo.InsuCarrier && this.insuInfo.InsuCarrier.InsurerCode) ? this.insuInfo.InsuCarrier.InsurerCode : "",
                Address: addressLine1  + " " + addressLine2,
                City: (this.insuInfo && this.insuInfo.Address && this.insuInfo.Address?.CityName) ? this.insuInfo.Address?.CityName : "",
                State: (this.insuInfo && this.insuInfo.Address && this.insuInfo.Address.StateName) ? this.insuInfo.Address.StateName : "",
                Zip: (this.insuInfo && this.insuInfo.Address && this.insuInfo.Address.ZipCode) ? this.insuInfo.Address.ZipCode : ""
        });
        } else if (this.openAsModal && this.rxInfo) {
                this.exportedDataFG = this._fb.group({
                        RxNo: this.notesInfoFor,
                        PatientName: this.rxInfo.Patient.lastname + ", " + this.rxInfo.Patient.firstname,
                        Drug: this.rxInfo.Drug.drugname + ", " + this.rxInfo.Drug.strength + ", " + this.rxInfo.Drug.drugformname,
                        DateFilled: moment(this.rxInfo.PrescReFill.FillDtTm).format("MM/DD/YYYY"),

                });
        } else if (this.router.includes("/eRX")) {
            this.exportedDataFG = this._fb.group({
                PatientName : this.erxInfo?.PatientName,
                PrescriberName: this.erxInfo?.PrescriberName
            });
        }
    }

    setDefaultValues() {
        const notesInfo: NotesLog = this.notesLogFG.value;
        if(notesInfo) {
            notesInfo.Note[0]['IsActive'] = true;
            // TODO set AppUserId. Get while login.
            notesInfo.Note[0]['AppUserId'] = 2;
            notesInfo.Note[0]['CreateDtTm'] = moment(Date.now()).format("MM/DD/YYYY");
            notesInfo.Note[0]['ModifiedDtTm'] = moment(Date.now()).local().format(
                "MM/DD/YYYY hh:mm:ss a"
            );
            notesInfo.Note[0]["IsDeleted"] = false;
        }
    }

    checkForCategoryExistnce() {
        if (
            this.notesLogFG.value &&
            (this.notesLogFG.value?.Note[0]?.NoteCatId !== null ||
                this.notesLogFG.value.NoteCat !== null)
        ) {
            return true;
        } else {
            if (this.categoryModel && typeof this.categoryModel === "string") {
                return true;
            } else {
                return false;
            }
        }
    }
    clearNotes() {
        if(this.notesLogFG?.value?.Note?.length)
            this.notesLogFG.value.Note.pop();
    }
    addNote() {
        this.IsSubmitted = true;
        if(this.IsFromRxSave)
             this.notesList = this.notesList?.length ? this.notesList : (this.NotesListBeforeSave?.length ? this.NotesListBeforeSave: []);
        // const checkForCategory = this.checkForCategoryExistnce();
        // if (checkForCategory) {
        const noteFG: any = this.notesLogFG.value["Note"]; 
        /// IsAddItem if any subcategory will be add from dropdown
        if (((this.notesLogFG.valid || noteFG[0]["IsAddItem"]) && noteFG[0]["xNote"]) || ((this.notesLogFG.value["NoteCat"] && this.notesLogFG.value["NoteCat"].length) && noteFG[0]["xNote"])) {
            const notesInfo = this.notesLogFG.value;
            this.setDefaultValues();
            if(notesInfo && notesInfo["Note"]?.length ) {
                if(notesInfo["Note"][0]["PopupScreenCodes"])
                {
                    notesInfo["Note"][0]["PopupScreenCodes"] = notesInfo["Note"][0]["PopupScreenCodes"]?.toString();
                     notesInfo["Note"][0]["IsShownAsPopup"] = true;
                }
                else 
                   notesInfo["Note"][0]["IsShownAsPopup"] = false;
            } 
           if(!this.IsFromRxSave) {
            this._notesLogSvc
            .addNotesLog(this.parentPage, this.Id, notesInfo)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.showAdd = [];
                    this.showAdd[this.notesLogFormattedData.length-1] = false;
                    this.showEdit = [];
                    const dataModel = [
                        {
                            HeaderName: "Notes",
                            ControlName: "xNote"
                        },
                        {
                            HeaderName: "Category",
                            ControlName: "NoteCatId"
                        },
                            {
                                HeaderName: "Popup Screen",
                                ControlName: "PopupScreenCodes"
                            }
                    ];
                    this._newAuditUtils.saveAuditOnAdd(
                        notesInfo.Note[0],
                        "Notes",
                        this.parentPage,
                        (this.parentPage === "RxAnnotation") ? this.rxInfo.PrescReFill.Id : this.Id,
                        dataModel,
                        this.notesLogInfo.NoteCat
                    );
                    this.IsSubmitted = false;
                    this.getNotes();
                    this.resetMode();
                    this.resetForm();
                    this._alertService.success("Notes saved successfully.");
                    if (this.modalRef) {
                        this.modalRef.close();
                    }
                   this.closeModalPopup();
                }
            });
           } else {
            this.notesList.unshift(this.notesLogFG?.value?.Note[0]);
            this.generateWJ({ Note: this.notesList});
            this.IsSubmitted = false;
            this.showAdd = [];;
            this.showAdd[this.notesLogFormattedData.length-1] = false;
             this.closeModalPopup();
           }

        } 
    }
    addNewNotes() {
        this.setAddMode(); 
        this.resetForm()
    }
    enableAddButton() {
            if(this.showAdd.length) {
                const filterData = this.showAdd.filter((item: any) => {
                    return item;
                });
                return filterData?.length ? true : false;
            } else 
                return false;
    }
    setAddMode(selectedIndexItem?) {
            this.transistion = !this.transistion;
            this.notesLogFG.reset();
            this.showTrackChanges = [];
            this.notesTrackHistory = [];
            this.notesLogFG.controls["Note"].patchValue([{IsShownAsPopup: true}]);
            if(!this.showAdd[this.notesLogFormattedData.length-1])
                  this.notesLogFormattedData.push("");
            this.addmode = false;
            this._cdr.detectChanges();
            this.addmode = true;
            this.showEdit = [];
            this.showAdd[this.notesLogFormattedData.length-1] = true;
            this.editmode = false;
            this.IsSubmitted = false;
            this.categoryModel = new NoteCat();
            if (this.notesLogInfo?.NoteCat?.length) {
                const genCat = this.notesLogInfo.NoteCat[0];
                if (genCat) {
                    this.categoryModel.Name = genCat.Name;
                    const noteFG: any = this.notesLogFG.controls["Note"];
                    noteFG.controls[0].controls["NoteCatId"].setValue(genCat.Id);
                    noteFG.controls[0].controls["Name"].setValue(genCat.Name);
                }
            }
            if(!this.openAsModal){
                if(this.modelRef){
                    this.modelRef.close();
                    this.modelRef = null;
                }
            }
    }

    resetMode(selectedItemIndex?) {
        this.addmode = false;
        this.editmode = false;
        this.showEdit = [];
        this.showEdit[selectedItemIndex] = false;
    }

    resetForm() {
        this.showForm = this.addmode || this.editmode ? true : !this.showForm;
    }

    setEditMode(selectedItemIndex?, noteItem?) {
        this.addmode = false;
        this.editmode = true;
        this.IsSubmitted = false;
        this.showTrackChanges = [];
        this.notesTrackHistory = [];
        if(selectedItemIndex>=0)
        {
            this.showEdit = [];
            if(this.notesLogFormattedData?.length &&  this.showAdd[this.notesLogFormattedData?.length-1] && !this.notesLogFormattedData[this.notesLogFormattedData?.length-1]) {
                this.notesLogFormattedData.pop();
                this.showAdd = [];
            }
            this.showEdit[selectedItemIndex] = true;
        }
        if(noteItem)
              this.getNoteData(noteItem);
    }

    getNoteData(item) {
        const getData = (this.IsFromRxSave &&  !this.notesLogInfo?.Note?.length)  ? (this.NotesListBeforeSave?.length ? this.NotesListBeforeSave[item.rowId] : this.notesList[item.rowId]) :  this.notesLogInfo.Note[item.rowId];
        const noteFG: any = this.notesLogFG.controls["Note"];
        noteFG.controls[0].controls["Id"].setValue(getData?.Id);
        noteFG.controls[0].controls["IsShownAsPopup"].setValue(
            getData?.IsShownAsPopup
        );
        noteFG.controls[0].controls["xNote"].setValue((getData?.xNote));
        noteFG.controls[0].controls["NoteCatId"].setValue(getData?.NoteCatId);
        noteFG.controls[0].controls["Name"].setValue(getData?.Name);
        noteFG.controls[0].controls["AppUserId"].setValue(getData?.AppUserId);
        noteFG.controls[0].controls["IsActive"].setValue(getData?.IsActive);
        noteFG.controls[0].controls["PopupScreenCodes"].setValue((getData && Array.isArray(getData?.PopupScreenCodes)) 
        ? getData?.PopupScreenCodes: (getData ? getData?.PopupScreenCodes?.split(',') : null));
        noteFG.controls[0].controls["IsDeleted"].setValue(0);
        noteFG.controls[0].controls["CreateDtTm"].setValue(getData?.CreateDtTm);
        this.categoryModel = new NoteCat();
        this.categoryModel.Name = _.map(
            _.filter(this.notesLogInfo?.NoteCat, { Id: getData?.NoteCatId }),
            "Name"
        )[0];
        this.originalData = noteFG.value;
    }
    
    filterAllinList(formGrop) {
        formGrop.controls['PopupScreenCodes'].patchValue(this._commUtil.filterAllinList(formGrop.controls['PopupScreenCodes'].value));
    }

    getNoteId(item) {
        this.noteId = this.notesLogInfo.Note[item.rowId].Id;
    }

    deleteNote() {
        // this.openAsModal = !this.openAsModal;
        this._notesLogSvc.deleteNotesLog(this.noteId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this._newAuditUtils.SaveAuditOnDelete(
                    null,
                    [{ name: "Id", value: this.Id },
                    {name: "Is Deleted", value: "true"}],
                    "Delete NotesLog",
                    this.parentPage,
                    (this.parentPage === "RxAnnotation") ? this.rxInfo.PrescReFill.Id : this.Id,
                );
                this.resetMode();
                this.showForm = false;
                this.getNotes();
                this._alertService.success("Notes deleted successfully.");
                if (this.modalrefSM) {
                    this.modalrefSM.close();
                }
                if (this.modalRef) {
                    this.modalRef.close();
                }
                this.IsDeleteNotes = false;
            }
        });
    }

    updateNote(selectedIndex?) {
        this.IsSubmitted = true;
        if (this.notesLogFG.valid) {
            
            const noteData = this.notesLogFG.value;
            if(noteData?.Note?.length) {
                noteData.Note[0].ModifiedDtTm = moment(Date.now()).local().format(
                    "MM/DD/YYYY hh:mm:ss"
                );
            }
            if(noteData?.AnnotationNotes?.length) {
                noteData.AnnotationNotes[0].ModifiedDtTm = moment(Date.now()).local().format(
                    "MM/DD/YYYY hh:mm:ss"
                );
            }
            if(this.NotesFr === 'Annotation')  {
                noteData["AnnotationNotes"] = noteData["Note"];
                noteData["Note"] = [];
            }
            const checkForCategory = this.checkForCategoryExistnce();
            if(this.NotesFr === 'Annotation' ? !noteData['AnnotationNotes'][0]["xNote"] : !noteData['Note'][0]["xNote"]) {
                this.IsSubmitted = true;
                return ;
            }
            if(noteData && noteData["Note"]?.length) {
                if(noteData["Note"][0]["PopupScreenCodes"])
                {
                    noteData["Note"][0]["IsShownAsPopup"]  = true;  
                    noteData["Note"][0]["PopupScreenCodes"] = noteData["Note"][0]["PopupScreenCodes"]?.toString();
                }
                else 
                     noteData["Note"][0]["IsShownAsPopup"]  = false;  
            }
            if(noteData && noteData["AnnotationNotes"]?.length) {
                if(noteData["AnnotationNotes"][0]["PopupScreenCodes"])
                {
                    noteData["AnnotationNotes"][0]["IsShownAsPopup"]  = true;
                    noteData["AnnotationNotes"][0]["PopupScreenCodes"] = noteData["AnnotationNotes"][0]["PopupScreenCodes"]?.toString();
                }
                else 
                noteData["AnnotationNotes"][0]["IsShownAsPopup"]  = false;
            }
            if (checkForCategory && !this.IsFromRxSave) {
                this._notesLogSvc.updateNotesLog(noteData)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    this.showEdit = [];
                    this.showAdd = [];
                    if (resp) {
                        const dataModel = [
                            {
                                HeaderName: "Notes",
                                ControlName: "xNote"
                            },
                            {
                                HeaderName: "Category",
                                ControlName: "NoteCatId"
                            },
                            {
                                HeaderName: "Popup Screen",
                                ControlName: "PopupScreenCodes"
                            }
                        ];
                        this._newAuditUtils.saveAuditChange(
                            this.originalData[0],
                            noteData.Note[0],
                            "Notes",
                            this.parentPage === "RxAnnotation" ? "Rx" : this.parentPage,
                            (this.parentPage === "RxAnnotation") ? this.rxInfo.PrescReFill.Id : this.Id,
                            dataModel,
                            false,
                            this.notesLogInfo.NoteCat
                        );
                        this.IsSubmitted = false;
                        this.getNotes();
                        this.resetMode();
                        this.resetForm();
                        this._alertService.success(
                            "Notes updated successfully."
                        );
                        if (this.modalRef) {
                            this.modalRef.close();
                        }
                       this.closeModalPopup();
                    }
                });
            } else {
                this.IsSubmitted = false;
                this.showEdit = [];
                this.showAdd = [];
                this._alertService.success(
                    "Notes updated successfully."
                );
           if(this.NotesListBeforeSave?.length)
                        this.NotesListBeforeSave[selectedIndex] =  noteData.Note[0];
            if(this.notesList?.length)
                     this.notesList[selectedIndex] =  noteData.Note[0];        
            this.generateWJ({ Note:( this.notesList?.length) ? this.notesList: this.NotesListBeforeSave});
            this.IsSubmitted = false;
            this.showAdd = [];;
            this.showAdd[this.notesLogFormattedData.length-1] = false;
            this.closeModalPopup();
            }
        }
    }
    closeModalPopup() {
        if(this.modelRef) {
            this.modelRef.close();
              this.modelRef = null;
        }
    }
    getNoteTrackChanges(noteItem, selectedNoteIndex) {
        this.notesTrackHistory = [];
        if (noteItem['noteId'] && !this.showTrackChanges[selectedNoteIndex]) {
            this.showTrackChanges= []; 
            this.showTrackChanges[selectedNoteIndex] = true;
            this._notesLogSvc.getNotesTrackChanges(noteItem['noteId']).subscribe((res: any) => {
                if (res && res.length) {
                    this.notesTrackHistory = res;
                }
            });
        } else {
            this.showTrackChanges = [];
        }
    }

       getNotes(IsFromInit: boolean = false) {
        //this.resetForm();
        if (this.dateTo === null) {
            this.dateTo = moment(Date.now()).format("YYYY-MM-DD");
        }
        if( this.rxInfo?.PrescReFill?.Id && this.NotesFr === 'Annotation') {
            this.searchParams["PrescrefillId"] = this.rxInfo.PrescReFill.Id;
        }
        this._notesLogSvc
            .getNotesLog(
                this.parentPage,
                this.IsFromRxSave ? 0 : this.Id,
                this.dateFrom,
                this.dateTo,
                this.searchParams
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.notesLogInfo = resp;
                // eRx related subcategories we have to hide from the dropdown for cancel Rx's only will show it in the Edit Mode
                if(this.NotesFr === 'RxAnnotation') {
                    this.notesSubcatList = resp?.NoteCat?.filter((item: any) => (item?.CategoryName.toUpperCase() !== 'ERX'));
                }
                 if (this.NotesFr && this.NotesFr === 'Annotation') {
                    this.notesLogInfo.Note  = this.notesLogInfo.AnnotationNotes;
                 }
                if (this.notesLogInfo) {
                    const  NotesFr   = (this.NotesFr === "RxAnnotation") ? "Rx Annotation" : (this.NotesFr  == 'Annotation' ? "Annotations": this.NotesFr );
                    const list1 = this.notesLogInfo?.NoteCat?.filter((item: any) => { return (item['Name']?.toUpperCase() == NotesFr?.toUpperCase()) });
                    const list2 = this.notesLogInfo?.NoteCat?.filter((item: any) => { return (item['Name']?.toUpperCase() != NotesFr?.toUpperCase())});
                    if(list1?.length && list2?.length)
                        this.notesLogInfo.NoteCat =  list1.concat(list2);
                    this.dateTo = null;
                    if(this.IsFromRxSave)
                         this.notesLogInfo["Note"] = this.NotesListBeforeSave;
                    this.generateWJ(this.notesLogInfo);
                    this.patchValue();
                    if(IsFromInit && !this.notesLogInfo?.Note?.length) {
                        this.setAddMode(); 
                        this.resetForm()
                    }
                }
            });
    }

    patchValue() {
        for (const key in this.notesLogInfo) {
            if (["Note"].indexOf(key) > -1) {
                this.generateFA(key, [new Note()]);
            }
        }
    }

    generateFA(key: string, values: any[]) {
        const arrFG = values.map(val => this._fb.group(val));
        const arrFA = this._fb.array(arrFG);
        this.notesLogFG.setControl(key, arrFA);
    }

    getCategoryName() {
        this.notesLogInfo.Note.map(val => {
            const data = _.map(
                _.filter(this.notesLogInfo.NoteCat, { Id: val.NoteCatId }),
                "Name"
            );
            data.map(d => this.categoryName.push(d));
        });
    }

    generateWJ(notes) {
        if (notes.Note && notes.Note.length) {
            this.isDataExists = true;
            this.totalcount = 1;
            const notesList  =  (this.NotesFr !== 'Annotation') ? notes.Note : notes.AnnotationNotes;
            this.notesLogFormattedData = notesList.map((result, i) => {
                let k = {};
                k["rowId"] = i;
                k["noteId"] = result.Id;
                k["note"] = result.xNote;
                k["IsNoteUpdate"] = result.IsNoteUpdate;
                k["usename"] = this.IsFromRxSave? this.userFullName : result.UserName;
                k["IsShownAsPopup"] = result.IsShownAsPopup;
                // k["ModifiedDtTm"] = moment(result.ModifiedDtTm).format(
                //     "MM/DD/YYYY hh:mm:ss"
                // );
                k["ModifiedDtTm"] = result.ModifiedDtTm;
                k["categoryName"] = _.map(
                    _.filter(this.notesLogInfo?.NoteCat, {
                        Id: result?.NoteCatId
                    }),
                    "Name"
                )[0];
                return k;
            });
            this.notesLogWJ = new CollectionView(this.notesLogFormattedData);
        } else {
            this.isDataExists = false;
            this.totalcount = 0;
            this.notesLogWJ = new CollectionView();
        }
    }

    addedCateg(addedEvent) {
       const parentPage =  (this.parentPage === 'RxAnnotation') ? "Rx Annotation" : this.parentPage;
        const category = this.categoryList?.find((item: any) => { return  parentPage.toUpperCase() === item['CategoryName'].toUpperCase() });
        const noteFG: any = this.notesLogFG.controls["Note"];
        let Val = addedEvent ? (addedEvent?.Id) : "";
        if (!Val && addedEvent && this.rxInfo?.rxType !== "nr") {
            // const item = { "CreatedDtTm": '', "Id": null, "IsActive": true, "Name": addedEvent?.Name, "NoteCategoryId": category?.CategoryId };
            // this.addSubCategory(item);
            this.notesLogFG.patchValue({
                NoteCat: [
                    {
                        Id: 0,
                        TenantId: null,
                        Name: addedEvent.Name,
                        Remarks: null,
                        IsActive: true,
                        IsAddItem: true,
                        CreatedDtTm: moment(Date.now()).format("MM/DD/YYYY"),
                        ModifiedDtTm: moment(Date.now()).local().format("MM/DD/YYYY hh:mm:ss a"),
                        NoteCategoryId: category?.CategoryId 
                    }
                ],
                Note: [
                    {
                        Id: 0,
                        TenantId: null,
                        Name: addedEvent.Name,
                        Remarks: null,
                        IsActive: true,
                        IsAddItem: true,
                        CreatedDtTm: moment(Date.now()).format("MM/DD/YYYY"),
                        ModifiedDtTm: moment(Date.now()).local().format("MM/DD/YYYY hh:mm:ss a"),
                        NoteCategoryId: category?.CategoryId 
                    }
                ]
            });  
            const noteFG: any = this.notesLogFG.controls["Note"];
            noteFG.controls[0].controls["NoteCatId"].markAsTouched(); 
        }
        else if(!Val && addedEvent && this.rxInfo?.rxType === "nr") {
            this._drugServ.addoRUpdateNoteCategory(
                {
                    Name: addedEvent?.Name,
                    NoteCategoryId: category?.CategoryId,
                    IsActive: true,
                    CreatedDtTm: null,
                    Id:0
                },
                "SAVE"
            ).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: number) => {
                if (resp) {
                    this.getSubCategories({
                        category : category?.CategoryId,
                        Name: addedEvent.Name,
                    });
                }
            });
        }            
    }
  getSubCategories(catObj) {
    if (this.dateTo === null) {
        this.dateTo = moment(Date.now()).format(MsgConfig.DATE_FORMAT_WITH_EIPHEN);
    }
    this._notesLogSvc
    .getNotesLog(
        this.parentPage,
        this.IsFromRxSave ? 0 : this.Id,
        this.dateFrom,
        this.dateTo,
        this.searchParams
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
        this.notesLogInfo = resp;
        let manualSubCat: any = {};
        // eRx related subcategories we have to hide from the dropdown for cancel Rx's only will show it in the Edit Mode
        if(this.NotesFr === 'RxAnnotation') {
            this.notesSubcatList = resp?.NoteCat?.filter((item: any) => (item?.CategoryName.toUpperCase() !== 'ERX'));
        }
        if(catObj.Name) {
            manualSubCat = resp?.NoteCat?.find((item: any) => (catObj.Name.toUpperCase() === item?.Name?.toUpperCase()));
        }
        this.notesLogFG.patchValue({
            Note: [
                {
                    Id: 0,
                    TenantId: null,
                    Name: catObj.Name,
                    Remarks: null,
                    IsActive: true,
                    IsAddItem: true,
                    NoteCatId:  manualSubCat ? manualSubCat.Id : 0,
                    CreatedDtTm: moment(Date.now()).format("MM/DD/YYYY"),
                    ModifiedDtTm: moment(Date.now()).local().format("MM/DD/YYYY hh:mm:ss a"),
                    NoteCategoryId: catObj?.category 
                }
            ],
        });
        const noteFG: any = this.notesLogFG.controls["Note"];
        noteFG.controls[0].controls["NoteCatId"].markAsTouched();

    });
  }
    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(750),
            distinctUntilChanged(),
            map(term => {
                if (term && (term.length > 1 || term !== null || term !== "")) {
                    const result = this.categoryBySearch(term);
                    if (result.length > 0) {
                        this.notesLogFG.controls["NoteCat"].setValue(null);
                        this.checkForCategoryExistnce();
                        return result;
                    } else {
                        this.categoryModel = term;
                        const noteFG: any = this.notesLogFG.controls["Note"];
                        noteFG.controls[0].controls["NoteCatId"].setValue(null);
                        this.notesLogFG.patchValue({
                            NoteCat: [
                                {
                                    Id: null,
                                    TenantId: null,
                                    Name: this.categoryModel,
                                    Remarks: null,
                                    IsActive: true,
                                    CreatedDtTm: moment(Date.now()).format(
                                        "MM/DD/YYYY"
                                    ),
                                    ModifiedDtTm: moment(Date.now()).local().format(
                                        "MM/DD/YYYY hh:mm:ss a"
                                    )
                                }
                            ]
                        });
                        this.checkForCategoryExistnce();
                    }
                } else {
                    const noteFG: any = this.notesLogFG.controls["Note"];
                    this.notesLogFG.controls["NoteCat"].setValue(null);
                    noteFG.controls[0].controls["NoteCatId"].setValue(null);
                    return [];
                }
            })
        );

    categoryBySearch(term) {
        return this.notesLogInfo.NoteCat.filter(v => {
            const val = v.Name;
            return val.toLowerCase().startsWith(term.toLocaleLowerCase());
        }).splice(0, 20);
    }

    selectedCategory(val) {
        const noteFG: any = this.notesLogFG.controls["Note"];
        noteFG.controls[0].controls["NoteCatId"].setValue(val.item.Id);
    }

    formatter = (x: any) => {
        // this.catgryName = x.Name;
        return x.Name;
    };

    // checkCategoryExist() {
    //     let val: string = null;
    //     const noteFG: any = this.notesLogFG.controls["Note"];
    //     if (typeof(this.categoryModel) !== "string") {
    //         val = this.categoryModel.Name;
    //         this.notesLogFG.controls["NoteCat"].setValue(null);
    //         delete this.notesLogInfo.NoteCat;
    //     } else {
    //         val = this.categoryModel;
    //         noteFG.controls[0].controls["NoteCatId"].setValue(null);
    //         this.notesLogFG.patchValue({
    //             NoteCat: [
    //                 {
    //                     Id: null,
    //                     TenantId: null,
    //                     Name: val,
    //                     Remarks: null,
    //                     IsActive: true,
    //                     CreatedDtTm: moment(Date.now()).format("MM/DD/YYYY"),
    //                     ModifiedDtTm: moment(Date.now()).format("MM/DD/YYYY")
    //                 }
    //             ]
    //         });
    //         noteFG.controls[0].controls["NoteCatId"].clearValidators();
    //     }
    // }

    changeVal(val) {
        this.catgryName = val.target.value;
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columns[0].align = "center";
    }

    closeNotesLog() {
        this.CloseNotesValue.emit(false);
    }

    openModalSM(content) {
        this.modalrefSM = this.modalService.open(content, {
            centered: true,
            windowClass: "large--content"
        });
    }

    openModalLG(content) {
        this.modalRefPrint = this.modalService.open(content,{ centered : true});
        this.exportNotes = true;
    }

    // exportExcel() {
    //     this.getDateFilteredData();
    //     this.exportNotes = false;
    //     this.modalRefPrint.close();
    //     this.modalRefPrint = null;
    //     // this.modalRef.close();
    //     // this.modalRef = null;
    // }

    getDateFilteredData() {
        this._notesLogSvc
            .getNotesLog(
                this.parentPage,
                this.Id,
                moment(this.exportFG.value.FromDate).format("MM-DD-YYYY"),
                moment(this.exportFG.value.ToDate).format("MM-DD-YYYY"),
                this.searchParams
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                const filterdData = resp;
                if (filterdData && filterdData.Note === null) {
                    this.hasFilteredData = false;
                    this._alertService.error("No data to export.");
                } else {
                    this.hasFilteredData = true;
                    if (resp) {
                        this.generateFilteredWJ(resp);
                    }
                }
            });
    }

    generateFilteredWJ(notes) {
        const notesList  =  (this.NotesFr !== 'Annotation') ? notes.Note : notes.AnnotationNotes;
        this.notesLogFilteredWJ = new CollectionView(
            notesList.map((result, i) => {
                let k = {};
                k["rowId"] = i;
                k["noteId"] = result.Id;
                k["note"] = result.xNote;
                k["usename"] = this.IsFromRxSave? this.userFullName : result.UserName;
                k["IsShownAsPopup"] = result.IsShownAsPopup;
                k["IsNoteUpdate"] = result.IsNoteUpdate;
                k["ModifiedDtTm"] = moment(result.ModifiedDtTm).local().format(
                    "MM/DD/YYYY hh:mm:ss a"
                );
                k["categoryName"] = _.map(
                    _.filter(this.notesLogInfo.NoteCat, {
                        Id: result.NoteCatId
                    }),
                    "Name"
                )[0];
                return k;
            })
        );
        setTimeout(() => {
            wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
                this.flex,
                {formatItem: (args) =>  args.xlsxCell.formula = null},
                this.parentPage + "_Notes" + ".xlsx"
            );
        }, 2000);
    }

    closeModal() {
        if (this.modalRefNote)  {
            this.modalRefNote.close();
            this.modalRefNote = null;
        }
        if (!this.IsFromRxSave) {
            this.CloseNotesValue.emit(true); 
        }
        else
        {
            if(this.NotesListBeforeSave?.length) {
                this.NotesListBeforeSave?.map((resp: any) =>{
                    if(Array.isArray(resp?.PopupScreenCodes)) {
                        resp.PopupScreenCodes = resp.PopupScreenCodes.toString();
                    }
                });
            }
            this.CloseNotesValueList.emit(this.NotesListBeforeSave?.length ? {
                Note: this.NotesListBeforeSave,
                NoteCat: this.notesLogInfo?.NoteCat
            } : {
                Note: this.notesList,
                NoteCat: this.notesLogInfo?.NoteCat
            });
        }
    }

    checkHasPrivsToAccess(category: any, privType: any) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            if (this.overrideFor === "Add") {
                this.setAddMode();
                this.resetForm();
            } else if (this.overrideFor === "Edit") {
                this.getNoteData(this.savedValue);
                if(this.savedValue)
                   this.setEditMode(this.savedValue['rowId']);
                this.resetForm();
            } else if (this.overrideFor === "Inactive") {
                this.openModalSM(this.popupType);
                if(this.savedValue)
                  this.getNoteId(this.savedValue);
            }
        }
    }

     openOveride(content, value, categoryId, type, endpoint?) {
        this.categoryId = categoryId;
        this.popupType = content;
        this.privType = type;
        this.openOverride = true;
        this.savedValue = value;
        this.overrideFor = type;
        this.overrideEndpoint = endpoint;
    }

    addEventonWijimo(event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                if (
                    this.parentPage === "Insurance" &&
                    this.hasADDInsuNotesPrivs
                ) {
                    this.setAddMode();
                    this.resetForm();
                } else if (
                    this.parentPage === "Patient" &&
                    this.hasADDPatNotesPrivs
                ) {
                    this.setAddMode();
                    this.resetForm();
                } else if (
                    this.parentPage === "Patient" &&
                    !this.hasADDPatNotesPrivs
                ) {
                    this.openOveride(
                        null,
                        null,
                        "PatientNotesLog",
                        "Add",
                        "POST_Utility_NotesLog"
                    );
                } else if (
                    this.parentPage === "Prescriber" &&
                    this.hasADDPrescNotesPrivs
                ) {
                    this.setAddMode();
                    this.resetForm();
                } else if (
                    this.parentPage === "Prescriber" &&
                    !this.hasADDPrescNotesPrivs
                ) {
                    this.openOveride(
                        null,
                        null,
                        "PrescriberNotesLog",
                        "Add",
                        "POST_Utility_NotesLog"
                    );
                } else if (
                    this.parentPage === "Drug" &&
                    this.hasADDDrugNotesPrivs
                ) {
                    this.setAddMode();
                    this.resetForm();
                } else if (
                    this.parentPage === "Drug" &&
                    !this.hasADDDrugNotesPrivs
                ) {
                    this.openOveride(
                        null,
                        null,
                        "DrugNotesLog",
                        "Add",
                        "POST_Utility_NotesLog"
                    );
                } else if(
                    this.parentPage === "Erx")
                {
                    this.setAddMode();
                    this.resetForm();
                }
            }
        }
    }

    editEventOnWijimo(event, grid) {
        event.preventDefault();
        if (this.parentPage === "Insurance" && this.hasChangInsNotesPrivs) {
            this.getNoteData(grid["selectedItems"][0]);
            this.setEditMode();
            this.resetForm();
        } else if (
            this.parentPage === "Insurance" &&
            !this.hasChangInsNotesPrivs
        ) {
            this.openOveride(
                null,
                grid["selectedItems"][0],
                "InsuranceNotesLog",
                "Edit",
                "PUT_Utility_NotesLog"
            );
        } else if (this.parentPage === "Drug" && this.hasChangDrugNotesPrivs) {
            this.getNoteData(grid["selectedItems"][0]);
            this.setEditMode();
            this.resetForm();
        } else if (this.parentPage === "Drug" && !this.hasChangDrugNotesPrivs) {
            this.openOveride(
                null,
                grid["selectedItems"][0],
                "DrugNotesLog",
                "Edit",
                "PUT_Utility_NotesLog"
            );
        } else if (
            this.parentPage === "Patient" &&
            this.hasChangPatNotesPrivs
        ) {
            this.getNoteData(grid["selectedItems"][0]);
            this.setEditMode();
            this.resetForm();
        } else if (
            this.parentPage === "Patient" &&
            !this.hasChangPatNotesPrivs
        ) {
            this.openOveride(
                null,
                grid["selectedItems"][0],
                "PatientNotesLog",
                "Edit",
                "PUT_Utility_NotesLog"
            );
        } else if (
            this.parentPage === "Prescriber" &&
            this.hasChangPresNotesPrivs
        ) {
            this.getNoteData(grid["selectedItems"][0]);
            this.setEditMode();
            this.resetForm();
        } else if (
            this.parentPage === "Prescriber" &&
            !this.hasChangPresNotesPrivs
        ) {
            this.openOveride(
                null,
                grid["selectedItems"][0],
                "PrescriberNotesLog",
                "Edit",
                "PUT_Utility_NotesLog"
            );
        } else if (this.parentPage === "Erx") {
            this.getNoteData(grid["selectedItems"][0]);
            this.setEditMode();
            this.resetForm();
        }
    }

    validate() {
        if (document.getElementById("Notes")) {
        } else {
            alert("required");
            // this.notesLogFG.Note.resume();
        }
    }

    async exportExcel(val: boolean, IsExport: boolean = false) {
        await this._formUtils.blurAndDelay(); 
        this.isprint = val;
        const resp = this._formUtils.checkDateErrors(this.exportFG, "FromDate", "ToDate", 0);
        if (resp === "DATEISSUE") {
            this._alertService.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertService.error(MsgConfig.INVALID_DATES_ERROR);
        } else if (resp === "VALID") {
           this.exportData = {
                ParentPage: this.parentPage,
                Id: this.Id,
                FromDate: moment(this.exportFG.value.FromDate).format("MM-DD-YYYY"),
                ToDate: moment(this.exportFG.value.ToDate).format("MM-DD-YYYY"),
                Param: this.searchParams,
                exportData: JSON.stringify(this.exportedDataFG ? this.exportedDataFG.value: null)
            };
            this._notesLogSvc.getNotesLogDetails(this.exportData)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
                    if (res && res.size > 0 && res.type === "application/pdf") {
                        this.exportedData = res;
                        if (this.isprint) {
                            this.printNotesLog();
                        } else {
                            this.showPreview = true;
                            const urlCreator = window.URL;
                            this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                                urlCreator.createObjectURL(res) + "#toolbar=0&zoom=115"
                            );
                        }
                    } else {
                        this._alertService.error("Notes report is unavailable.");
                    }
                } );

        }
    }

    printNotesLog() {
        const newBlob = new Blob([this.exportedData], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function() {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
        }.bind(this);
    }
    cancelNote(indexOfelement, isSplice?) {
        if(isSplice)
             this.notesLogFormattedData.splice(indexOfelement,1);
        this.resetMode(indexOfelement);
        this.resetForm();
        this.showAdd = [];
    }

    previousPage() {
    
        if(this.IsFromRxHistory){
            this.CloseEditMode.emit("cancel");
        }else{
            this.CloseNotesValue.emit("Patient1");
        }
    }
    addSubCategory(payload) {
      const resp: any  =  this._drugServ.addoRUpdateNoteCategory(payload, "SAVE").toPromise();
    }
    
}

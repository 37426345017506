import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[BarcodeDetector]'
})
export class BarcodeDetectorDirective {

    timeoutHandler: any = 0;
    inputString = "";

    @Input('BarcodeDetector') enableBarcode: boolean = false;
    @Input('forRxSerialBarCode') forRxSerialBarCode: boolean;

    @Output() EmitBarcode = new EventEmitter<any>();

    @HostListener("window:keypress", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        this.inputString += event.key
        if (this.enableBarcode) {
            let inputStr = this.el.nativeElement.value;
            if (!inputStr && this.timeoutHandler) {
                clearTimeout(this.timeoutHandler);
            } else if (inputStr && inputStr.length === 1) {
                this.timeoutHandler = setTimeout(() => {
                    inputStr = this.el.nativeElement.value;
                    if ((!this.forRxSerialBarCode && inputStr && inputStr.length >= 10) || (this.forRxSerialBarCode) ) {
                        this.EmitBarcode.emit(this.inputString);
                        clearTimeout(this.timeoutHandler);
                        this.el.nativeElement.value = "";
                        this.inputString = "";
                    }
                }, 300);
            }
        }
    }
    constructor(private el: ElementRef) {}
}

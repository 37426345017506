import { MsgConfig } from "./../../../app.messages";
import { BucketService, CommonService, DocumentsService, DrugService, EditDrugService, PatPreDrugModalService, RphVerificationService, RxService } from "src/app/services";
import { UserService } from "./../../../services/user.service";
import { BaseUtils } from "src/app/utils/base.utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    EventEmitter,
    Output,
    HostListener
} from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { DrugPickVerificationService, AlertService } from "../../../services";
import { DrugPickVerify } from "../../../models/drug-pick-verify.model";
import { CloneRx, SystemSetting, AccCategoryE, RxE, ErxVersions, DocumentInputParams, SharedDocuments, DrugPickVerificationE, Rx1E, DrugDesc } from "../../../models";
import { ColorCode, CommonUtil, DrugUtils, NRxUtils, RxUtils, WijimoUtil } from "../../../utils";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { environment } from "../../../../environments/environment";
import { AuditLogUtils } from "src/app/utils/audit-log.util";
import { PrivMaskGuard } from "src/app/guards";
import { CollectionView } from "@grapecity/wijmo";
import { DomSanitizer } from "@angular/platform-browser";
import { EdRxComponent } from "../../ed-rx";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import * as moment from "moment";
import { AddDrugToBucketComponent } from "../../shared/add-drug-to-bucket/add-drug-to-bucket.component";
import * as wjcGrid from "@grapecity/wijmo.grid";
import { CommonWarnorconfirmPopupComponent } from "../../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { HoverOver } from "src/app/utils/hover-over.util";
import { CommonNotesLogComponent } from "../../shared/common-notes-log/common-notes-log.component";
import { CommonPrintService } from "src/app/services/common-print.service";
import { CommonPrintComponent } from "src/app/components/common-print/common-print.component";
import { FormatsUtil } from "src/app/utils/formats.util";
const drugImage = environment.DrugImage;

@Component({
    selector: "app-verify-info",
    templateUrl: "./verify-info.component.html"
})
export class VerifyInfoComponent implements OnInit {
    hideRxForm = false;
    hideNDCForm = false;
    showIOU = false;
    imgURL: string;
    RxRefillFG: FormGroup;
    rxInfo: any;
    modalRef: any;
    @ViewChild("WrongDRrug")
    WrongDRrug: any;
    @ViewChild("verifyFailed", { static: true })
    verifyFailed: any;
    @ViewChild("alreadyVerified", { static: true })
    alreadyVerified: any;
    @ViewChild("invalidRx", { static: true })
    invalidRx: any;
    @ViewChild("verifiedRx", { static: true })
    verifiedRx: any;
    @ViewChild("manualDPV")
    manualDPV: any;
    @ViewChild("PromptToDel", {static: true})
    PromptToDel: any;
    @ViewChild("TherapeuticDrugPrmpt", {static: true})
    TherapeuticDrugPrmpt: any;
    verifiedRxModalInfo : any;
    @Output()
    TriggerChangeTab = new EventEmitter();
    @Output()
    EnableRphTabAfterTab = new EventEmitter();    
    @Output()
    CloseDrugPickPatientHistory = new EventEmitter();
    @Output()
    emitRxDet = new EventEmitter();
    formGroupInvalid = false;
    proceedRph = false;
    drugVerifyInfo: DrugPickVerify = new DrugPickVerify();
    warningInfo: string;
    systemStng: SystemSetting;
    showNdc: boolean;
    drugInfo: any;
    accessPrivCate: any;
    rxPrivs: any;
    openOverride: boolean;
    privType: any;
    categoryId: any;
    StatusId: any;
    rxNo: any;
    detailsInfo: any;
    index: number;
    hasDocuments: boolean;
    pdfToShow: any;
    imageToShow: any;
    documentAvailable: boolean;
    documentId: any;
    documentsList: CollectionView;
    fileTypeToShow: string = null;
    eRxVersion: any;
    isBarcodeFetching = false;
    isPatientHistory: boolean = false;
    refillNo: any;
    fillNo: any;
    logAudit: boolean;
    allowDPV: boolean = false;
    forceBarcodeScanSetting: boolean;
    noImgFnd: boolean;
    wjHeaders: any;
    drugDesc: DrugDesc;
    notesType: any;
    iouRecs: any=[];
    IOUListWJ: CollectionView<any>;
    manualEnterDPV: boolean;
    drugLotInfo: any;
    drgImgNm: any;
    errorMessage: boolean;
    TotalDispVal = 0;
    newRecordAdded: boolean;
    pharName: string;
    techName: string;
    modalDel: any;
    DeletedId: any;
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }
    deleteIndex: any;
    promptDel: any;
    inputActDisp: string;
    modalTherap: any;
    therapQtyInHand: any;
    stopenterAgain: boolean;
    drugInformation: any;
    refrigeratorItem: any;
    totalDocs: any[];
    currentDocIndex: number;
    unsubscribe$: Subject<void> = new Subject();
    InputNDC ="";
    NDCValForManual: string;
    RphOpened: boolean;
    MMSData: any;
    dropDownValues: Object;
    IsStockbottleDrug: boolean;
    therapeqvResponse: any;
    DrugSlctd: any;
    SerilizedDatetime: any;
    oldIouRecs: any;
    isPopupVisible: boolean = false;
    isLoginVerify = false;
    minDate: { year: any; month: any; day: any };

    @Input()
    set RxInfo(rxinfo: any) {
        if (rxinfo) {
            this.rxInfo = rxinfo;
            this.rxNo =this.rxInfo.Prescription && this.rxInfo.Prescription.PrescNum;
            this.refillNo=this.rxInfo.PrescReFill.ReFillNum;
            this.fillNo=this.rxInfo.PrescReFill.PartialFillNo;
        }
    }

    @Input()
    set IsPatientHistory(patient: any) {
        this.isPatientHistory = patient;
    }

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
         if (event.which === 27 && this.modalRef) { // esc
            this.modalRef.close();
            event.preventDefault();
        }

    }

    constructor(
        private _fb: FormBuilder,
        private _drgVerifyServ: DrugPickVerificationService,
        private modalService: NgbModal,
        private _alertService: AlertService,
        private _rxUtils: RxUtils,
        private _commonSvc: CommonService,
        private _userService: UserService,
        private _router: Router,
        private _privMaskGuard: PrivMaskGuard,
        private _commonServ: CommonService,
        private _commonUtil: CommonUtil,
        private _sanitizer: DomSanitizer,
        private _docService: DocumentsService,
        private _auditUtils: AuditLogUtils,
        private _rphService: RphVerificationService,
        private _nrxUtils: NRxUtils,
        private _patPreDrugModalServ: PatPreDrugModalService,
        public _formatsUtil:FormatsUtil,
        public _buckUtils: DrugBucketUtil, private _wijimoUtils: WijimoUtil, private _drugUtils: DrugUtils, private _rxServ: RxService, private _drugSvc: EditDrugService,
        private _colorCode: ColorCode, private _hoverOver: HoverOver, private _drugBuckUtil: DrugBucketUtil, private _buckService: BucketService,
        private _commonPrintSer: CommonPrintService
    ) {
        this.accessPrivCate = AccCategoryE;
        this.rxPrivs = RxE;
        const UserName = this._userService.getToken("PhUserName");
        this.proceedRph = UserName ? true : false;
    }

    ngOnInit() {
        if (
            this._commonSvc.getSetttingValue("PharmacySettings", "SHOW_RX_DRUG_NDC_DRUG_PICK_VERF") === "0"
        ) {
            this.showNdc = false;
        } else {
            this.showNdc = true;
        }
        this.forceBarcodeScanSetting = this._commonSvc.getSetttingValue("PharmacySettings", "Force_bottle_scan_for_NDC_in_Drug_Pick_Verification") === "1" ? true : false;
        this.createFG();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "DPVVerfListWJ")) {
                this.patchDefaultValHeaders();
            }
        });
    }


    createFG() {
        this.RxRefillFG = this._fb.group({
            RxNum: null,
            RefillNum: null,
            PartialfillNum: null,
            Ndc: null,
            gtin: null,
            serialNo: null,
            lotNo: null,
            expirationDt: null,
        });
        this.showRxRefill();
    }
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("DPVVerfListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("DPVVerfListWJ", storedWJ);
    }

    async showRxRefill(frmOkClick?: boolean) {
        if (this.rxInfo) {
            const dataToPost = {
                PageNumber: 1,
                PageSize: 1,
                RxId: this.rxInfo.Prescription.PrescNum,
                RefillNum: this.rxInfo.PrescReFill.ReFillNum,
                PartialfillNum: this.rxInfo.PrescReFill.PartialFillNo,
                IsFromDrugPickVerifScreen: true
            };
            const response  = await this._rphService.getRphVerifyDetails(dataToPost).toPromise();
            this.rxInfo = response && response.data.length ? response.data[0] : this.rxInfo;
            this.emitRxDet.emit(this.rxInfo);
            this.RxRefillFG.controls["RxNum"].patchValue(
                this.rxInfo.Prescription.PrescNum
            );
            this.RxRefillFG.controls["RefillNum"].patchValue(
                this.rxInfo.PrescReFill.ReFillNum.toString()
            );
            this.RxRefillFG.controls["PartialfillNum"].patchValue(
                this._commonServ.checkIsPartialFill(this.rxInfo.PrescReFill, this.rxInfo.Prescription) ?
                this.rxInfo.PrescReFill.PartialFillNo.toString() : ""
            );
            if (this.rxInfo.Iou?.length) {
                if (frmOkClick) {
                    this.iouRecs = [... this.rxInfo.Iou];
                } else {
                    this.rxInfo.Iou.map((item: any) => this.iouRecs.push(item));
                }
            } 
            if (this.iouRecs && this.iouRecs.length) {
                this.TotalDispVal = 0;
                this.iouRecs.map((item: any, i) => {
                    this.TotalDispVal = +this.TotalDispVal + +item.DispQty;
                })
                this.generateIOU(this.iouRecs);
            }
            this.StatusId = this._commonUtil.getRxStatusForDisplay(this.rxInfo);
            this.getDrugImage();
            this.showRx();
            this.getNotesLogFromRxtoDPV();

        }
    }

    async getNotesLogFromRxtoDPV() {
        const PrescReFill = this.rxInfo.PrescReFill;
        const Prescription = this.rxInfo.Prescription;
        const notesList = await this._nrxUtils.getNotesLog("RxAnnotation",Prescription?.Id, {RxType :  'dpv' , PrescReFill : PrescReFill?.["Id"], PrescNum : Prescription["PrescNum"]}) as any;
        if( notesList && notesList?.Note)
              notesList.Note = notesList.Note.concat(notesList.AnnotationNotes);
        if(notesList?.Note?.filter(ele => ele.IsShownAsPopup).length) {
            const modelRef = this.modalService.open(CommonNotesLogComponent,{keyboard : false, backdrop : false, centered : true , windowClass: "max-modal-970"});
            modelRef.componentInstance.NotesListFrmPage = {
                Note: notesList.Note.filter((item: any) => {
                    return item.IsShownAsPopup;
                })
            };
            modelRef.componentInstance.RxType =  "dpv";
            modelRef.componentInstance.IsNotesLogPopupClosed.subscribe((res : any)=>{
                modelRef.close();
            })
        }
    }

    validateVal(item?, rowId?, event?) {
        if (!this.RphOpened) {
            let maxVal = 0;
            let cumVal = 0;
            if (event && event.target && +event.target.value <= 0) {
                this.errorMessage = true;
                item.errorMessage = "Value must be > 0";
                return;
            } else {
                const editRec = this.iouRecs.find((item: any) => item.RowId === rowId);
                this.oldIouRecs = JSON.parse(JSON.stringify(this.iouRecs));
                this.iouRecs.map((item: any) => {
                    if (rowId !== item.RowId && item.DrugNDC) {
                        cumVal = cumVal + item.DispQty
                    }
                });
                maxVal = this.rxInfo?.PrescReFill?.DispQty - cumVal;
                const diffVal = editRec.DispQty - (event && event.target ? +event.target.value : 0);
                if (event && event.target && +event.target.value > maxVal) {
                    this.errorMessage = true;
                    event.target.value = (+event.target.value).toFixed(3);
                    item.errorMessage = "Max allowed value is "+ maxVal +" only.";
                    return;
                } else {
                    if(this.rxInfo?.Drug?.drugtypeid == 2 && this.rxInfo?.PrescReFill?.DispQty != +event.target.value){
                        this.errorMessage = true;
                        item.errorMessage = "Compounds cannot be dispensed partially.";
                        return;
                    }
                    if (this.iouRecs && this.iouRecs.length > 1) {
                        let count = 0;
                        this.iouRecs.map((item: any) => {
                            if (!item.DrugNDC) {
                                item.DispQty = ((+item.DispQty) + diffVal);
                            }
                            if (item.RowId === rowId) {
                                item.DispQty = (event && event.target ? +event.target.value : 0);
                            }
                            count = count + (editRec && editRec.RowId === item.RowId ? (event && event.target ? +event.target.value : 0) : (+item.DispQty));
                        })
                        this.TotalDispVal = count;
                        const index = this.iouRecs.findIndex((item: any) => !(+item.DispQty));
                        if (index > -1) {
                            this.deleteIndex = index;
                            this.commonPrompt();
                            // this.promptDel = this.modalService.open(this.PromptToDel, {centered: true, keyboard: false, backdrop: false, windowClass: "large--content"});
                        } else {
                            this.generateIOU(this.iouRecs);
                        }
                    } else {
                        if (this.iouRecs && this.iouRecs.length) {
                            this.iouRecs[0].DispQty = (event && event.target ? +event.target.value : 0);
                            this.TotalDispVal = (event && event.target ? +event.target.value : 0);
                            this.generateIOU(this.iouRecs);
                        }
                    }
                    
                    this.errorMessage = false;
                    item.errorMessage = "";
                    if (event.altKey) {
                        this.verifyInfo();
                    }
                }
            }
        }
        
        
    }
    commonPrompt() {
        const msgText = "The edited quantity is equal to the total dispensed quantity, so the newly added record will be deleted.";
        const modelRef = this.modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
        });
        modelRef.componentInstance.warnMsg =  msgText;
        modelRef.componentInstance.IsHeaderText = "Information";
        modelRef.componentInstance.okButton = true;
        modelRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe( async (resp: any) => {
            modelRef.close();
            if(resp) {
               this.closePromtToDel();

            }
        });
    }
    closePromtToDel() {
        this.iouRecs.splice(this.deleteIndex, 1);
        this.generateIOU(this.iouRecs);
    }
    generateIOU(IOUList) {
        const mapDataSet=[];
        IOUList.map((rx, i) => {
                const k = {};
                k["RowId"] = rx.RowId;
                k["IOUId"] = rx.Id;
                k["DrugPickVerifId"] = rx.DrugPickVerifId;
                k["Drug NDC"] = rx.DrugNDC;
                k["Drug Name"] = rx.DrugName;
                k["DrugClass"] = rx.DrugClass;
                k["Manufacturer"] = rx.manufacturername;
                k["Qty in Hand"] = rx.QtyInHand ? (+rx.QtyInHand).toFixed(3): "0.000";
                k["Actual Qty. Dispensed"] = rx.DispQty ? (rx.DispQty).toFixed(3) : "0.000";
                k["Source"] = rx.Source
                k["UserID"] = rx.UserId;
                k["LotNo"] = rx.LotNum;
                k["ExpirationDate"] = rx.LotExpiryDtTm ? moment.utc(rx.LotExpiryDtTm).local().format("MM/DD/YYYY") : "";
                k["GTIN"] = rx.GTIN;
                k["SerialNo"] = rx.SerialNo;
                k["DateTm"] = rx.DispDtTm ? moment.utc(rx.DispDtTm).local().format("MM/DD/YYYY hh:mm:ss a") : "";
                k["DrugDescription"] = rx.drugDescription;
                k["DrugImage"] = rx.DrugImage;
                k["ImgUrl"] = "";
                k["errorMessage"]="";
                k["Status"] = (this.rxInfo.PrescReFill.StatusId === 4 || this.rxInfo.Prescription.RxStatusId === 4) ? "T" :
                (this.rxInfo.PrescReFill.StatusId === 2 || this.rxInfo.Prescription.RxStatusId === 2 || this.rxInfo.RxBill.StatusId === 6) ? "D" :
                    this.rxInfo.PrescReFill.StatusId === 3 && this.rxInfo.RxBill.StatusId === 2 ? "BI" :
                        (this.rxInfo.RxBill.StatusId === 2 &&
                            this.rxInfo.PrescReFill.StatusId !== 4 && this.rxInfo.PrescReFill.StatusId !== 2) ? "B" :
                            (this.rxInfo.PrescReFill.StatusId === 3 && this.rxInfo.RxBill.StatusId === 1) ? "UI" : "U";
                k["IsPicked"] = (this.rxInfo.PickupRx && this.rxInfo.PickupRx.IsPickedUp) ? true : false;
                k["IsRefrigeratorItem"] = rx.IsRefrigeratorItem;
                k["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(k);
                k["drugLotInfo"] = rx.drugLotInfo;
                mapDataSet.push(k);
            }),
        this.IOUListWJ = new CollectionView(mapDataSet,
            {
                sortComparer: (a: any, b: any) => {
                    return moment(a).isValid() && moment(b).isValid()
                        ? moment(a).diff(moment(b)) : null;
                }
            })
        
            this.IOUListWJ.items.map(async (Item: any) => {
              Item.ImgUrl = await this._drugUtils.getImageName(null, null, false, Item.DrugImage, true);
            })
    }

    addnewIOU() {
        this.newRecordAdded = true;
        if (this.iouRecs?.length) {
            this.RxRefillFG.controls["Ndc"].patchValue(null);
            const newRow = {
                RowId: this.iouRecs && this.iouRecs.length ? this.iouRecs.length : 0,
                Id: null,
                DrugPickVerifId: null,
                DrugNDC : "",
                DrugName :  "",
                DrugClass: "",
                manufacturername : "",
                QtyInHand : "",
                DispQty : this.rxInfo?.PrescReFill?.DispQty - this.TotalDispVal,
                Source : "",
                UserId : "",
                LotNum: "",
                LotExpiryDtTm : "",
                GTIN : "",
                SerialNo : "",
                DispDtTm : null,
                drugDescription : "",
                DrugImage : "",
                DrugLotId: "",
                IsRefrigeratorItem: false
            }
            this.TotalDispVal = (+this.TotalDispVal) + (+newRow.DispQty);
            this.iouRecs.push(newRow);
            this.generateIOU(this.iouRecs);
        }
    }

    openDeleteConfirm(content: any) {
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
        this.modalDel = this.modalService.open(content, {centered: true, keyboard: false, backdrop: false, windowClass: "large--content"})
    }

    async deleteIOU(deleteItem: any) {
        let RowId = deleteItem.RowId;
        if (this.modalDel) {
            this.modalDel.close();
            this.modalDel = null;
        }
        const recdToDel = this.iouRecs.find((item: any) => item.RowId === RowId);
        if (deleteItem.IOUId || deleteItem.DrugPickVerifId) {
            const deleteData = {
                PrescId: this.rxInfo.Prescription.Id,
                PrescrefillId: this.rxInfo.PrescReFill.Id,
                IsFromDPV: true,
                iouId: deleteItem.IOUId,
                DrugPickVerifId: deleteItem.DrugPickVerifId,
                PrescNum: this.rxInfo.Prescription.PrescNum,
                RefillNum: this.rxInfo.PrescReFill.ReFillNum,
                FillNum: this.rxInfo.PrescReFill.PartialFillNo,
            }
            const resp = await this._rxServ.deleteIOURecord(deleteData).toPromise();
            // .subscribe((resp: any) => {
                if (resp) {
                    const index = this.iouRecs.findIndex((item: any) => item.RowId === RowId);
                    this.iouRecs.splice(index, 1);
                    if (this.iouRecs?.length) {
                        this.iouRecs.map((item: any) => {
                            if (item.RowId > RowId) {
                                item.RowId = item.RowId - 1;
                            }
                        })
                    }
                    
                    // const emptyRec = this.iouRecs.find((item: any) => item.DrugNDC === "");
                    // if (recdToDel && emptyRec && RowId !== emptyRec.RowId) {
                    //     emptyRec.DispQty = emptyRec.DispQty + recdToDel.DispQty;
                    //     this.iouRecs.map((item: any) => {
                    //         if (emptyRec && item.RowId === emptyRec.RowId) {
                    //             item.DispQty = emptyRec.DispQty;
                    //         }
                    //     })
                    // }
                    // this.generateIOU(this.iouRecs);
                }
            // });
        } else {
            const index = this.iouRecs.findIndex((item: any) => item.RowId === RowId);
            this.iouRecs.splice(index, 1);
            this.iouRecs.map((item: any) => {
                if (item.RowId > RowId) {
                    item.RowId = item.RowId - 1;
                }
            })
            // this.TotalDispVal = this.TotalDispVal ? (this.TotalDispVal - ((emptyRec && emptyRec.RowId === RowId )? emptyRec.DispQty : recdToDel.DispQty)) : this.TotalDispVal;
            this.newRecordAdded = false;
            this.InputNDC = "";
        }
        if (this.iouRecs && this.iouRecs.length) {
            const emptyRec = this.iouRecs.find((item: any) => item.DrugNDC === "");
            this.TotalDispVal = 0;
            let nonEmptyVal = 0;
            if (emptyRec) {
                this.iouRecs.map((item: any, i) => {
                    if (item.DrugNDC) {
                        nonEmptyVal = nonEmptyVal + (+item.DispQty)
                    }
                })
                emptyRec.DispQty = this.rxInfo.PrescReFill.DispQty - nonEmptyVal;
                this.iouRecs.map((item: any) => {
                    if (emptyRec && item.RowId === emptyRec.RowId) {
                        item.DispQty = emptyRec.DispQty;
                    }
                    this.TotalDispVal = +this.TotalDispVal + +item.DispQty;
                })
            } else {
                this.iouRecs.map((item: any, i) => {
                    this.TotalDispVal = +this.TotalDispVal + +item.DispQty;
                }) 
            }
            this.generateIOU(this.iouRecs);
        } else {
            this.TotalDispVal = 0;
        }
        this.errorMessage = false;
        
    }
    closeDeleteModal() {
        this.DeletedId = "";
        if (this.modalDel) {
            this.modalDel.close();
            this.modalDel = null;
        }
    }

    getRxInfo() {

    }

    getDocuments() {
        const postDoc = new DocumentInputParams();
        postDoc.DocCatId = SharedDocuments.Prescription;
        postDoc.PrescNum = this.rxNo;
        postDoc.RefillNum=this.refillNo;
        postDoc.FillNum=this.fillNo;
        this._docService.getDocList(postDoc).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp && resp.Documents && resp.Documents.length > 0) {
                this.totalDocs = resp.Documents;
                let document1: any = [], document2: any = [];
                if (this.rxInfo) {
                    resp.Documents.push({
                        Priority: !this.rxInfo.XmlFileId ? 0 : resp.ErxDocumentPriority,
                        data: !this.rxInfo.XmlFileId ? "rxDetails": "erxDetails",
                        row: resp.Documents.length,
                         Id: 123456,
                        "Title":!this.rxInfo.XmlFileId ? "rxDetails": "erxDetails",
                        "Comment" : "",
                          "Remarks" :"",
                          "Extn" : "",
                          "TenantId" : "",
                          "FileName" : !this.rxInfo.XmlFileId ? "rxDetails": "erxDetails",
                          "CreatedDtTm" : "",
                          "ModifiedDtTm" : ""
                    });
                  }
                  document1 = resp.Documents.filter((ele: any) => {
                   return (ele.Priority === 0);
                });
                document2 = resp.Documents.filter((ele: any) => {
                    return (ele.Priority !== 0);
                 });
                 document2.sort((a, b) => {
                    return a.Priority  - b.Priority;
                 });
                
                 resp.Documents = [...document2, ...document1];
                this.previewSelectedFile(resp.Documents[0]);
                this.generateList(resp.Documents);
                this.hasDocuments = true;
            } else {
                this.hasDocuments = false;
                this.totalDocs = [];
                    this.currentDocIndex = 0;
                    this.totalDocs.push({
                        Priority: !this.rxInfo.XmlFileId ? 0 : resp.ErxDocumentPriority,
                        data: !this.rxInfo.XmlFileId ? "rxDetails": "erxDetails",
                        row:0,
                         Id: 123456,
                        "Title": !this.rxInfo.XmlFileId ? "rxDetails": "erxDetails",
                        "Comment" : "",
                          "Remarks" :"",
                          "Extn" : "",
                          "TenantId" : "",
                          "FileName" : !this.rxInfo.XmlFileId ? "rxDetails": "erxDetails",
                          "CreatedDtTm" : "",
                          "ModifiedDtTm" : ""
                    });
                  this.fileTypeToShow = this.rxInfo.XmlFileId ? "erxDetails" : 'rxDetails';
            }
        });
    }

    generateList(data: any) {
        if (data && data.length > 0) {
            this.documentsList = new CollectionView(
                data.map((document, i) => {
                    const j = {};
                    j["data"] = document;
                    j["row"] = i;
                    j["Id"] = document.Id;
                    j["Document ID"] = document.Id;
                    j["Title"] = document.Name;
                    j["Comment"] = (document.Remarks === "null") ? "" : document.Remarks;
                    j["Extn"] = document.Extn;
                    j["FileName"] = document.DocFileName ? document.DocFileName  : "";
                    j["TenantId"] = document.TenantId;
                    j["CreatedDtTm"] = document.CreatedDtTm;
                    j["ModifiedDtTm"] = document.ModifiedDtTm;
                    return j;
                })
            );
            this.previewSelectedFile(0);
        } else {
            this.documentsList = new CollectionView([]);
        }
    }
    navigateToNextImage(next?) {

        let index = 0;
        if (next) {
            if (this.currentDocIndex === (this.totalDocs.length - 1)) {
                index = 0;
            } else {
                index = this.currentDocIndex + 1;
            }
        } else {
            if (this.currentDocIndex === 0) {
                index = (this.totalDocs.length) - 1;
            } else {
                index = (this.currentDocIndex) - 1;
            }
        }
        this.previewSelectedFile(this.totalDocs[index]);
    }
    basedonPriority() {
        let document1: any = [], document2: any = [];
        document1 = this.totalDocs.filter((ele: any) => {
         return (ele.Priority === 0);
      });
      document2 = this.totalDocs.filter((ele: any) => {
          return (ele.Priority !== 0);
       });
       document2.sort((a, b) => {
          return a.Priority  - b.Priority;
       });
       this.totalDocs = [...document2, ...document1];
    }

    async previewSelectedFile(val: any) {
        await  this.basedonPriority();
        this.currentDocIndex = this.totalDocs.findIndex((ele: any) => {
            return (ele.Id === val.Id);
        });
    this.currentDocIndex = (this.currentDocIndex === -1) ? 0 : this.currentDocIndex;
    if (val && (val.FileName === "erxDetails" || val.FileName === "rxDetails")) {
        this.fileTypeToShow = val.FileName;
    } else if (val &&  val.FileName !== "erxDetails") {
        this.pdfToShow = null;
        this.imageToShow = null;
        this.documentAvailable = false;
        this.documentId = val.Id;
        this._docService
            .getDocumentByName(val).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    if (resp.type === "application/pdf") {
                        this.createPdfFromBlob(resp);
                    } else {
                        this.createImageFromBlob(resp);
                    }
                }
        });
    }
        /*this.pdfToShow = null;
        this.imageToShow = null;
        this.documentAvailable = false;
        this.documentId = val.Id;
        this._docService.getDocumentByName(val)
            .subscribe(resp => {
                if (resp) {
                    if (resp.type === "application/pdf") {
                        this.createPdfFromBlob(resp);
                    } else {
                        this.createImageFromBlob(resp);
                    }
                }
        });*/
    }

    createPdfFromBlob(image: Blob) {
        this.fileTypeToShow = "pdf";
        const urlCreator = window.URL;
        this.pdfToShow = this._sanitizer.bypassSecurityTrustResourceUrl(
            urlCreator.createObjectURL(image) + "#toolbar=0"
        );
        this.documentAvailable = true;
    }

    createImageFromBlob(image: Blob) {
        this.fileTypeToShow = "image";
        const urlCreator = window.URL;
        this.imageToShow = this._sanitizer.bypassSecurityTrustResourceUrl(
            urlCreator.createObjectURL(image)
        );
        this.documentAvailable = true;
    }
    enteredDrugLot(item?, rowId?, event?,isforLotNum?){
        let lotDate = moment.utc(this.minDate).local().format("MM/DD/YYYY");
        if(isforLotNum)
        {
            item.ExpirationDate = event?.value ? moment.utc(event?.value.ExpDt).local().format("MM/DD/YYYY") : item.ExpirationDate;
            this.iouRecs[rowId]["DrugLotId"] = event?.value ? event?.value.Id : this.iouRecs[rowId]["DrugLotId"];
            this.iouRecs[rowId]["LotExpiryDtTm"] = event?.value ? moment.utc(event?.value.ExpDt).local().format("MM/DD/YYYY") : item.ExpirationDate;
            this.iouRecs[rowId]["LotNum"] = event?.value ? event?.value.LotNum : item.LotNo;
        } else {
            this.iouRecs[rowId]["LotExpiryDtTm"] = event?.target ? event?.target?.value : event;
            if(event?.target?.value == ""){
                event.target.value = lotDate;
                item.ExpirationDate = lotDate;
            }
        }
    }

    getDrugImage() {
        if (this.rxInfo.Drug) {
            this._commonServ.getDrugLotDescpInfo(this.rxInfo.Drug.id, this.rxInfo.PrescReFill.BucketId).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                const drugInfo:any = resp;
                this.refrigeratorItem = drugInfo.DrugInfo.IsRefrigeratorItem;
                this.drugLotInfo = drugInfo.DrugLots;
                this.drugDesc = drugInfo.DrugDescription;
                this.drgImgNm = drugInfo.ImageName;
                if (drugInfo.ImageName) {
                    const drugImageFileSplit = drugInfo.ImageName.split(".");
                    this._commonServ
                        .isFileExist(
                            drugImageFileSplit[0],
                            drugImageFileSplit[1]
                        ).pipe(takeUntil(this.unsubscribe$))
                        .subscribe(isExist => {
                            if (isExist) {
                                this.noImgFnd = false;
                                this.imgURL = drugImage.replace(
                                    "{imagefile}",
                                    "" +
                                    drugImageFileSplit[0] +
                                    "." +
                                    drugImageFileSplit[1]
                                );
                            } else {
                                this.noImgFnd = true;
                                this.imgURL = drugImage.replace(
                                    "{imagefile}",
                                    "empty.jpg"
                                );
                            }
                        });
                } else {
                    this.noImgFnd = true;
                    this.imgURL = drugImage.replace("{imagefile}", "empty.jpg");
                }
            } else {
                this.noImgFnd = true;
                this.imgURL = drugImage.replace(
                    "{imagefile}",
                    "empty.jpg"
                );
            }
        });
        }
    }

    getRxVerifyDetails(XmlFileId: number, XmlVersion: string) {
        if (!XmlVersion) {
            const versionsetting = this._commonServ.getSetttingValue("ERxSettings", "E-PRESCRIPTION_SCRIPT_VERSION");
            if (versionsetting === "2") {
                this.eRxVersion = ErxVersions.NewerVersion;
            } else {
                this.eRxVersion = ErxVersions.OldVersion;
            }
        } else {
            this.eRxVersion = XmlVersion;
        }
        this._rphService.getRxVerifyDetails(XmlFileId, this.eRxVersion).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                this.detailsInfo = resp;
            } else {
                this.detailsInfo = null;
            }
        });
    }



    focusToFirst(event) {
        if (document.getElementById("RxNumVer")) {
            event.preventDefault();
            document.getElementById("RxNumVer").focus();
        }
    }

    showRx() {
        this.hideRxForm = true;
        if (this.rxInfo && this.rxInfo.XmlFileId) {
            this.getRxVerifyDetails(this.rxInfo.XmlFileId, this.rxInfo.XmlVersion);
        } 
        // else {
            this.getDocuments();
        // }
    }

    get Ndc(): FormControl {
        return this.RxRefillFG.controls["Ndc"].get("Ndc") as FormControl;
    }

    getNdcDetails(event) {
        event.preventDefault();
        this.InputNDC = event && event.target ? event.target.value : "";
        this.RxRefillFG.controls["Ndc"].patchValue(this.InputNDC);
        if (this.RxRefillFG.value && this.RxRefillFG.value.Ndc && this.RxRefillFG.value.Ndc.length) {
            this.NDCValForManual = this.InputNDC;
            setTimeout(() => {
                this.manualEnterDPV = true;
                if (!this.forceBarcodeScanSetting ) {
                    if (!this.isBarcodeFetching) {
                        this.InputNDC = "";
                        this.showNDC();
                    }
                } else if (!this.isBarcodeFetching) {
                    // this.RxRefillFG.controls["Ndc"].patchValue(null);
                    if (!this._privMaskGuard.canActivate(AccCategoryE.DrugPickVerification, DrugPickVerificationE.AllowManualDrugPickVerification)) {
                        this.allowDPV = true;
                        this.InputNDC = "";
                        this.openOverideDPV("AllowManualDrugPickVerification");
                    } else {
                        this.InputNDC = "";
                        this.modalRef = this.modalService.open(this.manualDPV,
                            {centered: true,  size: "sm",
                            windowClass: "medium-sm-modal-30 large--content", backdrop: false});
                    }
                    // this._alertService.error("Please scan the drug from Barcode only");
                }
            }, 500);
        }
        
    }
    manualDPVPriv() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        this.showNDC();
    }

    invalidRNdcWarning() {
        this.warningInfo = "Invalid NDC number.";
        this.modalRef = this.modalService.open(this.invalidRx, {
            centered: true,
            keyboard: false,
            backdrop: false,
            windowClass: "large--content"
        });
        this.InputNDC = "";
        this.RxRefillFG.controls["Ndc"].patchValue(null);
    }

    showNDC() {
        
        let drugLotId: any;
        this.pharName = this._userService.getToken("PhUserName");
        this.techName = this._userService.getToken("TechUserName");
        const verifyInfo = this.RxRefillFG.value;
        if (verifyInfo && verifyInfo.Ndc && verifyInfo.Ndc !== "") {
            this._drgVerifyServ
            .getRxDrugStatus(
                verifyInfo.RxNum,
                verifyInfo.RefillNum,
                verifyInfo.PartialfillNum ? verifyInfo.PartialfillNum : 1, 
                verifyInfo.Ndc
            ).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                // this.isBarcodeFetching = false;
                if (resp && resp.result === 1) {
                    this.hideNDCForm = true;
                    // this.showGrid = true;
                    if (this.drugLotInfo?.length && this.isBarcodeFetching) {
                        drugLotId = this.drugLotInfo.find((item: any) => 
                        ((item.LotNum === this.RxRefillFG.controls.lotNo.value) && (new Date(this.RxRefillFG.controls.expirationDt.value).getTime() === new Date(item.ExpDt).getTime())));
                    }
                    this.drugVerifyInfo.Result = "p";
                    if (this.iouRecs && !this.iouRecs.length) {
                        const data = {
                            RowId: 0,
                            Id: null,
                            DrugPickVerifId: null,
                            DrugNDC : this.isBarcodeFetching ? this.RxRefillFG.controls.Ndc.value : this.rxInfo.Drug.drugcode,
                            DrugName :  this.rxInfo?.Drug?.drugname + " " + this.rxInfo?.Drug?.strength + " " + this.rxInfo?.Drug?.drugformname,
                            DrugClass :  this.rxInfo?.Drug?.drugclass,
                            manufacturername : this.rxInfo.Drug.manufacturername,
                            QtyInHand : this._buckUtils.getQtyInHand(this.rxInfo.Drug?.manageinventory ? this.rxInfo.Drug?.manageinventory : null, this.rxInfo.PrescReFill.BucketId),
                            DispQty : this.rxInfo?.PrescReFill?.DispQty,
                            Source : this.manualEnterDPV ? "User" : "",
                            UserId : this.techName ? this.techName : this.pharName,
                            LotNum: this.isBarcodeFetching ? this.RxRefillFG.controls.lotNo.value : (this.drugLotInfo?.length ? this.drugLotInfo[0].LotNum : ""),
                            LotExpiryDtTm : this.isBarcodeFetching ? this.RxRefillFG.controls.expirationDt.value : (this.drugLotInfo?.length ? this.drugLotInfo[0].ExpDt : ""),
                            GTIN :this.isBarcodeFetching ? this.RxRefillFG.controls.gtin.value : "",
                            SerialNo : this.isBarcodeFetching ? this.RxRefillFG.controls.serialNo.value : "",
                            DispDtTm : null,
                            drugDescription : this.drugDesc,
                            DrugImage : this.drgImgNm,
                            DrugLotId: this.isBarcodeFetching ? (drugLotId ? drugLotId.Id : null) : (this.drugLotInfo?.length ? this.drugLotInfo[0].Id : null),
                            IsRefrigeratorItem : this.refrigeratorItem,
                            DSCSAId: this._commonSvc.DecryptData(sessionStorage.getItem('DSCSAId')),
                            drugLotInfo: this.drugLotInfo
                        }
                        this.TotalDispVal = (+this.TotalDispVal) + (+data.DispQty);
                        this.iouRecs.push(data);
                        this.generateIOU(this.iouRecs);

                    } else{
                        const emptyList = this.iouRecs.find((item: any) => !item.DrugNDC);
                        if (emptyList) {
                            this.iouRecs.map((item: any) => {
                                if (!item.DrugNDC ) {
                                    item.RowId= item.RowId,
                                    item.Id = null,
                                    item.DrugPickVerifId = null,
                                    item.DrugNDC = this.isBarcodeFetching ? this.RxRefillFG.controls.Ndc.value : this.rxInfo.Drug.drugcode,
                                    item.DrugName = this.rxInfo?.Drug?.drugname + " " + this.rxInfo?.Drug?.strength + " " + this.rxInfo?.Drug?.drugformname,
                                    item.DrugClass =  this.rxInfo?.Drug?.drugclass,
                                    item.manufacturername = this.rxInfo.Drug.manufacturername,
                                    item.QtyInHand = this._buckUtils.getQtyInHand(this.rxInfo.Drug?.manageinventory ? this.rxInfo.Drug?.manageinventory : null, this.rxInfo.PrescReFill.BucketId),
                                    item.DispQty = item.DispQty,
                                    item.Source =this.manualEnterDPV ? "User" : "",
                                    item.UserId = this.techName ? this.techName : this.pharName,
                                    item.LotNum= this.isBarcodeFetching ? this.RxRefillFG.controls.lotNo.value : (this.drugLotInfo?.length ? this.drugLotInfo[0].LotNum : ""),
                                    item.LotExpiryDtTm = this.isBarcodeFetching ? this.RxRefillFG.controls.expirationDt.value : (this.drugLotInfo?.length ? this.drugLotInfo[0].ExpDt : ""),
                                    item.GTIN =this.isBarcodeFetching ? this.RxRefillFG.controls.gtin.value : "",
                                    item.SerialNo = this.isBarcodeFetching ? this.RxRefillFG.controls.serialNo.value : "",
                                    item.DispDtTm = "",
                                    item.drugDescription = this.drugDesc,
                                    item.DrugImage = this.drgImgNm,
                                    item.DrugLotId = this.isBarcodeFetching ? (drugLotId ? drugLotId.Id : null) : (this.drugLotInfo?.length ? this.drugLotInfo[0].Id : null),
                                    item.IsRefrigeratorItem = this.refrigeratorItem,
                                    item.drugLotInfo = this.drugLotInfo
                                }
                            });
                        } else {
                            const data = {
                                RowId: this.iouRecs && this.iouRecs.length ? this.iouRecs.length : 0,
                                Id: null,
                                DrugPickVerifId: null,
                                DrugNDC : this.isBarcodeFetching ? this.RxRefillFG.controls.Ndc.value : this.rxInfo.Drug.drugcode,
                                DrugName :  this.rxInfo?.Drug?.drugname + " " + this.rxInfo?.Drug?.strength + " " + this.rxInfo?.Drug?.drugformname,
                                DrugClass :  this.rxInfo?.Drug?.drugclass,
                                manufacturername : this.rxInfo.Drug.manufacturername,
                                QtyInHand : this._buckUtils.getQtyInHand(this.rxInfo.Drug?.manageinventory ? this.rxInfo.Drug?.manageinventory : null, this.rxInfo.PrescReFill.BucketId),
                                DispQty : this.rxInfo?.PrescReFill?.DispQty - this.TotalDispVal,
                                Source : this.manualEnterDPV ? "User" : "",
                                UserId : this.techName ? this.techName : this.pharName,
                                LotNum: this.isBarcodeFetching ? this.RxRefillFG.controls.lotNo.value : (this.drugLotInfo?.length ? this.drugLotInfo[0].LotNum : ""),
                                LotExpiryDtTm : this.isBarcodeFetching ? this.RxRefillFG.controls.expirationDt.value : (this.drugLotInfo?.length ? this.drugLotInfo[0].ExpDt : ""),
                                GTIN : this.isBarcodeFetching ? this.RxRefillFG.controls.gtin.value : "",
                                SerialNo : this.isBarcodeFetching ? this.RxRefillFG.controls.serialNo.value : "",
                                DispDtTm : null,
                                drugDescription : this.drugDesc,
                                DrugImage : this.drgImgNm,
                                DrugLotId: this.isBarcodeFetching ? (drugLotId ? drugLotId.Id : null) : (this.drugLotInfo?.length ? this.drugLotInfo[0].Id : null),
                                IsRefrigeratorItem : this.refrigeratorItem,
                                DSCSAId: this._commonSvc.DecryptData(sessionStorage.getItem('DSCSAId')),
                                drugLotInfo: this.drugLotInfo
                            }
                            this.TotalDispVal = (+this.TotalDispVal) + (+data.DispQty);
                            this.iouRecs.push(data);
                        }
                        this.generateIOU(this.iouRecs);
                    }
                } else if (resp && resp.result === 2) {
                    this.therapeqvResponse = resp;
                    this.IsStockbottleDrug = false;
                    this.modalTherap = this.modalService.open(this.TherapeuticDrugPrmpt, {centered: true, backdrop:false, keyboard: false, windowClass: "large--content"})
                } else if (resp && resp.result === -1) {
                    this.auditLogData();
                    this.MMSData = resp.MMSSearch?.length ? resp.MMSSearch[0] : null;
                    if (resp.MMSSearch?.length) {
                        this.openForMMS();
                    } else {
                        // this.logAudit = true;
                        if (!this.isPopupVisible) {
                            this.isPopupVisible = true;
                            this.invalidRNdcWarning();
                          }
                        
                    }
                } else if (resp && resp.result === 3) {
                    this.therapeqvResponse = resp;
                    this.IsStockbottleDrug = true;
                    this.modalTherap = this.modalService.open(this.TherapeuticDrugPrmpt, {centered: true, backdrop:false, keyboard: false, windowClass: "large--content"})
                } else if (resp && resp.result === -2) {
                    this.auditLogData();
                    // this.drugVerifyInfo.Result = "f";
                    // this.logAudit = true;
                    this.modalRef = this.modalService.open(this.verifyFailed, {
                        centered: true,
                        keyboard: false,
                        backdrop: false,
                        windowClass: "medium-sm-modal-305 large--content"
                    });
                } else {
                    this._alertService.error(MsgConfig.WRONG_MSG);
                }
            });
        }
    }
    isEqual(obj1: any, obj2: any): boolean {
        if (!obj2) {
            return false
        }
        return obj1.Id === obj2?.Id && obj1.DispQty === obj2.DispQty;
    }

    async printLabel(index) {
        if (this.iouRecs && this.iouRecs[index]) {
            this.rxInfo.Drug.drugcode = this.iouRecs[index]?.DrugNDC;
            this.rxInfo.isFrmDpvVerified = index;
            this._commonPrintSer.printLabelToRx(this.rxInfo, false, false, false, "labelPrint", "dpvVerf");
        }
    }

    printLabelCheck() {
        this.isLoginVerify = false;
        let nonMatchingValues = Object.keys(this.iouRecs).filter(key => {
            return !this.isEqual(this.iouRecs[key], this.oldIouRecs[key]);

        });
        this.printLabelFrmPopup(nonMatchingValues)
        this.closeModal('success', 'pass')
    }

    printLabelFrmPopup(nonMatchingValues) {
        if (nonMatchingValues && nonMatchingValues.length && this.iouRecs && this.iouRecs[+nonMatchingValues[0]]) {
            this.rxInfo.Drug.drugcode = this.iouRecs[+nonMatchingValues[0]]?.DrugNDC;
            this.rxInfo.isFrmDpvVerified = +nonMatchingValues[0];
            this._commonPrintSer.printLabelToRx(this.rxInfo, false, false, false, "labelPrint", "dpvVerf");
        } else if (nonMatchingValues && !nonMatchingValues.length && !this.isLoginVerify) {
            this.closeModal('rph', 'pass')
            this.isLoginVerify = true;
        }
        nonMatchingValues.shift()
        this._commonPrintSer.isLabelPrintCompleted().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
            if (res && res.fromScreen == "dpvVerf") {
                this._commonPrintSer.setPrintCompleted(null);
                //Without settimeout unable to print mutiple records of IOU
                setTimeout(() => {
                    this.printLabelFrmPopup(nonMatchingValues)
                },0)
            }
       })
    }

    openForMMS() {
        const msgText = "The selected NDC# is not found. Would you like to add?";
        const modelRef = this.modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
        });
        modelRef.componentInstance.warnMsg =  msgText;
        modelRef.componentInstance.buttonType = "Add From MMS";
        modelRef.componentInstance.shortcutKey = "r";
        modelRef.componentInstance.CancelButton = true;
        modelRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe( async (resp: any) => {
            modelRef.close();
            this.InputNDC = "";
            if(resp && resp !== "cancel") {
               this.addFrmMMS();
            } else {
                this.closeModal('close');
            }
        });
    }
    addFrmMMS() {
            let drugFG = this._drugUtils.createDrugDataModal();
            this._patPreDrugModalServ._patPreDrugModal$.next("drug");
            const modalRef = this._patPreDrugModalServ.modalInstanceRef;
            modalRef.componentInstance.openNPatchFrmMMS = true;
            modalRef.componentInstance.drugId = null;
            modalRef.componentInstance.drugFlow = "Add";
            modalRef.componentInstance.MMSDataDPV = this.MMSData;
            modalRef.componentInstance.addDrugModal = drugFG;
            modalRef.componentInstance.frmAction = true;
            modalRef.componentInstance.CloseDrugModal.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                modalRef.close();
                if (this.modalRef) {
                    this.modalRef.close()
                    this.modalRef = null;
                }
            });
    }
    async continueWithTherapDrug() {
        if (this.modalTherap) {
            this.modalTherap.close();
            this.modalTherap = null;
        }
        const drugInfo: any = await this._commonServ.getRxDrugDetailsById(this.therapeqvResponse.drugId).toPromise();
        this.drugInformation = drugInfo;
        if (!this.checkIsPriceExistOrNot(drugInfo, this.rxInfo.PrescReFill.BucketId)) {
            if (this._buckUtils.isAdvancedMode()) {
                this.openAddDrugToBucket(drugInfo, this.rxInfo.PrescReFill.BucketId); // if adv mode and price not exist then open add drug to bucket.
                return;
            } else {
                const DrugPO = this._drugBuckUtil.createDrugPoFG();
                const DrugBucketFG = this._drugBuckUtil.createDrugBucketFG();
                DrugBucketFG.patchValue({
                Id: this.rxInfo.PrescReFill.BucketId,
                IsInvManaged: true,
                });
                const payload = this._drugBuckUtil.patchDrugInvINFO(
                    this.therapeqvResponse.drugId, DrugBucketFG.value, DrugPO.value, null, this.drugInformation
                );
                const resp = await this._buckService
                .managBucketInv(payload)
                .toPromise();
                if (resp) {
                    this.checkManageInv(drugInfo);
                }
            }
        } else {
             //need to check druginfo-manageinv-bucketid-qtyinhand
             this.checkManageInv(drugInfo);
        }
    }

    checkIsPriceExistOrNot(drugInfo, bucketId = null): boolean {
        if (drugInfo && drugInfo.manageinventory && drugInfo.manageinventory.length) {
            const isBuckets = drugInfo.manageinventory.filter(buck => buck.BucketId === bucketId) || [];
            return isBuckets && isBuckets.length ? true : false;
        }
        return false;
    }
    async openAddDrugToBucket(drugInfo, bucketId = null) { // open add drug to bucket popup..
        if (!drugInfo || !drugInfo.id) {
            return;
        }

        let newbucketId = bucketId;
        const DrugBucketFG = this._buckUtils.createDrugBucketFG();
        const modelRef = this.modalService.open(AddDrugToBucketComponent,
            {backdrop: false, size: "lg", windowClass: "modal-md modal-center-to-page", keyboard: false});
        modelRef.componentInstance.IsFrmRx = true;
        modelRef.componentInstance.DrugBuckFG = DrugBucketFG;
        modelRef.componentInstance.DrugId = drugInfo.id;
        modelRef.componentInstance.DrugBucketInfo = DrugBucketFG.value;
        modelRef.componentInstance.BucketId = newbucketId;
        modelRef.componentInstance.emitAddDrugToBuckClose.pipe(takeUntil(this.unsubscribe$)).subscribe(async close => {
            modelRef.close();

                if (close === "NOTADDED") { // reset ndc...
                    this.ResetNDC();
                } else if (close === "ADDED") { // get druginfo
                    this.checkManageInv();
                }
        });
    }
   async checkManageInv(drgInfo?: any) {
        const drugInfo: any = drgInfo ? drgInfo : await this._commonServ.getRxDrugDetailsById(this.therapeqvResponse.drugId).toPromise();
        const QtyInHandList = drugInfo && drugInfo.manageinventory?.length ? drugInfo.manageinventory.find((item: any) => item.BucketId === this.rxInfo.PrescReFill.BucketId) : null;
        this.therapQtyInHand = QtyInHandList ? QtyInHandList.QtyInHand : "";
       this.contWithTherapNDC();
    }
    contWithTherapNDC() {
        let drugLotId: any;
        this._commonServ.getDrugLotDescpInfo(this.therapeqvResponse.drugId, this.rxInfo.PrescReFill.BucketId).pipe(takeUntil(this.unsubscribe$)).subscribe((resp : any) => {
            if (resp) {
                if (resp.DrugLots?.length && this.isBarcodeFetching) {
                    drugLotId = resp.DrugLots.find((item: any) => 
                    ((item.LotNum === this.RxRefillFG.controls.lotNo.value) && (new Date(this.RxRefillFG.controls.expirationDt.value).getTime() === new Date(item.ExpDt).getTime())));
                }
                this.drugLotInfo = resp.DrugLots;
                // this.RxRefillFG.controls["Ndc"].patchValue(null);
                    // &&(moment(moment(item.LotExpiryDtTm).format("YYYY-MM-DD")).isSame(moment(this.RxRefillFG.controls.expirationDt.value).format("YYYY-MM-DD")))));
                if (this.iouRecs && this.iouRecs.length) {
                    const emptyList = this.iouRecs.find((item: any) => !item.DrugNDC);
                    if (emptyList) {
                        this.iouRecs.map((item: any) => {
                            if (!item.DrugNDC ) {
                                item.RowId= item.RowId,
                                item.Id = null,
                                item.DrugPickVerifId = null,
                                item.DrugNDC = this.isBarcodeFetching ? this.RxRefillFG.controls.Ndc.value : resp.DrugInfo.NDC,
                                item.DrugName = resp.DrugInfo.DrugName,
                                item.DrugClass = this.drugInformation?.drugclass,
                                item.manufacturername = resp?.DrugInfo.manufacturername,
                                item.QtyInHand = this.therapQtyInHand,
                                item.DispQty = item.DispQty,
                                item.Source =this.manualEnterDPV ? "User" : "",
                                item.UserId = this.techName ? this.techName : this.pharName,
                                item.LotNum= this.isBarcodeFetching ? this.RxRefillFG.controls.lotNo.value : (resp.DrugLots?.length ? resp.DrugLots[0].LotNum : ""),
                                item.LotExpiryDtTm = this.isBarcodeFetching ? this.RxRefillFG.controls.expirationDt.value : (resp.DrugLots?.length ? resp.DrugLots[0].ExpDt : ""),
                                item.GTIN =this.isBarcodeFetching ? this.RxRefillFG.controls.gtin.value : "",
                                item.SerialNo = this.isBarcodeFetching ? this.RxRefillFG.controls.serialNo.value : "",
                                item.DispDtTm = "",
                                item.drugDescription = resp.DrugDescription,
                                item.DrugImage = resp.ImageName,
                                item.DrugLotId = this.isBarcodeFetching ? (drugLotId ? drugLotId.Id : null) : (resp.DrugLots?.length ? resp.DrugLots[0].Id : null)
                                item.IsRefrigeratorItem = resp.DrugInfo.IsRefrigeratorItem;
                                item.drugLotInfo = this.drugLotInfo;
                            }
                        });
                    } else {
                        const data = {
                            RowId: this.iouRecs && this.iouRecs.length ? this.iouRecs.length : 0,
                            Id : null,
                            DrugPickVerifId: null,
                            DrugNDC : this.isBarcodeFetching ? this.RxRefillFG.controls.Ndc.value : resp.DrugInfo.NDC,
                            DrugName : resp.DrugInfo.DrugName,
                            DrugClass : this.drugInformation?.drugclass,
                            manufacturername : resp.DrugInfo.manufacturername,
                            QtyInHand : this.therapQtyInHand,
                            DispQty : this.rxInfo?.PrescReFill?.DispQty - this.TotalDispVal,
                            Source :this.manualEnterDPV ? "User" : "",
                            UserId : this.techName ? this.techName : this.pharName,
                            LotNum: this.isBarcodeFetching ? this.RxRefillFG.controls.lotNo.value : (resp.DrugLots?.length ? resp.DrugLots[0].LotNum : ""),
                            LotExpiryDtTm : this.isBarcodeFetching ? this.RxRefillFG.controls.expirationDt.value : (resp.DrugLots?.length ? resp.DrugLots[0].ExpDt : ""),
                            GTIN :this.isBarcodeFetching ? this.RxRefillFG.controls.gtin.value : "",
                            SerialNo : this.isBarcodeFetching ? this.RxRefillFG.controls.serialNo.value : "",
                            DispDtTm : null,
                            drugDescription : resp.DrugDescription,
                            DrugImage : resp.ImageName,
                            DrugLotId: this.isBarcodeFetching ? (drugLotId ? drugLotId.Id : null) : (resp.DrugLots?.length ? resp.DrugLots[0].Id : null),
                            IsRefrigeratorItem : resp.DrugInfo.IsRefrigeratorItem,
                            drugLotInfo: this.drugLotInfo
                        }
                        this.TotalDispVal = (+this.TotalDispVal) + (+data.DispQty);
                        this.iouRecs.push(data);
                    }
                } else {
                    const data = {
                            RowId: 0,
                            Id : null,
                            DrugPickVerifId: null,
                            DrugNDC : this.isBarcodeFetching ? this.RxRefillFG.controls.Ndc.value : resp.DrugInfo.NDC,
                            DrugName : resp.DrugInfo.DrugName,
                            DrugClass : this.drugInformation?.drugclass,
                            manufacturername : resp.DrugInfo.manufacturername,
                            QtyInHand : this.therapQtyInHand,
                            DispQty : this.rxInfo?.PrescReFill?.DispQty - this.TotalDispVal,
                            Source :this.manualEnterDPV ? "User" : "",
                            UserId : this.techName ? this.techName : this.pharName,
                            LotNum: this.isBarcodeFetching ? this.RxRefillFG.controls.lotNo.value : (resp.DrugLots?.length ? resp.DrugLots[0].LotNum : ""),
                            LotExpiryDtTm : this.isBarcodeFetching ? this.RxRefillFG.controls.expirationDt.value : (resp.DrugLots?.length ? resp.DrugLots[0].ExpDt : ""),
                            GTIN :this.isBarcodeFetching ? this.RxRefillFG.controls.gtin.value : "",
                            SerialNo : this.isBarcodeFetching ? this.RxRefillFG.controls.serialNo.value : "",
                            DispDtTm : null,
                            drugDescription : resp.DrugDescription,
                            DrugImage : resp.ImageName,
                            DrugLotId: this.isBarcodeFetching ? (drugLotId ? drugLotId.Id : null) : (resp.DrugLots?.length ? resp.DrugLots[0].Id : null),
                            IsRefrigeratorItem : resp.DrugInfo.IsRefrigeratorItem,
                            drugLotInfo: this.drugLotInfo
                    }
                    this.TotalDispVal = (+this.TotalDispVal) + (+data.DispQty);
                    this.iouRecs.push(data);
                }
            }
            this.generateIOU(this.iouRecs);
        });
    }
    ResetNDC() {
        if (this.modalTherap) {
            this.modalTherap.close();
            this.modalTherap = null;
        }
        this.InputNDC = "";
        this.RxRefillFG.controls["Ndc"].patchValue(null);
    }
    init(flex: any) {
        // this.filedQueueFlex = flex;
        // flex.collapseGroupsToLevel(0);
    }

    searchForBarcode(barcodeVal) {
        if (barcodeVal) {
            this.manualEnterDPV = true;
            this.isBarcodeFetching = true;
            this._commonServ.getDrugByBarcode(barcodeVal).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                if (resp && resp.length) {
                    this.DrugSlctd =resp[0];
                    this.InputNDC = resp[0].ndc;
                    this.NDCValForManual = resp[0].ndc;
                    this.RxRefillFG.controls.Ndc.patchValue(resp[0].ndc);
                    this.RxRefillFG.controls.expirationDt.patchValue(resp[0].ExpirationDate );
                    this.RxRefillFG.controls.lotNo.patchValue(resp[0].LotNum);
                    this.RxRefillFG.controls.serialNo.patchValue(resp[0].SerialNumber);
                    this.RxRefillFG.controls.gtin.patchValue(resp[0].GTIN);
                    this.InputNDC = "";
                    this.showNDC();
                } else {
                    this.isBarcodeFetching = false;
                    this.RxRefillFG.controls.Ndc.patchValue(null);
                    this.InputNDC = "";
                    this._alertService.error("Drug not found with scanned barcode");
                }
            });
        }
    }

    getRxDetails() {
        this._drgVerifyServ
            .getRxDetails(this.RxRefillFG.value.RxNum, 0, 0).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.RxRefillFG.controls["RefillNum"].patchValue(0);
                    if (resp.drugPickVerificationStatus === "p") {
                        this.modalRef = this.modalService.open(
                            this.alreadyVerified,
                            {
                                centered: true,
                                keyboard: false,
                                backdrop: false,
                                windowClass: "large--content"
                            }
                        );
                    } else if (resp.Prescription.Id === -1) {
                        this.warningInfo = "Rx not found.";
                        this.modalRef = this.modalService.open(this.invalidRx, {
                            centered: true,
                            keyboard: false,
                            backdrop: false,
                            windowClass: "large--content"
                        });
                        this.RxRefillFG.controls["RxNum"].patchValue(null);
                    } else if (resp.RxBill.StatusId === 2) {
                        this.warningInfo = "Rx already billed.";
                        this.modalRef = this.modalService.open(this.invalidRx, {
                            centered: true,
                            keyboard: false,
                            backdrop: false,
                            windowClass: "large--content"
                        });
                    } else {
                        this.rxInfo = resp;
                        this.getDrugImage();
                    }
                }
            });
    }

    getRxRefDetails() {
        this._drgVerifyServ
            .getRxDetails(
                this.RxRefillFG.value.RxNum,
                this.RxRefillFG.value.RefillNum,
                this.RxRefillFG.value.PartialfillNum
            ).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    if (resp.drugPickVerificationStatus === "p") {
                        this.modalRef = this.modalService.open(
                            this.alreadyVerified,
                            {
                                centered: true,
                                keyboard: false, backdrop: false,
                                windowClass: "large--content"
                            }
                        );
                    } else if (resp.PrescReFill.Id === -1) {
                        this.warningInfo = "Refill not found.";
                        this.modalRef = this.modalService.open(this.invalidRx, {
                            centered: true,
                            keyboard: false, backdrop: false,
                            windowClass: "large--content"
                        });
                        this.RxRefillFG.controls["RefillNum"].patchValue(null);
                    } else if (resp.RxBill.StatusId === 2) {
                        this.warningInfo = "Rx already billed.";
                        this.modalRef = this.modalService.open(this.invalidRx, {
                            centered: true,
                            keyboard: false, backdrop: false,
                            windowClass: "large--content"
                        });
                    } else {
                        this.rxInfo = resp;
                        this.getDrugImage();
                    }
                }
            });
    }

    closeModal(val: any, status?,isVerify?) {
        if (val === "continue") {
            this.TriggerChangeTab.emit(true);
            this.verifyInfo("failed");
        } else if (val === "rescan") {
            this.RxRefillFG.controls["Ndc"].reset();
            if (document.getElementById("ndcScan")) {
                document.getElementById("ndcScan").focus();
            }
            if (document.getElementById("ndcScanInput")) {
                document.getElementById("ndcScanInput").focus();
            }
        } else if (val === "success") {
            this.showRxRefill(true);
            this.EnableRphTabAfterTab.emit(true);
        }  else if (val === "editRx") {
            if (this.checkHasPrivsToAccess(
                this.accessPrivCate.Rx,
                this.rxPrivs.EditRx)) {
                this.routeToRx();
            } else {
                this.openOveride("EditRx");
            }
        } else if (val === "IOU") {
            this.showIOU = true;
        } else if (val === "rph" && (!this.isLoginVerify || isVerify)) {
            if (!this._privMaskGuard.canActivate(AccCategoryE.Rx1, Rx1E.PharmacistVerification)) {
                this._alertService.error(MsgConfig.NO_PRIVS_ACCESS);
            } else {
            if (
                this.rxInfo && this.rxInfo.RxBill &&
                this.rxInfo.RxBill.StatusId === 1 &&
                this._commonServ.getSetttingValue(
                    "PharmacySettings",
                    "WorkFlowSettings"
                ) === "0"
            ) {
                this._alertService.error("Rx is unbilled.");
            } else {
                if (this.isPatientHistory) {
                    this.RphOpened = true;
                    const inputParams = {
                        IsPatientHistory: true,
                        RxID : this.rxInfo.Prescription.PrescNum,
                        RefilID : this.rxInfo.PrescReFill.ReFillNum,
                        FillId : this.rxInfo.PrescReFill.PartialFillNo
                    }
                    this.TriggerChangeTab.emit(inputParams);
                    // this._patPreDrugModalServ._patPreDrugModal$.next("RphVerification");
                    // const modalRef = this._patPreDrugModalServ.modalInstanceRef;
                    // modalRef.componentInstance.IsPatientHistory = true;
                    // modalRef.componentInstance.RxID = this.rxInfo.Prescription.PrescNum ;
                    // modalRef.componentInstance.RefilID = this.rxInfo.PrescReFill.ReFillNum ;
                    // modalRef.componentInstance.FillId = this.rxInfo.PrescReFill.PartialFillNo;
                    // modalRef.componentInstance.CloseRphModal.subscribe(x => {
                    //     if (x) {
                    //         modalRef.close();
                    //         if (this.modalRef) {
                    //             this.modalRef.close();
                    //             this.modalRef = null;
                    //         }
                    //         this.TriggerChangeTab.emit(true);
                    //     }
                    // });
                } else {
                    this.RphOpened = true;
                    const inputParams = {
                        IsPatientHistory: true,
                        RxID : this.rxInfo.Prescription.PrescNum,
                        RefilID : this.rxInfo.PrescReFill.ReFillNum,
                        FillId : this.rxInfo.PrescReFill.PartialFillNo
                    }
                    this.TriggerChangeTab.emit(inputParams);
                    // this._router.navigate([
                    //     "eprx/RphVerification/" +
                    //     this.rxInfo.Prescription.PrescNum +
                    //     "/" +
                    //     this.rxInfo.PrescReFill.ReFillNum +
                    //     "/" +
                    //     this.rxInfo.PrescReFill.PartialFillNo +
                    //     "/verifyRx"
                    // ]);
                }
                sessionStorage.setItem("RphFmDPV", "" + true);
            }
        }
        }
        this.hideNDCForm = false;
        this.oldIouRecs = null
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        if (status === "pass") {
            this._auditUtils.getChangedValues(
                null,
                { "DP Verified Status": "Pass" },
                "Drug Pick Verification",
                "Rx",
                this.rxInfo.PrescReFill.Id
            );
        } else if (status === "fail") {
            this._auditUtils.getChangedValues(
                null,
                { "DP Verified Status": "Fail" },
                "Drug Pick Verification",
                "Rx",
                this.rxInfo.PrescReFill.Id
            );
        }
    }
    async routeToRx(rxNo?: any, rxId?: any, val?: string) {
        const selectedrxNo = rxNo ? rxNo : +this.rxInfo.Prescription.PrescNum;
        const selectedrxId = rxId ? rxId : this.rxInfo.PrescReFill.Id;
        let rxInfo: any = await this._nrxUtils.getandStoreRxInfo(selectedrxNo, selectedrxId, false, null, null, null, true);
        if (rxInfo) {
            rxInfo = this._nrxUtils.editRxPatchValues(rxInfo);
            this._nrxUtils.createPatchStoreRxFG(rxInfo);
            this._nrxUtils.getCommonEndPoints(rxInfo);
            const modalRef = this.modalService.open(
                EdRxComponent,
                {
                    backdrop: false,
                    size: "lg",
                    windowClass: "md-xx-lg comp--modal",
                    keyboard: false
                }
            );
            modalRef.componentInstance.SelectedValue = ((val && val === "false")) ? val : "true";
            modalRef.componentInstance.CloseEditMode.pipe(takeUntil(this.unsubscribe$))
                .subscribe(x => {
                    modalRef.close();
                    if (x) {
                        this.showRxRefill();
                    }
                });
            modalRef.componentInstance.CloseAndReopen.pipe(takeUntil(this.unsubscribe$))
                .subscribe(d => {
                    modalRef.close();
                    if (d) {
                        this.routeToRx(d["rxNo"], d["rfId"], d["isNPPatientValue"]);
                    } else {
                        this.showRxRefill();
                    }
                });
        }
    }

    // routeToRx() {
    //     const cloneRx = new CloneRx();
    //     this._rxUtils.getRxDetailsByPrescNumber(
    //         this.rxInfo.Prescription.PrescNum,
    //         "er",
    //         this.rxInfo.PrescReFill.Id
    //     );
    //     cloneRx.RxId = this.rxInfo.Prescription.PrescNum;
    // }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val && !this.allowDPV) {
            this.routeToRx();
        }
        if (val && this.allowDPV) {
            this.showNDC();
            this.allowDPV = false;
        }
    }

    openOveride(content) {
        this.privType = content;
        this.categoryId = "Rx";
        this.openOverride = true;
    }

    openOverideDPV(content) {
        this.privType = content;
        this.categoryId = "DrugPickVerification";
        this.openOverride = true;
    }

    redirectToDPV() {
            this.TriggerChangeTab.emit(true);
    }

    verifyInfo(val?: any) {
        let resultArr = [];
        /*this.drugVerifyInfo.BottleNdc = this.rxInfo.Drug.ndc;
        this.drugVerifyInfo.PrescNum = this.rxInfo.Prescription.PrescNum;
        this.drugVerifyInfo.RefillNum = this.rxInfo.PrescReFill.ReFillNum;
        this.drugVerifyInfo.PartialfillNum = this.rxInfo.PrescReFill.PartialFillNo;
        this.drugVerifyInfo.PrescId = this.rxInfo.Prescription.Id;
        this.drugVerifyInfo.PrescRefillId = this.rxInfo.PrescReFill.Id;
        const technician = this._userService.getToken("TechUserName");
        if (!technician) {
            this.drugVerifyInfo.UserName = this._userService.getToken("PhUserName");
        } else {
            this.drugVerifyInfo.UserName = this._userService.getToken("TechUserName");
        }
        this.drugVerifyInfo.VerifiedById = _.toNumber(this._userService.getToken("UserId"));*/
            const currentUserId=_.toNumber(this._userService.getToken("UserId"));
            this.iouRecs.map((item: any) => {
                if (item.DrugNDC) {
                    const payLoad = {
                        PrescId: this.rxInfo.Prescription.Id,
                        PrescNum: this.rxInfo.Prescription.PrescNum,
                        PrescRefillId: this.rxInfo.PrescReFill.Id,
                        RefillNum: this.rxInfo.PrescReFill.ReFillNum,
                        PartialfillNum: this.rxInfo.PrescReFill.PartialFillNo,
                        BottleNdc: item.DrugNDC,
                        Result: "p",
                        VerifiedById: currentUserId,
                        UserName: this.techName ? this.techName : this.pharName,
                        DrugLotId: item.DrugLotId,
                        Gtin: item.GTIN ? item.GTIN : "",
                        SerialNo: item.SerialNo ? item.SerialNo : "",
                        VerifiedQty: item.DispQty, 
                        LotNum: item.LotNum,
                        LotExpiryDtTm: item.LotExpiryDtTm,
                        IouId: item.Id,
                        Id: item.DrugPickVerifId,
                        DSCSASerializationDtTm: this.SerilizedDatetime,
                        DSCSAId: item.DSCSAId,
                        drugLotInfo: []
                    };
                    resultArr.push(payLoad);
                }
            });
            if (!this.oldIouRecs) 
                this.oldIouRecs = JSON.parse(JSON.stringify(this.iouRecs));
            this._drgVerifyServ
                .sendDrugVerifyDetails(resultArr).pipe(takeUntil(this.unsubscribe$))
                .subscribe(async resp => {
                    if (resp && (val !== "failed")) {
                        if (!this._privMaskGuard.canActivate(AccCategoryE["Rx1"], Rx1E["PharmacistVerification"])) {
                            this.showRxRefill(true);
                            this.TriggerChangeTab.emit(true);
                            this._alertService.success("Drug Pick Verification completed successfully.");
                            sessionStorage.removeItem('DSCSAId');
                        } else {
                            const dataToPost = {
                                PageNumber: 1,
                                PageSize: 1,
                                RxId: this.rxInfo.Prescription.PrescNum,
                                RefillNum: this.rxInfo.PrescReFill.ReFillNum,
                                PartialfillNum: this.rxInfo.PrescReFill.PartialFillNo,
                                IsFromDrugPickVerifScreen: true
                            };
                            const response  = await this._rphService.getRphVerifyDetails(dataToPost).toPromise();
                            this.rxInfo = response && response.data.length ? response.data[0] : this.rxInfo;
                            this.iouRecs = [... this.rxInfo.Iou];
                            this.modalRef = this.modalService.open(this.verifiedRx, {
                                centered: true, keyboard: false, backdrop: false, windowClass: "large--content"
                            });
                        }
                        
                       this.auditLogData();
                    }
                });
    }

    rxImage(data: any) {
        this._drgVerifyServ.getRxImage(data).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            this.detailsInfo = resp;
        });
    }
     auditLogData() {
        this._auditUtils.getChangedValues(
            null,
            { "DP Verified": "Verified" },
            "Drug Pick Verification",
            "Rx",
            this.rxInfo.PrescReFill.Id
        );
     }
     openNotesModel(notesType) {
        this.notesType = notesType;
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
             if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            }
        }
    }

    toggleColorLegend() {
        this._alertService.showHelpText({ RxStatus: false, DrugName: true, Status: false, DrugForDrugFile: false });
      }
    
      getSerilizedDatetime(event) {
        this.SerilizedDatetime = event ? event : null;
      }
      ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        sessionStorage.removeItem('DSCSAId');
      }

}

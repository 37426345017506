import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { constant } from "../app.constants";
import { Observable } from "rxjs";
import { DrugInfo, EquivalentDrug, DrugInteraction, ErxVersions, EDrug, DrugInteractionData } from "../models";

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable()
export class DrugService {
    constructor(private _http: HttpClient) {}

    getDrugCounsellingInfo(drugId: number, isLong: boolean, lang?: string) {
        return this._http.get(
            constant.GET_Drug_Counselling.replace("{drugId}", "" + drugId)
                .replace("{islong}", "" + isLong)
                .replace("{language}", "" + lang)
        );
    }
    getDrugCounsellingReport(DrugCounPars: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_CounsellingReport, DrugCounPars, {
            headers: headers,
            responseType: "blob"
        });
    }
    getDrugCounsellingData(data) {
        return this._http.post(constant.POST_GET_Drug_Counselling, data);
    }

    getDrugPregnancyInfo(drugID: number, rxEntryType: string) {
        return this._http.get(
            constant.GET_Drug_PregnancyInfo.replace(
                "{drugId}",
                "" + drugID
            ).replace("{calledFrom}", "" + rxEntryType)
        );
    }
    getMacWacData(DrugCode: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_MAC_WAC_DrugPricing.replace("{DrugCode}", "" + DrugCode)
        );
    }
    getMacWacUpdateMmsData(DrugCode: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_MAC_WAC_UpdateFromMms.replace("{DrugCode}", "" + DrugCode)
        );
    }
    macWacNewPriceValues(data) {
        return this._http.post(constant.POST_MAC_WAC_NewPriceValues, data);
    }

    getDosageInfo(therapeuticCode: number): Observable<DrugInfo[]> {
        return this._http.get<DrugInfo[]>(
            constant.GET_Drug_DosageInfo.replace("{therapeuticCode}", "" + therapeuticCode)
        );
    }

    getEquivalentDrug(drugId: number, ndc?: any, isForErx?: any, Status?: any,
        showNonSplittableEquivalentDrugs?: boolean, drugSugest?: any, doNotconsiderPreferredDrugsetting?: boolean, therapeutic?: any, therapeuticCode?: any): Observable<EDrug[]> {
        const payload = {
            drugId: drugId,
            ndc:   ndc,
            isForErx:  isForErx,
            filterStatus:  Status ? Status : 1,
            showNonSplittableEquivalentDrugs: showNonSplittableEquivalentDrugs ? showNonSplittableEquivalentDrugs : (drugSugest ? drugSugest.ShowNonSplittableEquiDrugs : false),
            OnlyDrugsHavingQtyInStock: drugSugest ? drugSugest.OnlyDrugsHavingQtyInStock : false,
            OnlyShowDrugsAvailableForBucket: drugSugest ? drugSugest.OnlyShowDrugsAvailableForBucket : false,
            Is340B: drugSugest ? drugSugest.Is340B : false,
            BucketId: drugSugest ? drugSugest.BucketId : 0,
            doNotconsiderPreferredDrugsetting: doNotconsiderPreferredDrugsetting,
            Therapeutic: therapeutic,
            TherapeuticCode: therapeuticCode,
        };
        return this._http.post<EDrug[]>(constant.POST_Drug_Equivalent, payload);
    }

    getGenericDrug(drugData: any): Observable<EquivalentDrug[]> {
        return this._http.post<EquivalentDrug[]>(
            constant.POST_Drug_Generics, drugData
        );
    }

    getIs340B(
        patientId: number,
        prescriberId: number,
        drugId: number,
        insurancePlanId: number
    ): Observable<boolean> {
        return this._http.get<boolean>(
            constant.GET_Drug_Check340B.replace("{patientId}", "" + patientId)
                .replace("{prescriberId}", "" + prescriberId)
                .replace("{drugId}", "" + drugId)
                .replace("{insurancePlanId}", "" + insurancePlanId)
        );
    }

    getDrugInteractions(
        drugData:DrugInteractionData
     ): Observable<DrugInteraction> {
         return this._http.post<DrugInteraction>(
             constant.GET_Drug_Interactions, drugData
         );
     }

    getDrugRefIngredients(drugId: number, PrescId: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_Drug_Ref_Ingredients.replace("{drugId}", "" + drugId).replace("{prescReFillId}", "" + PrescId)
        );
    }

    getDruglotsBydrugId(drugId: number, term: string): Observable<any> {
        return this._http.get<any>(
            constant.GET_Drug_DrugLotSuggestById.replace(
                "{Drugid}",
                "" + drugId
            ).replace("{term}", "" + term)
        );
    }
    UpdateIngredientDrug(IngData: any): Observable<any> {
        return this._http.put<any>(constant.PUT_Drug_DrugIngredients, IngData);
    }
    AddIngredientDrug(IngData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Drug_DrugIngredients,
            IngData
        );
    }
    deleteIngredientDrug(IngId: number): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_Drug_DrugIngredients.replace("{IngId}", "" + IngId)
        );
    }

    getDrugMedguide(drugId: number): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.get(
            constant.GET_Drug_MedGuide.replace("{drugId}", "" + drugId),
            { headers: headers, responseType: "blob" }
        );
    }

    getDrugSearch(data: any) {
        return this._http.post(constant.GET_Drug_Data, data, reqOptns);
    }

    deleteDrug(Id: any) {
        return this._http.delete(constant.DELETE_Drug.replace("{drugId}", "" + Id));
    }

    getDrugIngredientsById(drugId, Version): Observable<any> {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion + "/" + constant.GET_compound_drugById.replace("{drugId}", "" + drugId);
        } else {
            url = constant.GET_compound_drugById.replace("{drugId}", "" + drugId);
        }
        return this._http.get(url);
    }

    checkDrugIngredientsRestriction(drugId: number, drugsList: string) {
        return this._http.get(
            constant.GET_COMPOUND_INGREDIENTS_CHECK.replace(
                "{drugId}",
                "" + drugId
            ).replace("{drugsList}", "" + drugsList)
        );
    }

    getEDI832PriceUpdateHist(data: any): Observable<any> {
        return this._http.post(constant.POST_PrimeEDIPriceUpdateHistory, data);
    }

    getDrugDeleteResponse(drugId: any) {
        return this._http.get(constant.GET_DrugDeleteResponse.replace("{drugId}", "" + drugId));
    }

    getUpdatePrefDrug(data: any): Observable<any> {
        return this._http.post(constant.POST_Drug_PrefDrug, data);
    }

    getDrugEDI810SuccessRecords(data: any): Observable<any> {
        return this._http.post(constant.POST_Drug_EDI810_SuccessRecords, data);
    }

    getDrugEDI810UnSuccessRecords(data: any): Observable<any> {
        return this._http.post(constant.POST_Drug_EDI810_UnSuccessRecords, data);
    }

    deleteDrugEDI810UnSuccessRecords(UnSuccessIdList: any): Observable<any> {
        return this._http.post(constant.DELETE_Drug_EDI810_UnSuccessRecords, UnSuccessIdList);
    }

    processDrugEDI810UnSuccessRecords(payLoad: any): Observable<any> {
        return this._http.post(constant.POST_Drug_EDI810Process, payLoad);
    }
    //drugCategories API's
    getdrugcategoriesList(data): Observable<any> {
        return this._http.post(constant.GET_DrugCategoriess, data, reqOptns);
    }
    deleteDrugcatgory(data: any): Observable<any> {
        return this._http.delete(constant.DELETE_DrugCategories.replace("{catId}", "" + data['categoryID']).replace("{isCheck}", "" + data['isCheck']));
    }
    addoRUpdateDrugCategory(data: any, type: string): Observable<any> {
        if(type && type === 'SAVE')
              return this._http.post<any>(constant.POST_AddDrugCategories, data);
        else 
            return this._http.put<any>(constant.POST_UpdateDrugCategories, data);
    }
     //Note Categories API's
    getNoteSubCategoriesList(data): Observable<any> {
        return this._http.post(constant.GET_NoteSubCategories,data);
    }
    getNoteCategoriesList(): Observable<any> {
        return this._http.get(constant.GET_NoteCategoriess);
    }

    addoRUpdateNoteCategory(data: any, type: string): Observable<any> {
        if(type && type === 'SAVE')
              return this._http.put<any>(constant.POST_AddNoteCategories, data);
        else 
            return this._http.put<any>(constant.PUT_UpdateNoteCategories, data);
    }
    deleteNoteSubCategory(catId: number, isType:boolean): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_NoteCategories.replace("{noteSubCategoryId}", "" + catId).replace("{isType}", "" + isType)
        );
    }
    getNoteSubCategory(catId: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_NoteSubCategoriesById.replace("{noteSubCategoryId}", "" + catId)
        );
    }

    getTransactionList(payLoad) {
        return this._http.post(constant.POST_Transaction_List,payLoad);
    }

    getDrugWithNDC(data) {
        return this._http.post(constant.POST_NDC_Search, data);
    }
}

import { TransferPharmacyComponent } from "./transfer-pharmacy/transfer-pharmacy.component";
import { PrivMaskGuard } from "./../../guards/access-privs.guard";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    ResultCardComponent,
    PrescriberResultCardComponent,
    DrugResultCardComponent,
    SearchboxGlobalComponent,
    CustomModalComponent,
    NotesLogComponent,
    DocumentsComponent,
    UploadComponent,
    RxHistoryComponent,
    RxPatientCardComponent,
    RxPrescriberCardComponent,
    RxDrugCardComponent,
    DrugCounsellingComponent,
    DrugIndicationComponent,
    DrugDosageInfoComponent,
    ClinicAddressComponent,
    SearchStateComponent,
    SearchCityComponent,
    SearchZipComponent,
    LowInvBuckTransComponent,
    RxTransferInComponent,
    EligibilityComponent,
    LoginVerifyComponent,
    EquivalentDrugsComponent,
    NcpdpPayersetComponent,
    IouComponent,
    RxDeliveryInfoComponent,
    SomthngWrongComponent,
    OverrideLogComponent,
    ElectronicRxDocComponent,
    RefillQueueAddComponent,
    ClinicalInfoSharedComponent,
    CancelPopUpComponent,
    RxClinicalInfoComponent,
    RxFollowTagComponent,
    DurComponent,
    OtherAmountComponent
} from ".";
import { AppTranslateChildModule } from "../../app.translate.child.module";
import { CustFormElemnsModule } from "../cust-form-elemns/cust-form-elemns.module";
import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { ColumnPicker } from "../../directives";
// import { FilterPipeModule } from "ngx-filter-pipe";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { WjInputModule } from "@grapecity/wijmo.angular2.input";
import { WjCoreModule } from "@grapecity/wijmo.angular2.core";
import { WjChartModule } from "@grapecity/wijmo.angular2.chart";
// import { NgxfUploaderModule } from "ngxf-uploader";
import { AngularEditorModule } from '@kolkov/angular-editor';
import {
    PatientService,
    DrugPriceCheckService,
    BucketService,
    QuickRefillService,
    ControlFileService,
    PatientHistoryPrintService,
    DrugService
} from "src/app/services";
import { RefillRxInfoComponent } from "./refill-rx-info/refill-rx-info.component";

import { EprxPaginationComponent } from "./eprx-pagination/eprx-pagination.component";
import { AdminRxComponent } from "./admin-rx/admin-rx.component";
import { RefillProgressStatsComponent } from "./refill-progress-stats/refill-progress-stats.component";
import { QuickVerfComponent } from "./quick-verf/quick-verf.component";
import { CompIngredListComponent } from "./comp-ingred-list/comp-ingred-list.component";
import { EasyRefillWizardComponent } from "./easy-refill-wizard/easy-refill-wizard.component";
import { QuickRefillComponent } from "./quick-refill/quick-refill.component";
import { DeleteRxComponent } from "./delete-rx/delete-rx.component";
import { PrintRxLabelComponent } from "./print-rx-label/print-rx-label.component";
import { ActionsComponent } from "./actions/actions.component";
import { SendEfillRequestComponent } from "./actions/send-efill-request/send-efill-request.component";
import { PrintPatientRecordComponent } from "./actions/print-patient-record/print-patient-record.component";
import { PrintErxReqComponent } from "./actions/print-erx-req/print-erx-req.component";
import { CheckDurComponent } from "./actions/check-dur/check-dur.component";
import { NgxMaskModule } from "ngx-mask";
import { PrintErxService } from "src/app/services/print-erx.service";
import { CopyncdpComponent } from "./copyncdp/copyncdp.component";
import { SubPriceListComponent } from "./sub-price-list/sub-price-list.component";
import { InsucopyComponent } from "./insucopy/insucopy.component";
import { ErxChangeReqComponent } from "./erx-change-req/erx-change-req.component";
import { DiagListComponent } from "./diag-list/diag-list.component";
import { LabelDesignService } from "src/app/services/label-design.service";
import { SubDurComponent } from "./sub-dur/sub-dur.component";
import { ColorPickerModule } from "ngx-color-picker";
import { DefaultLabelPrintsetComponent } from "./default-label-printset/default-label-printset.component";
import { BillCashPopUpComponent } from "./bill-cash-pop-up/bill-cash-pop-up.component";
import { RxFileComponent } from "./rx-file/rx-file.component";
import { NextBillToInsuranceComponent } from "./next-bill-to-insurance/next-bill-to-insurance.component";
import { ViewTransMsgComponent } from "./view-trans-msg/view-trans-msg.component";
import { DeferredReasonListComponent } from "./deferred-reason-list/deferred-reason-list.component";
import { CntrlFileResubComponent } from "./cntrl-file-resub/cntrl-file-resub.component";
import { BillToCashComponent } from "./bill-to-cash/bill-to-cash.component";
import { UCCalculationComponent } from "./uc-calculation/uc-calculation.component";
import { ScannerUtilComponent } from "./scanner-util/scanner-util.component";
import { PriorApprvlComponent } from "./prior-apprvl/prior-apprvl.component";
import { FamilyInfoComponent } from "./family-info/family-info.component";
import { ImageEditorComponent } from "./image-editor/image-editor.component";
import { AngularCropperjsModule } from "angular-cropperjs";
import { ImageEditorCropComponent } from "./image-editor-crop/image-editor-crop.component";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { RefillRequestComponent } from "./refill-request/refill-request.component";
import { RefillFlowComponent } from "./refill-flow/refill-flow.component";
import { DocumentRefComponent } from "./document-ref/document-ref.component";
import { FamilyInfoRefComponent } from "./family-info-ref/family-info-ref.component";
import { TransDurComponent } from "./trans-dur/trans-dur.component";
import { PrintFaxRequestComponent } from "./actions/print-fax-request/print-fax-request.component";
import { LowInventoryComponent } from "./low-inventory/low-inventory.component";
import { PdfViewerComponent } from "./pdf-viewer/pdf-viewer.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { DocSplitComponent } from "./doc-split/doc-split.component";
import { RphMarkVerfiedComponent } from "./rph-mark-verfied/rph-mark-verfied.component";
import { AutofocusDirective } from "src/app/utils";
import { SearchGlobalRefComponent } from "./search-global-ref/search-global-ref.component";
import { PrinterConfigComponent } from "./printer-config/printer-config.component";
import { ScanDocumentFillComponent } from "./scan-document-fill/scan-document-fill.component";
import { PriorApprovalResultComponent } from "./prior-approval-result/prior-approval-result.component";
import { ImageEditorRefComponent } from "./image-editor-ref/image-editor-ref.component";
import { ErxResultComponent } from "./erx-result/erx-result.component";
import { PrintRxFormComponent } from "./print-rx-form/print-rx-form.component";
import { BatchEditComponent } from "./actions/batch-edit/batch-edit.component";
import { AddDrugToBucketComponent } from "./add-drug-to-bucket/add-drug-to-bucket.component";
import { WjGridDetailModule } from "@grapecity/wijmo.angular2.grid.detail";
import { FileDueDateComponent } from "./file-due-date/file-due-date.component";
import { RefillFiltersComponent } from "./refill-filters/refill-filters.component";
import { DocumentDisplayComponent } from "./document-display/document-display.component";
import { InsCodesComponent } from "./ins-codes/ins-codes.component";
import { DiagnisCodeComponent } from "./diagnis-code/diagnis-code.component";
import { ViewDataSetComponent } from "./view-data-set/view-data-set.component";
import { BucketListDisplayComponent } from "./bucket-list-display/bucket-list-display.component";
import { RxSigQtyDawComponent } from './rx-sig-qty-daw/rx-sig-qty-daw.component';
import { InsuranceListComponent } from './insurance-list/insurance-list.component';
import { DrugMissingFormComponent } from './drug-missing-form/drug-missing-form.component';
import { RxBasicDisplayComponent } from "./rx-basic-display/rx-basic-display.component";
import { RxTotalQtyComponent } from './rx-total-qty/rx-total-qty.component';
import { CommonDrugPickVerifyComponent } from "./common-drug-pick-verify/common-drug-pick-verify.component";
import { CommonWarnorconfirmPopupComponent } from './common-warnorconfirm-popup/common-warnorconfirm-popup.component';
import {CommonRphVerifyComponent} from "./common-rph-verify/common-rph-verify.component";
import { CommonWarningsComponent } from './common-warnings/common-warnings.component';
import { AutomaticSubmissionCntrlFileComponent } from "../schedule-task/automatic-submission-cntrl-file/automatic-submission-cntrl-file.component";
import { RxSerialReqPopDisplayComponent } from './rx-serial-req-pop-display/rx-serial-req-pop-display.component';
import { SendSmsComponent } from './send-sms/send-sms.component';
import { MessageLogComponent } from "../message-log/message-log.component";
import { MessageQueueComponent } from "../message-queue/message-queue/message-queue.component";
import { SendRefillDueComponent } from './send-refill-due/send-refill-due.component';
import { CommonPriceValsChangePopupComponent } from './common-price-vals-change-popup/common-price-vals-change-popup.component';
import { AdditionalInsurancesCommonComponent } from './additional-insurances-common/additional-insurances-common.component';
import { DMEPopupComponent } from './dme-popup/dme-popup.component';
import { CommonDrugpickVerfHistComponent } from './common-drugpick-verf-hist/common-drugpick-verf-hist.component';
import { InventoryDetailsComponent } from './inventory-details/inventory-details.component';
import { AddDurComponent } from './Add-Dur/add-dur/add-dur.component';
import { NotesHistoryComponent } from './notes-history/notes-history.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { DrugCategoriesComponent } from './drug-categories/drug-categories.component';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { CommonNotesLogComponent } from './common-notes-log/common-notes-log.component';
import { HoaComponent } from './hoa/hoa.component';
import { IouCaptureComponent } from './iou-capture/iou-capture/iou-capture.component';
import { UserGuideIconComponent } from "./user-guide-icon/user-guide-icon.component";
import { VaccineImmunizationComponent } from './vaccine-immunization/vaccine-immunization.component';
import { CustomReportPreviewComponent } from './custom-report-preview/custom-report-preview.component';
import { CommonDscsaInfoComponent } from './common-dscsa-info/common-dscsa-info.component';
import { ClinicalAssessmentComponent } from './clinical-assessment/clinical-assessment.component';
import { CurrentViewComponent } from './current-view/current-view.component';
import { VerificationChecksComponent } from './verification-checks/verification-checks.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { RxPrivThumbnailsComponent } from "../rx-priv-thumbnails/rx-priv-thumbnails.component";
import { PrevoiusRefillsFillsComponent } from './prevoius-refills-fills/prevoius-refills-fills.component';
import { ERxTRxREQComponent } from './e-rx-trx-req/e-rx-trx-req.component';
import { SingleNotesComponent } from './single-notes/single-notes.component';
import { InfoIconTooltipComponent } from './info-icon-tooltip/info-icon-tooltip.component';
@NgModule({
    imports: [
        CommonModule,
        AppTranslateChildModule,
        CustFormElemnsModule,
        NgbModule,
        RouterModule,
        WjCoreModule,
        WjInputModule,
        WjGridModule,
        WjChartModule,
        NgxMaskModule.forRoot(),
        ColorPickerModule,
        AngularCropperjsModule,
        NgxImageZoomModule,
        PdfViewerModule,
        WjGridDetailModule,
        AngularEditorModule,
        IvyCarouselModule
    ],
    declarations: [
        ResultCardComponent,
        PrescriberResultCardComponent,
        DrugResultCardComponent,
        SearchboxGlobalComponent,
        ColumnPicker,
        CustomModalComponent,
        NotesLogComponent,
        DocumentsComponent,
        UploadComponent,
        RxHistoryComponent,
        RxPatientCardComponent,
        RxPrescriberCardComponent,
        RxDrugCardComponent,
        DrugCounsellingComponent,
        DrugIndicationComponent,
        DrugDosageInfoComponent,
        SearchStateComponent,
        SearchCityComponent,
        SearchZipComponent,
        LowInvBuckTransComponent,
        RxTransferInComponent,
        ClinicAddressComponent,
        EligibilityComponent,
        LoginVerifyComponent,
        EquivalentDrugsComponent,
        RefillRxInfoComponent,
        IouComponent,
        EprxPaginationComponent,
        RxDeliveryInfoComponent,
        AdminRxComponent,
        RefillProgressStatsComponent,
        QuickVerfComponent,
        CompIngredListComponent,
        EasyRefillWizardComponent,
        NcpdpPayersetComponent,
        TransferPharmacyComponent,
        BucketListDisplayComponent,
        QuickRefillComponent,
        DeleteRxComponent,
        PrintRxLabelComponent,
        ActionsComponent,
        SendEfillRequestComponent,
        PrintPatientRecordComponent,
        PrintErxReqComponent,
        CheckDurComponent,
        SomthngWrongComponent,
        CancelPopUpComponent,
        OverrideLogComponent,
        ElectronicRxDocComponent,
        RefillQueueAddComponent,
        ClinicalInfoSharedComponent,
        RxClinicalInfoComponent,
        CopyncdpComponent,
        SubPriceListComponent,
        InsucopyComponent,
        RxFollowTagComponent,
        DurComponent,
        ErxChangeReqComponent,
        DiagListComponent,
        OtherAmountComponent,
        SubDurComponent,
        DefaultLabelPrintsetComponent,
        BillCashPopUpComponent,
        RxFileComponent,
        NextBillToInsuranceComponent,
        ViewTransMsgComponent,
        ScannerUtilComponent,
        DeferredReasonListComponent,
        CntrlFileResubComponent,
        BillToCashComponent,
        UCCalculationComponent,
        PriorApprvlComponent,
        UCCalculationComponent,
        FamilyInfoComponent,
        ImageEditorComponent,
        ImageEditorCropComponent,
        RefillRequestComponent,
        RefillFlowComponent,
        DocumentRefComponent,
        FamilyInfoRefComponent,
        TransDurComponent,
        PrintFaxRequestComponent,
        LowInventoryComponent,
        PdfViewerComponent,
        DocSplitComponent,
        RphMarkVerfiedComponent,
        SearchGlobalRefComponent,
        PrinterConfigComponent,
        ScanDocumentFillComponent,
        PriorApprovalResultComponent,
        ImageEditorRefComponent,
        ErxResultComponent,
        PrintRxFormComponent,
        BatchEditComponent,
        AddDrugToBucketComponent,
        FileDueDateComponent,
        RefillFiltersComponent,
        InsCodesComponent,
        DocumentDisplayComponent,
        InsCodesComponent,
        RxSigQtyDawComponent,
        DiagnisCodeComponent,
        InsuranceListComponent,
        DrugMissingFormComponent,
        ViewDataSetComponent,
        RxBasicDisplayComponent,
        RxTotalQtyComponent,
        CommonDrugPickVerifyComponent,
        CommonWarnorconfirmPopupComponent,
        CommonRphVerifyComponent,
        CommonWarningsComponent,
        AutomaticSubmissionCntrlFileComponent,
        RxSerialReqPopDisplayComponent,
        SendSmsComponent,
        SendRefillDueComponent,
        CommonPriceValsChangePopupComponent,
        AdditionalInsurancesCommonComponent,
        DMEPopupComponent,
        CommonDrugpickVerfHistComponent,
        InventoryDetailsComponent,
        AddDurComponent,
        NotesHistoryComponent,
        ReleaseNotesComponent,
        DrugCategoriesComponent,
        NoDataFoundComponent,
        CommonNotesLogComponent,
        HoaComponent,
        UserGuideIconComponent,
        IouCaptureComponent,
        VaccineImmunizationComponent,
        CustomReportPreviewComponent,
        CommonDscsaInfoComponent,
        ClinicalAssessmentComponent,
        CurrentViewComponent,
        VerificationChecksComponent,
        RxPrivThumbnailsComponent,
        PrevoiusRefillsFillsComponent,
        ERxTRxREQComponent,
        SingleNotesComponent,
        InfoIconTooltipComponent
    ],
    exports: [
        InfoIconTooltipComponent,
        SingleNotesComponent,
        ERxTRxREQComponent,
        PrevoiusRefillsFillsComponent,
        DocumentDisplayComponent,
        PrintFaxRequestComponent,
        InsCodesComponent,
        ResultCardComponent,
        RefillFiltersComponent,
        PrescriberResultCardComponent,
        DrugResultCardComponent,
        SearchboxGlobalComponent,
        CustFormElemnsModule,
        ColumnPicker,
        NgbModule,
        CustomModalComponent,
        NotesLogComponent,
        DocumentsComponent,
        UploadComponent,
        RxHistoryComponent,
        RxPatientCardComponent,
        RxPrescriberCardComponent,
        RxDrugCardComponent,
        DrugCounsellingComponent,
        DrugIndicationComponent,
        DrugDosageInfoComponent,
        SearchStateComponent,
        SearchCityComponent,
        SearchZipComponent,
        LowInvBuckTransComponent,
        RxTransferInComponent,
        ClinicAddressComponent,
        EligibilityComponent,
        LoginVerifyComponent,
        EquivalentDrugsComponent,
        RefillRxInfoComponent,
        IouComponent,
        EprxPaginationComponent,
        RxDeliveryInfoComponent,
        AdminRxComponent,
        RefillProgressStatsComponent,
        QuickVerfComponent,
        CompIngredListComponent,
        EasyRefillWizardComponent,
        NcpdpPayersetComponent,
        TransferPharmacyComponent,
        BucketListDisplayComponent,
        QuickRefillComponent,
        DeleteRxComponent,
        FileDueDateComponent,
        PrintRxLabelComponent,
        ActionsComponent,
        SendEfillRequestComponent,
        PrintPatientRecordComponent,
        PrintErxReqComponent,
        CheckDurComponent,
        SomthngWrongComponent,
        CancelPopUpComponent,
        ElectronicRxDocComponent,
        RefillQueueAddComponent,
        ClinicalInfoSharedComponent,
        RxClinicalInfoComponent,
        OverrideLogComponent,
        CopyncdpComponent,
        SubPriceListComponent,
        InsucopyComponent,
        RxFollowTagComponent,
        DurComponent,
        ErxChangeReqComponent,
        DiagListComponent,
        OtherAmountComponent,
        SubDurComponent,
        ColorPickerModule,
        DefaultLabelPrintsetComponent,
        BillCashPopUpComponent,
        RxFileComponent,
        NextBillToInsuranceComponent,
        ViewTransMsgComponent,
        ScannerUtilComponent,
        DeferredReasonListComponent,
        CntrlFileResubComponent,
        UCCalculationComponent,
        BillToCashComponent,
        PriorApprvlComponent,
        ImageEditorComponent,
        NgxImageZoomModule,
        FamilyInfoComponent,
        RefillRequestComponent,
        WjCoreModule,
        WjInputModule,
        WjGridModule,
        WjChartModule,
        RefillFlowComponent,
        DocumentRefComponent,
        FamilyInfoRefComponent,
        TransDurComponent,
        LowInventoryComponent,
        PdfViewerComponent,
        DocSplitComponent,
        RphMarkVerfiedComponent,
        SearchGlobalRefComponent,
        PrinterConfigComponent,
        PriorApprovalResultComponent,
        ImageEditorRefComponent,
        ErxResultComponent,
        PrintRxFormComponent,
        NgxMaskModule,
        RxSigQtyDawComponent,
        DiagnisCodeComponent,
        InsuranceListComponent,
        DrugMissingFormComponent,
        ViewDataSetComponent,
        RxBasicDisplayComponent,
        CommonDrugPickVerifyComponent,
        CommonWarnorconfirmPopupComponent,
        CommonRphVerifyComponent,
        AutomaticSubmissionCntrlFileComponent,
        RxSerialReqPopDisplayComponent,
        SendSmsComponent,
        SendRefillDueComponent,
        CommonPriceValsChangePopupComponent,
        AdditionalInsurancesCommonComponent,
        CommonDrugpickVerfHistComponent,
        AddDurComponent,
        NotesHistoryComponent,
        DrugCategoriesComponent,
        NoDataFoundComponent,
        HoaComponent,
        UserGuideIconComponent,
        IouCaptureComponent,
        VaccineImmunizationComponent,
        CommonDscsaInfoComponent,
        ClinicalAssessmentComponent,
        VerificationChecksComponent,
        RxPrivThumbnailsComponent,
        IvyCarouselModule
    ],
    providers: [
        PrivMaskGuard,
        DrugPriceCheckService,
        PatientService,
        BucketService,
        NgbActiveModal,
        QuickRefillService,
        PrintErxService,
        LabelDesignService,
        ControlFileService,
        PatientHistoryPrintService,
        DrugService
    ],
    entryComponents: [
        ERxTRxREQComponent,
        PrevoiusRefillsFillsComponent,
        EligibilityComponent,
        RxTotalQtyComponent,
        RxDeliveryInfoComponent,
        AdminRxComponent,
        InsuranceListComponent,
        RefillProgressStatsComponent,
        QuickVerfComponent,
        EasyRefillWizardComponent,
        DeleteRxComponent,
        ActionsComponent,
        CopyncdpComponent,
        TransferPharmacyComponent,
        BucketListDisplayComponent,
        OverrideLogComponent,
        RxFollowTagComponent,
        DiagListComponent,
        SubDurComponent,
        BillCashPopUpComponent,
        RxFileComponent,
        EquivalentDrugsComponent,
        NextBillToInsuranceComponent,
        ViewTransMsgComponent,
        CntrlFileResubComponent,
        UCCalculationComponent,
        ImageEditorRefComponent,
        ImageEditorComponent,
        ClinicAddressComponent,
        DocumentRefComponent,
        RefillFlowComponent,
        LowInvBuckTransComponent,
        CompIngredListComponent,
        BillToCashComponent,
        LowInventoryComponent,
        PriorApprovalResultComponent,
        ErxResultComponent,
        LoginVerifyComponent,
        BatchEditComponent,
        FileDueDateComponent,
        DrugMissingFormComponent,
        ViewDataSetComponent,
        RxBasicDisplayComponent,
        CommonWarnorconfirmPopupComponent,
        RxSerialReqPopDisplayComponent,
        SendSmsComponent,
        SendRefillDueComponent,
        CommonPriceValsChangePopupComponent,
        AdditionalInsurancesCommonComponent,
        CommonDrugpickVerfHistComponent,
        AddDurComponent,
        HoaComponent,
        IouCaptureComponent
        ],
    schemas:  [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class SharedModule {}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { constant } from "../app.constants";
import { Observable ,  BehaviorSubject } from "rxjs";
import { RxStore } from "../store";
import { TransmitSearch } from "../models";
import { share } from "rxjs/operators";

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
};
@Injectable({
    providedIn: "root"
})
export class TransmissionService {
    modalRef: any;

    grpTransInfo$: Observable<any>;
    _grpTransInfo$: BehaviorSubject<any>;

    totalGrpTransInfo$: Observable<any>;
    _totalGrpgrpTransInfo$: BehaviorSubject<any>;

    _payRecords$ = new BehaviorSubject({}) as BehaviorSubject<any>;
    payRecords$ = this._payRecords$.asObservable();

    _transmsnModal$ = <BehaviorSubject<any>>new BehaviorSubject(null);
    transmsnModal$ = this._transmsnModal$.asObservable();

    _isTransmnsCmpltd$ = <BehaviorSubject<any>>new BehaviorSubject(null);
    isTransmnsCmpltd$ = this._isTransmnsCmpltd$.asObservable();

    constructor(private _http: HttpClient, private _rxStore: RxStore) {
        this._grpTransInfo$ = new BehaviorSubject(null) as BehaviorSubject<any>; // value from child components
        this.grpTransInfo$ = this._grpTransInfo$.asObservable();

        this._totalGrpgrpTransInfo$ = new BehaviorSubject(
            null
        ) as BehaviorSubject<any>; // value from child components
        this.totalGrpTransInfo$ = this._totalGrpgrpTransInfo$.asObservable();
    }

    getUnbilledRxInfo(payload: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_TransUnbilledQueue, payload
        );
    }

    initiateBatchTransmission(transData: any): Observable<any> {
        return this._http.put<any>(constant.PUT_BatchTransmission, transData);
    }

    checkBeforeBatchTrans(transData: any): Observable<any> {
        return this._http.put<any>(constant.PUT_GetPending_Rx, transData);
    }

    groupTransmission(patId?: any, isComp?: boolean): any {
        if (isComp) {
            return this._http
            .get<any>(constant.GET_MenuGroupTransmissions.replace(
                "{patientId}",
                "" + (+patId) ? patId : 0
            ).replace("{IsCompleted}", "" + isComp))
            .subscribe(resp => {
                this._totalGrpgrpTransInfo$.next(resp);
            });
        } else {
            return this._http
            .get<any>(constant.GET_MenuGroupTransmissions.replace(
                "{patientId}",
                "" + (+patId) ? patId : 0
            ).replace("{IsCompleted}", "" + isComp), reqOptns)
            .subscribe(resp => {
                this._totalGrpgrpTransInfo$.next(resp);
            });
        }
    }

    getGroupTransmissionStatus(patId?: any, isComp?: boolean, groupNum?: number): any {
        if (isComp) {
            return this._http.get<any>(
                constant.GET_MenuGroupTransmissions.replace(
                    "{patientId}",
                    "" + (+patId) ? patId : 0
                ).replace("{IsCompleted}", "" + isComp).replace("{groupnum}", "" + groupNum));
        } else {
            return this._http.get<any>(
                constant.GET_MenuGroupTransmissions.replace(
                    "{patientId}",
                    "" + (+patId) ? patId : 0
                ).replace("{IsCompleted}", "" + isComp).replace("{groupnum}", "" + groupNum), reqOptns);
        }
    }

    groupTransmHistory(transFilter): Observable<any> {
        return this._http.post<any>(
            constant.POST_GroupTransmissions,
            transFilter
        );
    }

    userTransmissionHistory(histrParams: any): Observable<any> {
        return this._http.post<any>(
            constant.GET_UserTransmissionHistory,
            histrParams
        );
    }

    storedata(data) {
        this._grpTransInfo$.next(data);
        // this.grpTransInfo = new Rx.BehaviorSubject(data);
    }

    updateUserReviewStatus(prescRefID: number, statusId: any) {
        return this._http.put<any>(
            constant.PUT_UpdateUserReviewStatus.replace(
                "{prescrefillId}",
                "" + prescRefID
            ).replace("{statusId}", "" + statusId),
            {}
        );
    }

    async reviewTransmissionStatus(prescRefID: number, statusId: any) {
        return await this._http.put<any>(
            constant.PUT_UpdateUserReviewStatus.replace(
                "{prescrefillId}",
                "" + prescRefID
            ).replace("{statusId}", "" + statusId),
            {}
        ).toPromise();
    }

    initiateRxTransmission(transRxData: any) {
        const loaderOptns = {
            headers: new HttpHeaders().set("loadingMesssage", "Transmitting Rx")
        };
        return this._http.post<any>(
            constant.POST_Transmit_TransmitClaim,
            transRxData , loaderOptns
        ).subscribe(resp => this._rxStore.storeTransmissionData(resp));
    }

    initiateRxTransmissionAsObsev(transRxData: any) {
        const loaderOptns = {
            headers: new HttpHeaders().set("loadingMesssage", "Transmitting Rx")
        };
        return this._http.post<any>(
            constant.POST_Transmit_TransmitClaim,
            transRxData , loaderOptns
        );
    }

    getRxTransmissionStatus(rxTransLogId, transmitSearch: TransmitSearch, claimId: any): Observable<any> {
        return this._http.post<any>(constant.POST_Transmit_TransmitClaim_Show
            .replace("{rxTransLogId}", "" + rxTransLogId).replace("{claimId}", "" + claimId), transmitSearch).pipe(share());
    }

    getandStoreRxTransmissionInfo(rxTransLogId, transmitSearch: TransmitSearch, claimId: any): void {
        this._http.post<any>(constant.POST_Transmit_TransmitClaim_Show
            .replace("{rxTransLogId}", "" + rxTransLogId).replace("{claimId}", "" + claimId), transmitSearch)
            .subscribe(resp => this._rxStore.storeTransmissionData(resp));
    }

    getTransmissionInfo(rxTransLogId, transmitSearch: TransmitSearch, claimId: any): any {
        return this._http.post<any>(constant.POST_Transmit_TransmitClaim_Show
            .replace("{rxTransLogId}", "" + rxTransLogId).replace("{claimId}", "" + claimId), transmitSearch).toPromise();
    }

    getPayRecord(Id) {
        return this._http.get(constant.GET_Pay_record.replace("{prescRefillId}", "" + Id));
    }

    getPayDetails(Id) {
        return this._http.get(constant.GET_Pay_Details.replace("{prescRefillId}", "" + Id));
    }

    updateBenifitInfo(data) {
        return this._http.put(constant.PUT_Benefit_Info, data);
    }

    storePayRecords(data) {
        this._payRecords$.next(data);
    }

    setTranmsnComplted(val) {
        this._isTransmnsCmpltd$.next(val);
    }

    getCompletedGroupTransmsnInfo(patId?: any) {
        return this._http.get(constant.GET_Completed_Group_Tansmision.replace(
            "{patientId}",
            "" + patId ? patId : 0
        ));
    }

    groupTransmStatusofFailedRx(failedBatchIDs: any) {
        return this._http.post<any>(
            constant.POST_Transmit_GroupTransmStatusofFailedRx,
            failedBatchIDs
        );
    }

    getTransRejectCode() {
        return this._http.get(constant.GET_TRANS_REJECT_CODES);
    }

    getLargePriceValues(data) {
        return this._http.post(constant.POST_LARGE_VALUES, data);
    }
    
}

<div [ngClass]="(isAddnew &&
!erxSearch) ? 'mt-1 input-group' : searchFrmPage === 'edi810' ? 'input-group has-no-add' : 'mt-1 input-group has-no-add'">
    <div [ngClass]="
            disableSerchFil ? 'input-group-btn disabled' : 'input-group-btn'
        " *ngIf="!hasFilters">
        <select *ngIf="!erxSearch" #searchIcon class="select--box" [value]="option"
            (change)="getValueFromDropdown($event.target.value); clearValue()" [disabled]="disableSerchFil">
            <option *ngFor="let opt of listItems" [value]="opt">
                {{ opt }}</option>
        </select>
        <select *ngIf="erxSearch" #searchIcon class="select--box" [value]="option"
            (change)="getValueFromDropdown($event.target.value); clearValue()">
            <option *ngFor="let opt of erxItems" [value]="opt">
                {{ opt }}</option>  
        </select>
    </div>

    <div class="col-md-12 radiobuttons--block" *ngIf="!IsHide">
        <div class="row">
            <div [ngClass]="(selectedValue === 'Drug') ? 'col-8 pr-0' : 'col-10 pr-0'" 
              [hidden]="disableRadioButtons"
                *ngIf="!disableRadioButtons">
                <div class="row" [hidden]="disableRadioButtons">
                    <div class="col-7">
                        <div class="row">
                            <div class="custom-control custom-radio col-4 pr-0"
                                *ngFor="let key of ['Patient', 'Prescriber', 'Drug']">
                                <input class="custom-control-input" type="radio" [id]="key" [checked]="
                                        (selectedValue ? selectedValue : 'Patient') === key
                                    " name="typesearch" [value]="key" (click)="
                                        getValueFromDropdown($event.target.value); clearValue()
                                    " *ngIf="!erxSearch" />
                                <label class="custom-control-label dash--custom__label" [for]="key" *ngIf="!erxSearch">
                                    {{ key }}
                                    <!-- <div *ngIf="selectedValue===key" class="d-inline" style="font-weight: bold;color: rgb(247, 148,29);" [ngbTooltip]="instructToolTip"  tooltipClass="search-tooltip"  triggers="click" [autoClose]="true" placement="bottom-left">𝒊</div> -->
                                    <div *ngIf="selectedValue===key"
                                        class="d-inline position-absolute font-weight-bolder text-white ml-1  dash_boardInfo text-center font_size_1_2rem background_orange border_radius_50per"
                                        [ngbTooltip]="instructToolTip" container="body"
                                        tooltipClass="search-tooltip hover--width" triggers="mouseover:mouseleave"
                                        [autoClose]="true" placement="bottom-left">𝒊</div>
                                    <ng-template #instructToolTip>
                                        <div>
                                            <div class="row text-left" *ngIf="selectedValue==='Patient'">
                                                <div class="col-md-12">
                                                    <label class="margin_bottom_1rem text-decoration-underline">Search
                                                        Patient</label>
                                                </div>
                                                <p class="col-md-12">Enter Patient <strong> Last, First
                                                        name</strong>(can be partial last and first name) to search <br>
                                                    or <br>
                                                    In order to search by patient's first name only, enter,
                                                    <strong>First name</strong> <br>
                                                    or<br>
                                                    Enter Patient's <strong>Insurance ID</strong> to search<br>
                                                    or<br>
                                                    Enter Patient's <strong>DOB</strong> (MM/DD/YYYY)<br>
                                                    or <br>
                                                    Enter patient's <strong>Chart#</strong><br>
                                                    or<br>
                                                    Enter Patient's <strong>Phone#</strong><br>
                                                    or<br>
                                                    Enter patient's Rx number followed by <strong>#</strong>
                                                </p>
                                            </div>
                                            <div class="row text-left" *ngIf="selectedValue==='Prescriber'">
                                                <div class="col-md-12">
                                                    <label class="margin_bottom_1rem text-decoration-underline">Search
                                                        Prescriber</label>
                                                </div>
                                                <p class="col-md-12">Enter Prescriber<strong> Last name, First name
                                                    </strong> <br>
                                                    or <br>
                                                    In order to search by prescriber's first name only, enter,
                                                    <strong>First name</strong><br>
                                                    or<br>
                                                    Enter prescriber's <strong>NPI#</strong><br>
                                                    or<br>
                                                    Enter prescriber's <strong>License#</strong><br>
                                                    or<br>
                                                    Enter prescriber's <strong>Phone#</strong><br>
                                                </p>
                                            </div>
                                            <div class="row text-left" *ngIf="selectedValue==='Drug'">
                                                <div class="col-md-12">
                                                    <label class="margin_bottom_1rem text-decoration-underline">Search
                                                        Drug</label>
                                                </div>
                                                <p class="col-md-12">Enter Drug name to search <strong>(Format name,
                                                        strength)</strong> <br>
                                                    or<br>
                                                    Enter the <strong>11 digit NDC </strong>to search by Drug NDC <br>
                                                    or<br>
                                                    Enter the <strong>Drug quick code </strong> to search by Drug Quick
                                                    code
                                                </p>
                                            </div>
                                        </div>
                                    </ng-template>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="row">
                            <div class="custom-control custom-radio col-4 pl-0" *ngIf="!erxSearch">
                                <input class="custom-control-input" type="radio" id="RxSer"
                                    [checked]="selectedValue === 'Rx'" name="typesearch" value="Rx" (click)="
                                        getValueFromDropdown($event.target.value); clearValue()
                                    " />
                                <label class="custom-control-label dash--custom__label" for="RxSer">
                                    Rx
                                <div *ngIf="selectedValue === 'Rx'" class="d-inline position-absolute font-weight-bolder text-white ml-1  dash_boardInfo text-center font_size_1_2rem background_orange border_radius_50per"
                                    [ngbTooltip]="instructToolTip" container="body"
                                    tooltipClass="search-tooltip hover--width" triggers="mouseover:mouseleave"
                                    [autoClose]="true" placement="bottom-left">𝒊</div>
                                <ng-template #instructToolTip>
                                    <div>
                                        <div class="row text-left">
                                            <div class="col-md-12">
                                                <label class="margin_bottom_1rem text-decoration-underline">Enter
                                                    Rx</label>
                                            </div>
                                            <p class="col-md-12">
                                                -------<br>
                                                Enter Rx# <br>
                                                or <br>
                                                Enter Rx# with Ref# <br>
                                                or<br>
                                                Enter Rx# with Ref# and Fill#<br>
                                            </p>
                                        </div>
                                    </div>
                                </ng-template>
                                </label>
                            </div>
                            <div class="custom-control custom-radio col-6 pr-0" *ngIf="!erxSearch">
                                <input class="custom-control-input" type="radio" id="RefillSer"
                                    [checked]="selectedValue === 'Refill'" name="typesearch" value="Refill" (click)="
                                    getValueFromDropdown($event.target.value); clearValue()
                                " />
                                <label class="custom-control-label dash--custom__label" for="RefillSer">
                                    Refill Wizard
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="!disableRadioButtons ? ((selectedValue === 'Drug') ? 'col-4 pl-0' : 'col-2 pl-0') : 'col-12'">
                <!-- <span class="text-right" *ngIf="selectedValue === 'Drug' && !disableRadioButtons">
                    <span>
                        <i class="far fa-lg fa-long-arrow-alt-right color_orange"></i><span> for Equv ;
                        </span> <b class="color_orange">Shift + <i
                                class="far fa-lg fa-long-arrow-alt-right color_orange"></i></b><span>for Gen Equv</span>
                    </span>
                </span> -->
                <span class="d-inline help-box-information" *ngIf="!disableRadioButtons" container="body"
                    [ngbTooltip]="instructshortCutHelp" tooltipClass="{{notAdashBoard ? 'help-tooltip-bottom' :'help-tooltip'}} hover--width"
                    triggers="mouseover:mouseleave" [autoClose]="true" placement="right">Help</span>
                <ng-template #instructshortCutHelp>
                    <div class="row text-left">
                        <div class="col-md-12">
                            <label class="margin_bottom_1rem text-decoration-underline">Short cut Help (Case
                                Insensitive)</label>
                        </div>
                        <ul class="">
                            <li> <span class="font-weight-bold ">Alt + /</span> To focus on Global search </li>
                            <li> <span class="font-weight-bold ">P:</span> To search Patient </li>
                            <li> <span class="font-weight-bold ">PR:</span> To search Prescriber </li>
                            <li> <span class="font-weight-bold ">D:</span> To search Drug </li>
                            <li> <span class="font-weight-bold ">RX: </span> search Rx </li>
                            <li> <span class="font-weight-bold ">Alt + X:</span> To add new Patient </li>
                            <li> <span class="font-weight-bold ">Alt + Y:</span> To add new Prescriber </li>
                            <li> <span class="font-weight-bold ">Alt + Z:</span> To add new Drug </li>
                            <li> <span class="font-weight-bold ">Ctrl + R:</span> To add new RX </li>
                        </ul>
                    </div>
                </ng-template>
            </div>
        </div>

    </div>
    <input *ngIf="!(selectedValue === 'Rx' || selectedValue === 'Refill')" type="text" #searchBox [tabindex]="tabIndex"
        name="searchBox" [ngClass]="(searchFrmPage && (searchFrmPage === 'Dash' || searchFrmPage === 'header'))?'form-control search--input__box z-index-0 global_search': (searchFrmPage === 'edi810' ? 'form-control search--input__box z-index-0 heightInline': 'form-control search--input__box z-index-0')" [ngStyle]="{height: (searchFrmPage === 'vaccnForm' ? '2.8rem' : '')}" placeholder="Search {{ placholderText }}{{(selectedValue==='Patient' || selectedValue === 'Prescriber' || selectedValue === 'Drug') ? ' - Enter min 3 Chars' : ''}}"
        appChangeDebounce [ngbTypeahead]="search" [resultTemplate]="rt" [inputFormatter]="formatter"
        (keydown.esc)="clearFocus()" KeyUpDown [disabled]="inputDisable" [value]="inputValue" [title]="inputDisable && isFrmErx ? 'The DAW is set to Yes and the prescribed drug is not found in our database. Please add the drug to continue.' : ''"
        (keyup.enter)="keyTab($event)" (blur)="focusedOutofSearch()" (change)="checkIsNullValue($event?.target?.value)"
        [id]="searchID? searchID : 'iSearchBox'" ngbtypeaheadpopup [placement]="'bottom'" #instance="ngbTypeahead"
        [BarcodeDetector]="selectedValue === 'Drug'" (EmitBarcode)="getBarcodeInfo($event)"  [container]="searchFrmPage === 'edi810' ? 'body' : ''"/>

    <!-- <div *ngIf="showEqDrugs && selectedValue === 'Drug' && EqDrugs.length > 0"
        class="col-lg-12 dropdown eqv_drugs---search">
        <div class="eq_drug_header eq_drug_header_70 text-center">
            <h3>{{eqDrugHeading}}</h3>
        </div>
        <ul [ngClass]="showlgList ? 'lg-dropdown-menu_eq_list show eq--list' : 'dropdown-menu_eq_list show eq--list'">
            <li class="dropdown-item" [ngClass]="i===selecteEqDrugRowId ? 'active':''"
                *ngFor="let eqDrug of EqDrugs;let i = index" (click)="selectedEqDrug(eqDrug)">
                <app-drug-result-card [IsFrmErx]="isFrmErx" [DrugResultData]="eqDrug" [RouterURL]="drugRouterURL"
                    [IsAllToShow]="erxSearch" [ShowOnlyDrugName]="showDrugName" [index]="i"
                    [DrugSuggestFilterFG]="DrugSuggFiltFG"></app-drug-result-card>
            </li>
        </ul>
    </div> -->
    <input *ngIf="selectedValue === 'Rx' || selectedValue === 'Refill'" type="text" #searchBox [tabindex]="tabIndex"
        name="searchBox" class="form-control search--input__box z-index-0 global_search" placeholder="Search {{ placholderText }}{{(selectedValue==='Patient' || selectedValue === 'Prescriber' || selectedValue === 'Drug') ? ' - Enter min 3 Chars' : ''}}"
        (keydown.esc)="clearFocus()" [disabled]="inputDisable || selectedValue === 'Refill'" [value]="inputValue"
        (keydown.enter)="searchByKeyWord($event?.target?.value); keyTab($event)" (blur)="focusedOutofSearch()"
        id="iSearchBox" appChangeDebounce [ngbTypeahead]="searchRx" />
    <span class="" *ngIf="hasFilters">
        <div class="search--icon">
            <i class="far fa-search" (click)="!IsClearIConDisable ? inputClearedBySearchIcon() : ''"></i>
        </div>
    </span>
    <a *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Patient' &&
            hasAddPatPrivs
        " class="add-new--icon p-0 mt-1" (click)="IsClearIConDisable? '' : addNewPatientPrescDrug()">
        <!--i class="far fa-plus-circle" title="Add Patient"></i-->
        <button [ngClass]="isFrmRx ? 'hotkey_success' : (IsClearIConDisable ? 'inactive' : 'hotkey_success hotkey_primary--add-button')"
            [title]="'Alt+X'"><span>X</span>ADD</button>
    </a>
    <a (click)="openOveride('PatientFile', 'Add')" *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Patient' &&
            !hasAddPatPrivs
        " class="add-new--icon p-0 mt-1">
        <!--i class="far fa-plus-circle" title="User has No privilege"></i-->
        <button [ngClass]="isFrmRx ? (IsClearIConDisable ? 'inactive' : 'hotkey_success'):
        (IsClearIConDisable ? 'inactive' : 'hotkey_success hotkey_primary--Override-Global')"
            [title]="'Alt+X'"><span>X</span>ADD</button>
    </a>
    <a *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Prescriber' &&
            hasAddprescPrivs
        " class="add-new--icon p-0 mt-1" (click)="addNewPatientPrescDrugRx()">
        <!--i class="far fa-plus-circle" title="Add Prescriber"></i-->
        <button [ngClass]="isFrmRx ? 'hotkey_success' : 'hotkey_success hotkey_primary--Override-Global'"
            [title]="'Alt+Y'"><span>Y</span>ADD</button>
    </a>
    <a (click)="openOveride('PrescriberFile', 'Add')" *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Prescriber' &&
            !hasAddprescPrivs
        " class="add-new--icon p-0 mt-1">
        <!--i class="far fa-plus-circle" title="User has No privilege"></i-->
        <button [ngClass]="isFrmRx ? 'hotkey_success' : 'hotkey_success hotkey_primary--Override-Global'"
            [title]="'Alt+Y'"><span>Y</span>ADD</button>
    </a>
    <!-- Drug Suggest Filter opening after click-->
    <!-- <span [hidden]="IsHideDrugFilters" [ngClass]="[ isAddnew ? 'mt-3 pl-2 edit--drug' : 'mt-3 pl-2 edit--drug']"
        [placement]="positionForBucketNameDisplay" *ngIf="
        !erxSearch && selectedValue == 'Drug'" triggers="mouseover:mouseleave" container="body"
        [ngbTooltip]="instructToolTip"
        [tooltipClass]=" (positionForBucketNameDisplay === 'top') ? 'search-tooltip-patient_bucket' : 'search-tooltip-patient_bucket_for_left_display' ">
        <ng-template #instructToolTip>
            <div>
                <b>Bucket Name : {{bucketName[0]?.Name | uppercase}}</b>
            </div>
        </ng-template>
        <i class="far fa-filter fa-lg actions rph--filter--icon" (click)="openDrugFilter()"></i>
    </span>
    <span *ngIf="!erxSearch && selectedValue == 'Drug' && !disableRadioButtons" title="Drug Search List Color Legend"
        [ngClass]="[ isAddnew ? 'mt-3 pl-2 edit--drug' : 'mt-2 pl-2 edit--drug']">
        <img src="assets/img/drug_color_code.png" class="d-inline cursor_pointer color_legend_dashboard"
            (click)="toggleColorLegend()">
    </span> -->
    <a *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Drug' &&
            hasAddDrugPrivs
        " class="add-new--icon p-0 mt-1" (click)="addNewPatientPrescDrugRx()">
        <!--i class="far fa-plus-circle" title="Add Drug"></i-->
        <button [ngClass]="isFrmRx ? 'hotkey_success' : 'hotkey_success hotkey_primary--Override-Global'"
            [title]="'Alt+Z'"><span>Z</span>ADD</button>
    </a>
    <a (click)="openOveride('DrugFile', 'Add')" *ngIf="
            isAddnew &&
            !erxSearch &&
            selectedValue == 'Drug' &&
            !hasAddDrugPrivs
        " class="add-new--icon p-0 mt-1">
        <!--i class="far fa-plus-circle" title="User has No privilege"></i-->
        <button [ngClass]="isFrmRx ? 'hotkey_success' : 'hotkey_success hotkey_primary--Override-Global'"
            [title]="'Alt+Z'"><span>Z</span>ADD</button>
    </a>



    <ng-template #rt let-r="result" let-t="term">
        <div *ngIf="selectedValue == 'Patient'" class="col-md-12 padding-0">
            <app-patient-card [ResultData]="r" [RouterURL]="patRouterURL" [IsAllToShow]="erxSearch"
                [ShowOnlyPatName]="showPatName" [index]="r['index']" [searchTerm]="searchTextValue"></app-patient-card>
        </div>
        <div *ngIf="selectedValue == 'Prescriber'" class="col-md-12 padding-0">
            <app-prescriber-result-card [PrescriberResultData]="r" [RouterURL]="presRouterURL" [IsAllToShow]="erxSearch"
                [ShowOnlyPrescName]="showPrescName" [index]="r['index']"
                [searchTerm]="searchTextValue"></app-prescriber-result-card>
        </div>
    </ng-template>
</div>

<div class="row" *ngIf="selectedValue == 'Drug' && !dawFromeRxY && drugList?.length">
    <app-drug-result-card [IsFrmErx]="isFrmErx" [DrugResultData]="drugList" [RouterURL]="drugRouterURL"
    [IsAllToShow]="erxSearch" [ShowOnlyDrugName]="showDrugName" (EmitEqDrugs)="equivalentDrugs($event,true)"
    (EmitCloseEqDrugs)="showEqDrugs=false" (EmitGenDrugs)="equivalentDrugs($event,false)" [searchTerm]="searchTextValue" [SingleBucketMode]="singleBucketMode"
    [DrugSuggestFilterFG]="DrugSuggFiltFG" [bucketFrmPriceChck]="bucketFrmPriceChck" [searchFrmPage]="searchFrmPage" [allowToAddNewDrug]="allowToAddNewDrug"
    [updateDrugSuggFilter]="updateDrugSuggFilter" [DrugBucketList]="drugBucketList" [isFrmRx]="isFrmRx" [disableBuck]="disableBuck" (GetSelectedDrug)="getSelectedDrug($event)"
    (AddNewDrug)="addNewDrug($event)" (GetDrugBarcodeInfo)="getBarcodeInfo($event)" [IsScannedDrugNotFound]="isScannedDrugNotFound" (CloseDrugPage)="closeDrugPage($event)"></app-drug-result-card>
</div>

<!-- Start Warning PopUps -->

<ng-template #addPatient let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Search Patient</h4>
        <div body *ngIf="searchValue && searchValue.endsWith('#')"><b>Patient not found with given Rx: </b> <span
                class="font-bigger text-primary word_break">{{searchValue | uppercase}}</span>. <span
                *ngIf="!IsFromPkg">Would you like to add?</span></div>

        <div body *ngIf="searchValue && !searchValue.endsWith('#')"><b>Patient: </b> <span
                class="font-bigger text-primary word_break">{{searchValue | uppercase }}</span> not found. <span
                *ngIf="!IsFromPkg">Would you like to add?</span></div>
        <button footer ngbAutofocus *ngIf="!IsFromPkg" id="addOk" type="submit" class="hotkey_primary" appShortcutKey
            InputKey="y" (click)="addNewPatientPrescDrug()" (keydown.enter)="addNewPatientPrescDrug()">
            <b>Y</b> YES
        </button>
        <button footer ngbAutofocus id="addcDefActn" type="submit" *ngIf="!IsFromPkg" class="hotkey_primary"
            appShortcutKey InputKey="n" (click)="closeModal()" (keydown.enter)="closeModal()">
            <b>N</b> NO
        </button>
        <button footer ngbAutofocus id="addcDefActn" type="submit" *ngIf="IsFromPkg" class="hotkey_primary"
            appShortcutKey InputKey="o" (click)="closeModal()" (keydown.enter)="closeModal()">
            <b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #addPrescriber let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Search Prescriber</h4>
        <div body><b>Prescriber: </b> <span class="font-bigger text-primary word_break">{{searchValue |
                uppercase}}</span> not found. Would you like to add?</div>
        <button footer ngbAutofocus id="addOk" type="submit" class="hotkey_primary" appShortcutKey InputKey="y"
            (click)="addNewPatientPrescDrug()" (keydown.enter)="addNewPatientPrescDrug()">
            <b>Y</b> YES
        </button>
        <button footer ngbAutofocus id="addOk" type="submit" class="hotkey_primary" appShortcutKey InputKey="m"
            (click)="addNewPatientPrescDrug(true)" (keydown.enter)="addNewPatientPrescDrug(true)">
            <b>M</b> Search From MMS
        </button>
        <button footer id="addcDefActn" type="submit" class="hotkey_primary" appShortcutKey InputKey="c"
            (click)="closeModal()" (keydown.enter)="closeModal()">
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #addDrug let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Search Drug</h4>
        <div body *ngIf="allowToAddNewDrug && !isScannedDrugNotFound " class="word_break"><b>Drug </b><span
                class="font-bigger text-primary">{{searchValue | uppercase}}</span> not found. Would you like to add?
        </div>

        <div body *ngIf="!allowToAddNewDrug && !isScannedDrugNotFound" class="word_break"><b>Drug: </b><span
                class="font-bigger text-primary">{{searchValue | uppercase }}</span> not found.</div>

        <div body><span *ngIf="isScannedDrugNotFound">Drug not found with scanned barcode.</span></div>


        <button footer ngbAutofocus id="addOk" type="submit" class="hotkey_primary" appShortcutKey InputKey="y"
            (click)="addNewPatientPrescDrug()" (keydown.enter)="addNewPatientPrescDrug()" *ngIf="allowToAddNewDrug">
            <b>Y</b> YES

            <!-- <span *ngIf="!isFrmErx">Y</span> Add
            <span *ngIf="isFrmErx">Y</span> Yes -->
        </button>
        <button *ngIf="!allowToAddNewDrug" footer type="submit" class="hotkey_primary" appShortcutKey InputKey="o"
            (click)="d('Cross click')" (keydown.enter)="d('Cross click')"><b>O</b> OK</button>
        <button *ngIf="!isFrmErx && allowToAddNewDrug" footer ngbAutofocus id="addOk" type="submit"
            class="hotkey_primary" appShortcutKey InputKey="m" (click)="addNewPatientPrescDrug(true)"
            (keydown.enter)="addNewPatientPrescDrug(true)">
            <b>M</b> Search From MMS
        </button>
        <button footer id="addcDefActn" type="submit" class="hotkey_primary" appShortcutKey InputKey="c"
            (click)="closeModal()" (keydown.enter)="closeModal()" *ngIf="allowToAddNewDrug">
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #addDrugHasVal let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Search Drug</h4>
        <div body>
            The selected Drug filters restricts the display of the searched drug
            although the drug exists in the drug file. <br />
            Please change the Drug filters if you wish to choose this drug, else search for a different one.
        </div>
        <button footer id="addcDefActn" type="submit" class="hotkey_primary" appShortcutKey InputKey="o"
            (click)="closeModal()" (keydown.enter)="closeModal()" *ngIf="allowToAddNewDrug">
            <b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #addRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Search Rx</h4>
        <div body><span class="font-bigger text-primary">

                {{ (selectedValue && (selectedValue === 'Refill')) ? 'Refill# Not Found.' : 'Rx not found.'}}
            </span></div>

        <button footer id="addcDefActn" type="submit" class="hotkey_primary" appShortcutKey InputKey="o"
            (click)="closeModal()" (keydown.enter)="closeModal()">
            <b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #DrugSuggestFilter let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeDrugFilter()">
        <h4 header>Drug Suggest Filter</h4>
        <div body>
            <div class="eprx--block__content row">
                <div class="col-lg-12" *ngIf="addDrugHasValue">
                    <span class="font_size_1_2rem">
                        The selected Drug filters restricts the display of the searched drug
                        although the drug exists in the drug file. <br />
                        Please change the Drug filters from below if you wish to choose this drug, else search for a
                        different one.
                    </span>
                </div>
                <div class="col-lg-12 mt-4" *ngIf="addDrugHasValue">
                    <div class="box box-default box-solid">
                        <div class="box-body">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <eprx-select [AutoFocus]="(updateDrugSuggFilter && updateDrugSuggFilter.BucketId ? true : false) ||
                                    (isFrmRx && !checkInvPrivs()) ? false : true"
                                    [Title]="isFrmRx && !checkInvPrivs() ? 'User does not have privilege to change Bucket' : ''"
                                    [RxSelectId]="'bucketListFrmDrgsug'" [LabelText]="'Inv. Bucket Name'"
                                    [PlaceHolder]="'Bucket Name'" [ControlName]="'BucketId'"
                                    (TriggerSelectValue)="getBucketList()" [FormGroupName]="DrugSuggFiltFG"
                                    [BindLabel]="'Name'" [BindLabel2]="'BucketContextType'" [HasBindLabel2]="true"
                                    [BindValue]="'Id'" [LabelForId]="'Id'" [LabelTitle1]="'Bucket Name'"
                                    [LabelTitle2]="'Bucket Context Type'" [IsDisabled]="(updateDrugSuggFilter && updateDrugSuggFilter.BucketId  && (((isFrmErx || disableBuck)) ? true : false) ? true : false) ||
                                    (isFrmRx && !checkInvPrivs()) || bucketFrmPriceChck" [List]="drugBucketList">
                                </eprx-select>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4"
                                *ngIf="!(isFrmErx || disableBuck)">
                                <eprx-check-box [LabelText]="'Show drugs only which are added to this bucket'"
                                    [ControlName]="'OnlyShowDrugsAvailableForBucket'" [FormGroupName]="DrugSuggFiltFG"
                                    [AutoFocus]="(updateDrugSuggFilter && updateDrugSuggFilter.BucketId ? true : false) ||
                                (isFrmRx && !checkInvPrivs()) || bucketFrmPriceChck"
                                    [rxSelectId]="'OnlyShowDrugsAvailableForBucketSuggest'">
                                </eprx-check-box>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
                                <eprx-check-box [LabelText]="'Show drugs only with available stock Qty'"
                                    [ControlName]="'OnlyDrugsHavingQtyInStock'" [FormGroupName]="DrugSuggFiltFG"
                                    [rxSelectId]="'OnlyDrugsHavingQtyInStockSuggest'">
                                </eprx-check-box>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
                                <eprx-check-box [LabelText]="'Show same Pack Size for non-splittable Equivalent Drugs'"
                                    [ControlName]="'ShowNonSplittableEquiDrugs'" [FormGroupName]="DrugSuggFiltFG"
                                    [rxSelectId]="'ShowNonSplittableEquiDrugsSuggest'">
                                </eprx-check-box>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mt-4">
                                <eprx-check-box [LabelText]="'Show 340B Drugs Only'" [ControlName]="'Is340B'"
                                    [FormGroupName]="DrugSuggFiltFG" [rxSelectId]="'Is340Bsuggest'">
                                </eprx-check-box>
                            </div>
                        </div>
                    </div>
                </div>

                <span *ngIf="!addDrugHasValue">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mt-4" *ngIf="!(isFrmErx || disableBuck)">
                        <eprx-select [AutoFocus]="(updateDrugSuggFilter && updateDrugSuggFilter.BucketId ? true : false) ||
                            (isFrmRx && !checkInvPrivs()) ? false : true"
                            [Title]="isFrmRx && !checkInvPrivs() ? 'User does not have privilege to change Bucket' : ''"
                            [RxSelectId]="'bucketListFrmDrgsug'" [LabelText]="'Inv. Bucket Name'"
                            [PlaceHolder]="'Bucket Name'" [ControlName]="'BucketId'" [FormGroupName]="DrugSuggFiltFG"
                            [BindLabel]="'Name'" (TriggerSelectValue)="getBucketList()"
                            [BindLabel2]="'BucketContextType'" [HasBindLabel2]="true" [BindValue]="'Id'"
                            [LabelForId]="'Id'" [LabelTitle1]="'Bucket Name'" [LabelTitle2]="'Bucket Context Type'"
                            [IsDisabled]="(updateDrugSuggFilter && updateDrugSuggFilter.BucketId  && (((isFrmErx || disableBuck)) ? true : false) ? true : false) ||
                            (isFrmRx && !checkInvPrivs()) || bucketFrmPriceChck" [List]="drugBucketList">
                        </eprx-select>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4" *ngIf="!(isFrmErx || disableBuck)">
                        <eprx-check-box [LabelText]="'Show drugs only which are added to this bucket '"
                            [ControlName]="'OnlyShowDrugsAvailableForBucket'" [FormGroupName]="DrugSuggFiltFG"
                            [AutoFocus]="(updateDrugSuggFilter && updateDrugSuggFilter.BucketId ? true : false) ||
                        (isFrmRx && !checkInvPrivs()) || bucketFrmPriceChck"
                            [rxSelectId]="'OnlyShowDrugsAvailableForBucketSuggest'">
                        </eprx-check-box>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
                        <eprx-check-box [LabelText]="'Show drugs only with available stock Qty'"
                            [ControlName]="'OnlyDrugsHavingQtyInStock'" [FormGroupName]="DrugSuggFiltFG"
                            [rxSelectId]="'OnlyDrugsHavingQtyInStockSuggest'">
                        </eprx-check-box>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
                        <eprx-check-box [LabelText]="'Show same Pack Size for non-splittable Equivalent Drugs'"
                            [ControlName]="'ShowNonSplittableEquiDrugs'" [FormGroupName]="DrugSuggFiltFG"
                            [rxSelectId]="'ShowNonSplittableEquiDrugsSuggest'">
                        </eprx-check-box>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 mt-4">
                        <eprx-check-box [LabelText]="'Show 340B Drugs Only'" [ControlName]="'Is340B'"
                            [FormGroupName]="DrugSuggFiltFG" [rxSelectId]="'Is340Bsuggest'">
                        </eprx-check-box>
                    </div>
                </span>
            </div>
        </div>
        <button footer id="savedefault" type="submit" class="hotkey_success" appShortcutKey [AltKey]="'s'"
            (click)="saveAsDefault()" (keydown.enter)="saveAsDefault()">
            <span>S</span>Save as default
        </button>
        <button *ngIf="!addDrugHasValue" footer id="closedrugsuggest" type="submit" class="hotkey_success"
            appShortcutKey [AltKey]="'c'" (click)="closeDrugFilter()" (keydown.enter)="closeDrugFilter()">
            <span>C</span>close
        </button>
        <button *ngIf="addDrugHasValue" footer id="closedrugsuggest" type="submit" class="hotkey_success" appShortcutKey
            [AltKey]="'o'" (click)="closeAddDrugHasValue()" (keydown.enter)="closeAddDrugHasValue()">
            <span>O</span>Ok
        </button>
    </app-custom-modal>
</ng-template>


<app-override-log *ngIf="openOverride" [OverrideEndpoint]="overrideEndpoint" [PrivMask]="privType" [CatId]="categoryId"
    (OverrideLoginDet)="OverrideRes($event)"></app-override-log>

<!-- Close pdocuemnt-ref PopUps -->
<app-easy-refill-wizard *ngIf="selectedValue === 'Refill'"
    (IsPopUpClosed)="refillWizardPopupClosed()"></app-easy-refill-wizard>
 <ng-template #VaccineForm let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Vaccine Immunization</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal('cancel')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="exprx--common-block">
            <div class="box-default mb-2">
                <div class="eprx--block__content border_AllRadius transmit--block insurance--body">
                    <div class="row">
                        <h5 class="col-12 label_Color">Patient Details</h5>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trasmit--rx">
                            <div class="row col-12">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'patientRaces'" [LabelText]="'Patient\'s Race'" [PlaceHolder]="''" 
                                    [ControlName]="'PatientRaceId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                    [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'" [AutoFocus]="true"
                                    [List]="vaccDrpDwnInfo?.PatientRace" [show2ValInInputhidden]="true" [IsRequired]="true"
                                    [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGrpInvld">
                                    </eprx-select>
                                        </div>
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'patientEthnicity'" [LabelText]="'Ethnicity'" [PlaceHolder]="''"
                                            [ControlName]="'PatientEthnicityId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                            [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                            [List]="vaccDrpDwnInfo?.PatientEthnicity" [show2ValInInputhidden]="true" [IsRequired]="true"
                                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGrpInvld">
                                            </eprx-select>
                                        </div>
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'comorbStatus'" [LabelText]="'Comorbidity Status'" [PlaceHolder]="''" 
                                            [ControlName]="'PatientComorbidityStatusId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true" [IsNgbTooltip]="true"
                                            [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                            [List]="vaccDrpDwnInfo?.PatientComorbidityStatus" [show2ValInInputhidden]="true" [IsTitle]="true">
                                            </eprx-select>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-4 margin_top_0_35rem">
                                                <eprx-input [LabelText]="'Mother\'s Maiden Name'" [PlaceHolder]="''" [ControlName]="'MaidenMiddleName'" [FormGroupName]="vaccImmuneFG"  [ValidPattern]="regex?.AlphaNumeric" [RxSelectId] = "'maidenMiddleName'" [MaxLength]="50" [ErrorDefs]="{pattern: 'Invalid!'}">
                                                </eprx-input>
                                            </div>
                                            <div class="col-4">
                                                <eprx-select [RxSelectId]="'patientconsent'" [LabelText]="'Patient Consent'" [PlaceHolder]="''" 
                                        [ControlName]="'PatientConsentId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                        [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Name'"
                                        [List]="vaccDrpDwnInfo?.PatientConsentDropDown">
                                        </eprx-select>
                                            </div> 
                                            <div class="col-4">
                                                <eprx-select [LabelText]="'Patient Guardian'" [PlaceHolder]="''" [ControlName]="'PatientRelationId'" [FormGroupName]="vaccImmuneFG"
                                                [HasMultiple]="false" [BindLabel]="'Code'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="''" [show2ValInInputhidden]="true"
                                                [Show2Values]="true" [ShowLabel2]="true" [List]="systemData?.RelationCat ? (systemData?.RelationCat | filterRelation:'Pick') : []">
                                            </eprx-select>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="row col-12">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-4 margin_top_0_35rem">
                                            <eprx-input [LabelText]="'Guardian First Name'" [PlaceHolder]="''" [ControlName]="'GuardienFirstName'" [FormGroupName]="vaccImmuneFG"  [ValidPattern]="regex?.AlphaNumeric" [RxSelectId] = "'guardianFirstNameId'" [MaxLength]="50" [ErrorDefs]="{pattern: 'Invalid!'}">
                                            </eprx-input>
                                        </div>
                                        <div class="col-4 margin_top_0_35rem">
                                            <eprx-input [LabelText]="'Guardian Last Name'" [PlaceHolder]="''" [ControlName]="'GuardienLastName'" [FormGroupName]="vaccImmuneFG"  [ValidPattern]="regex?.AlphaNumeric" [RxSelectId] = "'guardianLastNameId'" [MaxLength]="50" [ErrorDefs]="{pattern: 'Invalid!'}">
                                            </eprx-input>
                                        </div>
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'minorconsent'" [LabelText]="'Minor Consent'" [PlaceHolder]="''"  [IsDisabled]="disableMinorcsnt"
                                            [ControlName]="'MinorConsentId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                            [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Name'"
                                            [List]="vaccDrpDwnInfo?.PatientConsentDropDown">
                                            </eprx-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="exprx--common-block">
            <div class="box-default mb-2">
                <div class="eprx--block__content border_AllRadius transmit--block insurance--body">
                    <div class="row">
                        <h5 class="col-12 label_Color">Vaccination Details</h5>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trasmit--rx">
                            <div class="row col-12">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-4 seconday-search--grid common_global_search_max_content_display_for_reports document_queue line_height_1">
                                            <!-- <eprx-input [LabelText]="'Drug Name'" [HasControl]="false" [ReadOnly]="true" [InputValue]=" (rxInfo?.Drug?.drugname | uppercase) + ' ' +
                            (rxInfo?.Drug?.strength ? rxInfo?.Drug?.strength : ' ') + ' ' + rxInfo?.Drug?.drugformname"></eprx-input> -->
                                            <label>Drug</label>
                                            <app-searchbox-global id="DrugSer" [RxSelectId]="'DrugSer'" [SearchFor]="'Drug'"
                                            [DisableRadioButtons]="true" [IsAddNew]="false" [IsHide]="true" (SelectedDrug)="optedDrug($event)" [IsHideDrugFilters]="true" [SearchFrm]="'vaccnForm'"
                                            [ClearInputValue]="drugInputValue">
                                            </app-searchbox-global>
                                        </div>
                                        <div class="col-4 margin_top_0_35rem">
                                            <eprx-input [LabelText]="'NDC'" [HasControl]="false" [ReadOnly]="true" [InputValue]="this._formatsUtil.getNDCFormat(NDCScaned ? NDCScaned : rxInfo?.Drug?.drugcode)"></eprx-input>
                                        </div>
                                        <!-- <div class="col-4 margin_top_0_35rem">
                                            <eprx-input [LabelText]="'Drug Manufacturer'" [HasControl]="false" [ReadOnly]="true" [InputValue]=" rxInfo?.Drug?.manufacturername
                                            ? rxInfo?.Drug?.manufacturername
                                            : ''"></eprx-input>
                                        </div> -->
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'drgManf'" [LabelText]="'Drug Manufacturer'" [PlaceHolder]="''" 
                                            [ControlName]="'DrgManufactId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                            [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Description'" [BindLabel]="'Value'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                            [List]="vaccDrpDwnInfo?.ManufacturerDetails" [show2ValInInputhidden]="true" [IsTitle]="true" [IsRequired]="true"
                                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGrpInvld">
                                            </eprx-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-4">
                                            <eprx-select [LabelText]="'Billing Units'" [PlaceHolder]="'Billing Units'"
                                            [ControlName]="'drugBillingUnits'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false"
                                            [BindLabel]="'Remarks'" [BindLabel2]="'Name'" [BindValue]="'Id'" [LabelForId]="'Remarks'"
                                            [List]="BillingUnitDropDowns" [RxSelectId]="'MeasureUnitId'" [IsRequired]="true"
                                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGrpInvld"
                                            [Show2Values]="true" [show2ValInInputhidden]="true" [frmVaccnFrm]="true" (mmsSearchStart)="openMMSSearch()">
                                            </eprx-select>
                                            <!-- <span style="position: relative;
                                            top: -5rem;
                                            left: 8rem;"><i class="far fa-sync-alt color_cyan" title="Refresh Data from MMS"></i>
                                            </span> -->
                                            <!-- <i class="far fa-sync-alt" title="Refresh Data from MMS"></i> -->
                                        </div>
                                        <!-- <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1"> -->
                                                        <!-- <app-drug-refresh-mms [mmsType]="'NPI'" [prescriberId]="prescriberId" [TextNPI]="TextNPI"
                                                            [prescriberInfo]="prescriberInfo" [prescriberFG]="prescriberFG">
                                                        </app-drug-refresh-mms> -->
                                            <!-- <i class="far fa-sync-alt" title="Refresh Data from MMS"></i> -->
                                        <!-- </div> -->
                                        <div class="col-4 margin_top_0_35rem">
                                            <eprx-input [LabelText]="'Vaccine Group'" [HasControl]="false" [ReadOnly]="true" [InputValue]="drugInfo?.Drug?.VaccineGroupName
                                            ? drugInfo.Drug.VaccineGroupName
                                            : ''"></eprx-input>
                                        </div>
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'adminroute'" [LabelText]="'Admin Route'" [PlaceHolder]="''" 
                                            [ControlName]="'AdminRouteId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                            [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                            [List]="vaccDrpDwnInfo?.AdminRoute" [show2ValInInputhidden]="true" [IsTitle]="true">
                                            </eprx-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-12">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'adminsite'" [LabelText]="'Admin Site'" [PlaceHolder]="''" 
                                        [ControlName]="'AdminSiteId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                        [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                        [List]="vaccDrpDwnInfo?.AdminSite" [show2ValInInputhidden]="true" [IsTitle]="true">
                                        </eprx-select>
                                        </div>
                                        <div class="col-4">
                                            <eprx-select [LabelText]="'Administering Pharmacist'" [ControlName]="'UserId'"
                                                [FormGroupName]="vaccImmuneFG" [PlaceHolder]="''" [BindLabel]="'UserId'" [BindLabel2]="'Name'"
                                                [BindLabel3]="'Role'" [LabelTitle1]="'USER ID'" [LabelTitle2]="'NAME'"
                                                [LabelTitle3]="'ROLE'" [BindValue]="'AppUserId'" [LabelForId]="'AppUserId'"
                                                 [List]="appUsrData" [hasBindLabel3]="true" [IsFirstElem]="true" [IsRequired]="true"
                                                 [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGrpInvld"></eprx-select>
                                        </div>
                                        <div class="col-4 margin_top_0_35rem">
                                            <eprx-input [LabelText]="'Rph NPI'" [HasControl]="false" [ReadOnly]="true" [InputValue]="npi"></eprx-input>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-4">
                                                <eprx-select [RxSelectId]="'adminsuffix'" [LabelText]="'Administering Prov. Suffix'" [PlaceHolder]="''" 
                                                [ControlName]="'AdminProvSuffixId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [IsRequired]="true"
                                                [BindLabel]="'Name'" [BindValue]="'Id'" [ErrorDefs]="{required: 'Required'}"
                                                [List]="vaccDrpDwnInfo?.AdministeringProviderSuffix" [FormGroupInvalid]="formGrpInvld">
                                                </eprx-select>
                                            </div>
                                            <div class="col-4 margin_top_0_35rem">
                                                <eprx-input [LabelText]="'Dose Number'" [PlaceHolder]="'Enter Dose Number'" [ControlName]="'DoseNum'"
                                                [FormGroupName]="vaccImmuneFG" [InputType]="'NUMBER'" [DecimalValues]="0" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGrpInvld">
                                                </eprx-input>
                                            </div>
                                            <div class="col-4">
                                                <eprx-select [RxSelectId]="'vaccnrefusal'" [LabelText]="'Vaccination Refusal'" [PlaceHolder]="''" 
                                                [ControlName]="'VaccinationRefusalId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                                [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                                [List]="vaccDrpDwnInfo?.VaccinationRefusal" [show2ValInInputhidden]="true" [IsTitle]="true">
                                                </eprx-select>
                                            </div>
                                            <!-- <div class="col-6"> // handled from backend
                                                <eprx-select [RxSelectId]="'vaccAppt'" [LabelText]="'Missed Vaccination Appt'" [PlaceHolder]="''" 
                                        [ControlName]="'MissedVaccineApprId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true" [IsRequired]="true"
                                        [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'" [ErrorDefs]="{required: 'Required'}"
                                        [List]="vaccDrpDwnInfo?.MissedVaccinationAppoinment" [show2ValInInputhidden]="true" [IsTitle]="true" [FormGroupInvalid]="formGrpInvld">
                                        </eprx-select>
                                            </div> -->
                                        </div>
                                    </div>
                            </div>
                            <div class="row col-12">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-4 margin_top_0_35rem">
                                            <eprx-input [LabelText]="'CVX Code-Description'" [PlaceHolder]="''" [ControlName]="'CVXCodeDesc'"
                                            [FormGroupName]="vaccImmuneFG">
                                            </eprx-input>
                                        </div>
                                        <div class="col-4">
                                            <eprx-date-picker [RxSelectId]="'vispresented'" [LabelText]="'VIS Presented'"
                                            [PlaceHolder]="''" [ControlName]="'VISPresentDtTm'" [FormGroupName]="vaccImmuneFG"
                                            [MinDate]="" [MaxDate]="" [IsRequired]="true"
                                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGrpInvld">
                                            </eprx-date-picker>
                                            <div class="d-inline vaccine_tooltip" [ngbTooltip]="instructToolTip"  tooltipClass="vaccineInfo-tooltip hover--width"  triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top" >𝒊</div>
                                            <ng-template #instructToolTip>
                                            <div class="col-lg-12">
                                                 <div class="row text-left">
                                                    <p class="font-innertext-size_PharmAcc">
                                                        VIS is Vaccine Information Statement - The date that the vaccine is administered, and this date is submitted to the state registry.
                                                    </p>
                                                </div>
                                            </div>
                                            </ng-template>
                                        </div>
                                        <div class="col-4">
                                            <eprx-date-picker [RxSelectId]="'vispublished'" [LabelText]="'VIS Published'"
                                                [PlaceHolder]="''" [ControlName]="'VISPublishDtTm'" [FormGroupName]="vaccImmuneFG"
                                                [MinDate]="" [MaxDate]="" [IsRequired]="true"
                                                [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGrpInvld">
                                                </eprx-date-picker>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'vfcelig'" [LabelText]="'VFC Eligibility'" [PlaceHolder]="''" 
                                            [ControlName]="'VFCEligibilityId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                            [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                            [List]="vaccDrpDwnInfo?.VFCEligibility" [show2ValInInputhidden]="true" [IsTitle]="true" [IsRequired]="true"
                                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGrpInvld">
                                            </eprx-select>
                                            <div class="d-inline vaccine_tooltip" [ngbTooltip]="instructToolTip1"  tooltipClass="vaccineInfo-tooltip hover--width"  triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="top" >𝒊</div>
                                            <ng-template #instructToolTip1>
                                            <div class="col-lg-12">
                                                 <div class="row text-left">
                                                    <p class="font-innertext-size_PharmAcc">
                                                        Vaccine for Children Eligibility - This if a federal program for 19yrs and younger. So, age >19 then default V01 - Not VFC Eligible.
                                                    </p>
                                                </div>
                                            </div>
                                            </ng-template>
                                        </div>
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'prioritygrp'" [LabelText]="'Priority Group'" [PlaceHolder]="''" 
                                            [ControlName]="'PriorityGrpId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                            [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                            [List]="vaccDrpDwnInfo?.VaccinePriorityGroup" [show2ValInInputhidden]="true" [IsTitle]="true">
                                            </eprx-select>
                                        </div>
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'serlgyrslt'" [LabelText]="'Serology Result'" [PlaceHolder]="''" 
                                            [ControlName]="'SerologyResultsId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                            [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                            [List]="vaccDrpDwnInfo?.SerologyResults" [show2ValInInputhidden]="true" [IsTitle]="true">
                                            </eprx-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-12">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'vfcFundSrc'" [LabelText]="'Vaccine Funding Source'" [PlaceHolder]="''" 
                                            [ControlName]="'FundingSrcId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false" [HasBindLabel2]="true"
                                            [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Description'" [BindLabel]="'Value'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                            [List]="vaccDrpDwnInfo?.FundingSource" [show2ValInInputhidden]="true" [IsTitle]="true" [IsRequired]="true"
                                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGrpInvld">
                                            </eprx-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="exprx--common-block">
            <div class="box-default mb-2">
                <div class="eprx--block__content border_AllRadius transmit--block insurance--body">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trasmit--rx">
                            <div class="row col-12">
                                <div class="col-6">
                                    <h5 class="row col-6 label_Color pl-0">Billing</h5>
                                    <div class="row">
                                        <div class="col-4 margin_top_0_35rem">
                                            <eprx-input [LabelText]="'Incentive Amount'" [PlaceHolder]="''" [ControlName]="'Incentive'" [InputType]="'CURRENCY'" [DecimalValues]="2" [MaxLength]="9"
                                            [FormGroupName]="vaccImmuneFG" (TriggerSearchValue)="changeToNumber($event?.value, 'Incentive')" [MaskPattern]="'099999.00'" [DropSpclChar]="false" [RxSelectId]="'IncentiveAmount'">
                                            </eprx-input>
                                        </div>
                                        <div class="col-4">
                                            <eprx-select [LabelText]="'Submission Clarification'" [PlaceHolder]="''"
                                            [ControlName]="'SubClarifId'"
                                            [FormGroupName]="vaccImmuneFG" [List]="systemData?.subClarificationData" [BindLabel]="'Name'"
                                            [BindLabel2]="'Remarks'" [IsTitle]="true" [BindValue]="'Id'"
                                            [LabelForId]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                            [Show2Values]="true">
                                            </eprx-select>
                                        </div>
                                        <div class="col-4">
                                            <eprx-select [LabelText]="'Basis of Cost'" [RxSelectId]="'BasCost'" [PlaceHolder]="''" [List]="costBasisDrpDown ? costBasisDrpDown?.CostBasis : ''"
                                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Name'" [ControlName]="'CostBasisId'"
                                            [FormGroupName]="vaccImmuneFG" [Show2Values]="true">
                                            </eprx-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <h5 class="row col-6 label_Color pl-0">Completion</h5>
                                    <div class="row">
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'compltsts'" [LabelText]="'Completion Status'" [PlaceHolder]="''" 
                                    [ControlName]="'CompletionStatusId'" [FormGroupName]="vaccImmuneFG" [HasMultiple]="false"
                                    [BindLabel]="'Name'" [BindValue]="'Id'"
                                    [List]="vaccDrpDwnInfo?.CompletionStatus" [FormGroupInvalid]="formGrpInvld">
                                    </eprx-select>
                                        </div>
                                        <div class="col-4">
                                            <eprx-select [RxSelectId]="'drugLotID'" [LabelText]="'Drug Lot #'" [PlaceHolder]="'Drug Lot #'"
                                            [ControlName]="'drugLot'" [FormGroupName]="vaccImmuneFG" [BindValue]="'LotNum'"
                                            [List]="finalLotList" [BindLabel]="'LotNum'"  [LabelForId]="'drugLotIDImm'"  [IsRequired]="true"
                                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGrpInvld"
                                            (TriggerSelectValue)="enteredDrugLot($event, PRESCREFILL, 'LotNum')" (TriggerOnBlur)="enteredDrugLot($event, PRESCREFILL, 'LotNum')" (keydown.space)="drugLotRestriction($event)"
                                            [AddItem]="true" [SelectOnTab]="true" [ShowDefaultValue]="true" [InputValue]="vaccImmuneFG?.value['drugLot']"
                                            [addItemMaxLength]="30">
                                            </eprx-select>
                                        </div>
                                        <div class="col-4">
                                            <eprx-date-picker [RxSelectId]="'lotExpires'" [LabelText]="'Expiration Date'" [PlaceHolder]="''" [ControlName]="'lotExpiryDate'"(TriggerOnEnterValue)="enteredDrugLot($event, PRESCREFILL, 'LotExpiryDate')" [FormGroupName]="vaccImmuneFG"  [IsRequired]="true" [ErrorDefs]="{required: 'Required',minDate: 'Expired Date'}" [FormGroupInvalid]="formGrpInvld" [MinDate]="minDate" (TriggerOnDateSelect)="enteredDrugLot($event, PRESCREFILL, 'LotExpiryDate')">
                                            </eprx-date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="exprx--common-block">
            <div class="box-default mb-2">
                <div class="eprx--block__content border_AllRadius transmit--block insurance--body">
                    <app-dur [VaccineForm]="true" [rxno]="rxInfo?.Prescription?.Id"
                    [refId]="rxInfo?.PrescReFill?.Id"
                     [systemData]="systemData" [fromNewRx] = "true" [rxType] = "rxInfo?.rxType" [frmQukRef]="frmQuickRef" (emitFocus)="focusOnSave($event)">
                    </app-dur>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success"  id="SaveBtn" (click)="saveImmunizationForm()" appShortcutKey [AltKey]="'s'"><span>S</span>Save
                </button>
                <button class="hotkey_success" id="CancelBtn"  (click)="closeModal('cancel')" appShortcutKey [AltKey]="'c'"><span>C</span>Cancel
                </button>
            </div>
        </div>
    </div>
 </ng-template>


import { DrugData, MiscInfo, PaginationModel, SystemData } from "src/app/models";
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";
import { DrugUtils, WijimoUtil } from "src/app/utils";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { EditDrugService, AlertService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CollectionView } from "@grapecity/wijmo";
import * as moment from "moment";
import { mmsDataModel, drugMMSHeader } from "../../drug.constants";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { MsgConfig } from "src/app";
import { DrugStore } from "src/app/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-drug-refresh-mms",
    templateUrl: "./drug-refresh-mms.component.html"
})
export class DrugRefreshMmsComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    @Input() drugInfo: DrugData;
    @Input() systemData: SystemData;
    @Input() dropDownValue: any;
    @Input() drugId: number;
    @Input() drugFG: FormGroup;
    @Input() forVaccnForm: any;
    drugSearchDt: any;
    selectedDrugInfo: any;
    MMSPatchInfo: any;
    @Input()
    set drugDet(Data: any) {
        this.drugSearchDt = Data;
    }
    @Input() mmsSearchEDI: boolean;
    @Input()
    set MMSDataDPV(MMSInfo: any) {
        this.MMSPatchInfo = MMSInfo;
    }
    @ViewChild("searchData", { static: true })
    searchData: any;
    @Output() IsPopUpClosed = new EventEmitter<any>();
    @Output() DataforDrugManfact = new EventEmitter<any>();
    refreshFG: FormGroup;
    hideFilters: boolean;
    completeMMSData: any;
    model: any;
    modaltype: string;
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    selectedSizeMMSData: any;
    hasMMSValues: boolean;
    mmsWJItemS: CollectionView;
    scltdData: any;
    mmsObject: any;
    mmsDataGrid: wjcGrid.FlexGrid;
    refreshDataGrid: wjcGrid.FlexGrid;
    actvHeader = drugMMSHeader;
    wjHeaders: any;
    formGroupInvalid: boolean;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();
    constructor(private _drugTabUtil: DrugTabUtils, private _editDrugSvc: EditDrugService,
        private _alertSvc: AlertService, private _drugStore: DrugStore,
        private _drugUtil: DrugUtils, private _fb: FormBuilder, private _modalSvc: NgbModal, private _wijimoUtils: WijimoUtil, private _cdr:ChangeDetectorRef) {
        this.refreshFG = this._fb.group({
            DrugName: this.drugSearchDt ? this.drugSearchDt.DrugNm : null,
            NDCCode: this.drugSearchDt ? this.drugSearchDt.NDC : null,
            QuickCode: null
            });
    }

    ngOnInit() {
        if (!this.MMSPatchInfo) {
        this.patchValue();
        this.drugMMSSearchPopUp();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "drugMMSSearchList") {
                this.patchDefaultValHeaders();
            }
        });
        } else {
            this.selectMMSData(null, null, this.MMSPatchInfo);
        }
    }

    async drugMMSSearchPopUp() {
        if (this.mmsSearchEDI || this.forVaccnForm) {
            await this.getDrugMMS();
        } else {
            await this.getDrugMMS();
            this.formGroupInvalid = false
            this.model = this._modalSvc.open(this.searchData, { size: "lg", keyboard: false, backdrop: false, centered: true });
            this.modaltype = "MMSSearchType";
        } 
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("drugMMSSearchList");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("drugMMSSearchList", storedWJ);
    }

    async getDrugMMS(type?) {
        this.hideFilters = false;
        if ((this.refreshFG.controls['DrugName'].value && this.refreshFG.controls['DrugName'].value.length < 3)  ||
        (this.refreshFG.controls['NDCCode'].value && this.refreshFG.controls['NDCCode'].value.length < 3)) {
            // this._alertSvc.error("Enter minimum three characters to search");
            this._cdr.detectChanges();
            this.formGroupInvalid = true;
        } else  {
            this.completeMMSData = await this._editDrugSvc.getDrugMmsData(this.refreshFG.value).toPromise();
            if (this.completeMMSData && this.completeMMSData.length > 0) {
                if (this.forVaccnForm) {
                    this.IsPopUpClosed.emit(this.completeMMSData);
                } else {
                    if (this.mmsSearchEDI && !type) {
                        this.formGroupInvalid = false
                        this.model = this._modalSvc.open(this.searchData, { size: "lg", keyboard: false, backdrop: false, centered: true });
                        this.modaltype = "MMSSearchType";
                    }
                    this.isDataExists = true;
                    this.mmsPagination();
                }
            } else {
                if (this.forVaccnForm) {
                    this.IsPopUpClosed.emit(null);
                } else {
                    this.hasMMSValues = false;
                    this.isDataExists = false;
                    this.mmsWJItemS = null;
                    if (this.mmsSearchEDI && !type) {
                        this.drugFG.controls["Drug"].patchValue({DrugCode: this.drugSearchDt ? this.drugSearchDt.NDC : null, Name: this.drugSearchDt.DrugDesc ? this.drugSearchDt.DrugDesc : null, DrugUnitId: this.drugSearchDt.PckUnit ? this.drugSearchDt.PckUnit :null})
                        this.closeModal();
                    }
                }
            }
        }

    }

    async updateMMS() {
        const response = await this._editDrugSvc.updateDrugInfo(this.drugInfo).toPromise();
        if (response) {
            this._drugUtil.patchDrugInfo(this.drugInfo, this.drugFG);
            this._drugStore.storeDrugInfo(this.drugInfo);
            this._alertSvc.success(MsgConfig.UPDATE_SUCCESS);
            this.closeModal();
        } else {
            this._alertSvc.error(MsgConfig.UPDATE_FAIL);
        }
    }

    mmsPagination() {
        const startIndex = (this.pageNu * this.Size) - this.Size;
        const endIndex = (startIndex + this.Size);
        this.selectedSizeMMSData = this.completeMMSData.slice(startIndex, endIndex);
        if (this.selectedSizeMMSData.length) {
            this.hasMMSValues = true;
            this.mmsWJItemS = this._drugTabUtil.generateMMSWIJIMO(this.selectedSizeMMSData);
        } else {
            this.hasMMSValues = false;
        }
    }

    selectedDrug(isChecked: boolean, checkedVal: any) {
        if (isChecked) {
            // this.checkedPat = checkedVal && checkedVal["PatId"] ? checkedVal["PatId"] : null;
            this.selectedDrugInfo = checkedVal ? checkedVal : null;
        }
    }

    patchValue() {
       if(this.drugFG && this.drugFG.value && this.drugFG.value["Drug"]){
            this.refreshFG.patchValue({
                NDCCode: this.drugSearchDt ? this.drugSearchDt.NDC : this.drugFG.value["Drug"]["DrugCode"],
                DrugName: this.drugSearchDt ? this.drugSearchDt.DrugNm : this.drugFG.value["Drug"]["Name"]
            });
        }

    }

    async selectMMSData(val: any, type?: any, mmsInfo?:any) {
        this.scltdData = mmsInfo ? mmsInfo :this.selectedSizeMMSData[val];
        this.generateNewData();
        this.setValuetommsObject();
        this.mmsFunction();
        const drug = this.drugFG.value["Drug"];
        if(!this.getData(drug.ManufactId, this.dropDownValue.Organization, "Id", "Name")){
            this.DataforDrugManfact.emit(this.scltdData);
        }
        
    }

    mmsFunction() {
        if (!this.drugId) {
            this.mmsObject.map(value => {
                this.refreshMMSData(value, true);
            });
            this.closeModal();
        } else {
            this.hideFilters = true;
        }
    }

    resetMMSValues() {
        this.closeModal();
        this.mmsObject.map(value => {
            this.refreshMMSData(value, false);
        });

    }

    refreshMMSData(val: any, checked: any) {
        const cntrlNames = ["IsBranded", "DrugTypeId", "DrugFormId",
         "DrugUnitId", "UnitDoseIndId", "ManufactId", "UnitPriceCost", "UnitPriceAWP"];
         this.drugFG.controls["isFromMMS"].patchValue(true);
         const FG: any = this.drugFG.controls["Drug"];
        if (checked) {
            if (cntrlNames.indexOf(val["cntrlName"]) === -1) {
                console.log(val["cntrlName"], FG , "Hello");
                FG.controls[val["cntrlName"]].patchValue(val["MMsvalue"]);
            }
        } else {
            FG.controls[val["cntrlName"]].patchValue(val["currentvalue"]);
        }
        if (val["cntrlName"] === "IsBranded") {
            FG.controls[val["cntrlName"]].patchValue((checked ? val["MMsvalue"] : val["currentvalue"]) === "true" ? true : false);
        } else if (val["cntrlName"] === "DrugFormId") {
            FG.controls[val["cntrlName"]].patchValue(this.getData(checked ? val["MMsvalue"] : val["currentvalue"],
            this.dropDownValue.FDA_Form, "FormName", "FDAFormID"));
        } else if (val["cntrlName"] === "DrugUnitId") {
            FG.controls[val["cntrlName"]].patchValue(this.drugInfo["Drug"][val["cntrlName"]] = this.getData(checked ? val["MMsvalue"]
            : val["currentvalue"], this.systemData.DrugUnit, "Remarks", "Id"));
        } else if (val["cntrlName"] === "UnitDoseIndId") {
            FG.controls[val["cntrlName"]].patchValue(this.getData(checked ? val["MMsvalue"] : val["currentvalue"],
            this.systemData.UnitDoseIndication, "Remarks", "Id"));
        } else if (val["cntrlName"] === "ManufactId") {
            FG.controls[val["cntrlName"]].patchValue(this.getData(checked ? val["MMsvalue"] : val["currentvalue"],
            this.dropDownValue.Organization, "Name", "Id"));
        } else if (val["cntrlName"] === "DrugTypeId") {
            FG.controls[val["cntrlName"]].patchValue(this.getData(checked ? val["MMsvalue"] : val["currentvalue"],
            this.systemData.DrugType, "Name", "Id"));
        } else if (val["cntrlName"] === "UnitPriceCost" || val["cntrlName"] === "UnitPriceAWP" || val["cntrlName"] === "QtyPack") {
            FG.controls[val["cntrlName"]].patchValue((parseFloat(checked ? val["MMsvalue"] : val["currentvalue"])).toFixed(4));
            this.calculatePrice(val["cntrlName"], FG.value[val["cntrlName"]]);
        }

        // else if (val["cntrlName"] === "Strength") {
        //     this.splitStrength(checked ? val["MMsvalue"] : val["currentvalue"]);
        //  }
    }

    calculatePrice(cntrlName, value) {
        const FG: any = this.drugFG.controls["Drug"];
        if (cntrlName === "UnitPriceAWP") {
            FG.controls["UnitPriceAWP3Digits"].patchValue((parseFloat(value)).toFixed(4));
            FG.controls["AWPPack"].patchValue((value * FG.value["QtyPack"]).toFixed(2));
        } else if (cntrlName === "UnitPriceCost") {
            FG.controls["UnitPriceCost3Digits"].patchValue((parseFloat(value)).toFixed(4));
            FG.controls["CostPack"].patchValue((value * FG.value["QtyPack"]).toFixed(2));
        } else if (cntrlName === "QtyPack") {
            FG.controls["AWPPack"].patchValue((value * FG.value["UnitPriceAWP"]).toFixed(2));
            FG.controls["CostPack"].patchValue((value * FG.value["UnitPriceCost"]).toFixed(2));
        }
    }

    splitStrength(data) {
        const value = data;
        this.drugFG.controls["Drug"].patchValue({Strength : value ? value.replace(/\D+/g, "") : null});
        const unit = data ? data.replace(/\d+/g, "") : null;
        if (unit) {
            this.drugFG.controls["Drug"].patchValue({MeasureUnitId:
                this.getData(unit.split(/[/\.-]/g)[0], this.systemData.DrugUnit, "Remarks", "Id")});
        }
    }

    setValuetommsObject() {
        const mmshead = Object.keys(mmsDataModel);
        this.mmsObject.map((val, i) => {
            if (mmshead[i] !== "DrugClass") {
                Object.assign(val, {head: mmsDataModel[mmshead[i]], cntrlName: mmshead[i],
                link: this.checkDatamatch(val["currentvalue"], val["MMsvalue"]) ? mmsDataModel[mmshead[i]] : null});
            } else if (mmshead[i] === "DrugClass") {
                Object.assign(val, {head: mmsDataModel[mmshead[i]], cntrlName: mmshead[i],
                    link: (val["MMsvalue"] && val["currentvalue"] && val["currentvalue"] !== val["MMsvalue"])
                    ? mmsDataModel[mmshead[i]] : null});
            }
        });
        if (!this.drugId) {
            this.mmsObject.push({
                head: "NDC",
                currentvalue: this.drugInfo.Drug.DrugCode,
                MMsvalue: this.scltdData.DrugNDC,
                link: "Q",
                cntrlName: "DrugCode"
            });
        }
    }

    getData(data, dropDownData, checkCntrl, returnCntrl) {
        return (data && dropDownData.find(val => val[checkCntrl] === data)) ?
        dropDownData.find(val => val[checkCntrl] === data)[returnCntrl] : null;
    }

    checkDatamatch(data1, data2) {
        let result = false;
        if (data2) {
            result = data1 ? !(data1.toLowerCase().trim() === data2.toLowerCase().trim()) : true;
        }
        return result;
    }

    trimData(data, type?: string) {
        return data ? type && type === "strength" ? (data.trim()).substr(0, 14) : data.trim() : null;
    }

    clearFilters() {
        this.refreshFG.reset();
        this.getDrugMMS("search");
    }

    closeModal(val?) {
        if (!this.mmsSearchEDI) {
            this.clearFilters();
        }
        if (this.model) {
            this.model.close();
        }
        this._cdr.detectChanges();
        this.drugFG.markAsTouched();
        this.drugFG.markAsUntouched();
        this.drugFG.updateValueAndValidity();
        if (this.mmsSearchEDI) {
            this.IsPopUpClosed.emit(val && (val === "Cross" || val === "Cancel") ? "Cancel" : this.drugFG);
        } else {
            this.IsPopUpClosed.emit(null);
        }
        if (document.getElementById("QuickCode")) {
            document.getElementById("QuickCode").focus();
        }
    }
    selectedMMSByEnter(grid, event) {
        let ht: any;
        if (event.which === 13) {
            event.preventDefault();
        } else if (event.which !== 13) {
            ht = grid.hitTest(event);
        }
        if (grid["selectedItems"][0] && (!ht || ht.cellType === 1)) {
            event.preventDefault();
            this.selectMMSData(grid["selectedItems"][0]["Select"]);
        }
    }

    spaceEventOnWijimo(event, grid) {
        event.preventDefault();
        if (grid["selectedItems"][0]["link"]) {
            let checked = document.getElementById(grid["selectedItems"][0]["link"])["checked"];
            checked = !checked;
            this.refreshMMSData(grid["selectedItems"][0], checked);
        }
    }

    tabEventOnWijimo(event) {
        if (document.getElementById("updateMMSButton")) {
            event.preventDefault();
            document.getElementById("updateMMSButton").focus();
        }
    }

    updateMMSInit(flex: wjcGrid.FlexGrid) {
        flex.focus();
        this.refreshDataGrid = flex;
    }

    focusOnRefreshGrid(event) {
        if (this.refreshDataGrid) {
            event.preventDefault();
            this.refreshDataGrid.focus();
        }
    }

    closeMMSData() {
        this.hideFilters = false;
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.focus();
        this.mmsDataGrid = flex;
    }

    focusToMMsData(event) {
        if (this.hasMMSValues && this.mmsDataGrid) {
            event.preventDefault();
            this.mmsDataGrid.focus();
        }
    }

    cancelEventOnWijimo(event) {
        if (event.ctrlKey) {
            if (event.which === 67) {
                event.preventDefault();
                if (this.hideFilters) {
                } else if (!this.hideFilters) {
                    this.closeModal();
                }
            }
        }
    }

    setPage(page: number) {
        this.pageNu = page;
        this.mmsPagination();
    }
    setSize(size: any) {
        this.pageNu = this.paginationModel.pageNumber;
        this.Size = size;
        this.mmsPagination();
    }

    generateNewData() {
        const drug = this.drugFG.value["Drug"];
        const mms = this.scltdData;
        this.mmsObject = [
            {
                currentvalue: drug.Name,
                MMsvalue: this.trimData(mms.DrugName)
            },
            {
                currentvalue: this.getData(drug.DrugTypeId, this.systemData.DrugType, "Id", "Name"),
                MMsvalue: this.trimData(mms.DrugType)
            },
            {
                currentvalue: drug.IsBranded ? "true" : "false",
                MMsvalue: mms.Brand && mms.Brand.toLowerCase() === "y" ? "true" : "false"
            },
            {
                currentvalue: this.getData(drug.DrugFormId, this.dropDownValue.FDA_Form, "FDAFormID", "FormName"),
                MMsvalue: this.trimData(mms.DrugForm)
            },
            {
                currentvalue: drug.Strength,
                MMsvalue: this.trimData(mms.Strength, "strength")
            },
            {
                currentvalue: this.getData(drug.DrugUnitId, this.systemData.DrugUnit, "Id", "Remarks"),
                MMsvalue: this.trimData(mms.unitofMeasure)
            },
            {
                currentvalue: this.getData(drug.UnitDoseIndId, this.systemData.UnitDoseIndication, "Id", "Remarks"),
                MMsvalue: this.trimData(mms.unitdoesofInd)
            },
            {
                currentvalue: this.getData(drug.ManufactId, this.dropDownValue.Organization, "Id", "Name"),
                MMsvalue: mms.Madeby
            },
            {
                currentvalue: drug.QtyPack,
                MMsvalue: this.trimData(mms.Quanitypack)
            },
            {
                currentvalue: this.trimData(drug.BrandName),
                MMsvalue: this.trimData(mms.BrandName)
            },
            {
                currentvalue: this.trimData(drug.GenericName),
                MMsvalue: this.trimData(mms.GenericName)
            },
            {
                currentvalue: this.trimData(drug.UPC),
                MMsvalue: this.trimData(mms.upc)
            },
            {
                currentvalue: drug.UnitPriceCost,
                MMsvalue: this.trimData(mms.CostPrice) ? mms.CostPrice : "0.0000"
            },
            {
                currentvalue: drug.UnitPriceAWP,
                MMsvalue: this.trimData(mms.UnitPrice) ? mms.UnitPrice : "0.0000"
            },
            {
                currentvalue: drug.DirectUnitPrice,
                MMsvalue: this.trimData(mms.DirPrice) ? mms.DirPrice : "0.0000"
            },
            {
                currentvalue: drug.DrugClass,
                MMsvalue: Number(this.trimData(mms.DrugClass))
            },
            {
                currentvalue: drug.DiscontDt ? moment(drug.DiscontDt).format("MM/DD/YYYY") : null,
                MMsvalue: mms.discontDate ? moment(mms.discontDate).format("MM/DD/YYYY") : null
            },
            {
                currentvalue: drug.Therapeutic ? drug.Therapeutic.toString() : null,
                MMsvalue: this.trimData(mms.Therapeutic)
            },
            {
                currentvalue: drug.TherapeuticCode ? drug.TherapeuticCode.toString() : null,
                MMsvalue: this.trimData(mms.TherapeuticCode)
            },
            {
                currentvalue: drug.WarningCode,
                MMsvalue: this.trimData(mms.Warning)
            },
            {
                currentvalue: drug.DrugFullName,
                MMsvalue: this.trimData(mms.DrugNameLong)
            },
            {
                currentvalue: drug.DrugLabelName,
                MMsvalue: this.trimData(mms.DrugNameShort)
            },
            // {
            //     currentvalue: drug.IsTriplicate,
            //     MMsvalue: mms.IsTriplicate
            // }
        ];
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}

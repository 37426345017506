import { DateTime } from "@grapecity/wijmo";

export class DailyLog {
    FromDate: Date = null;
    ToDate: Date = null;
    Facility: string = null;
    PatientCategory: string = null;
    PrintCompoundIngredients: Boolean = null;
    OnlyFacilityPatients: Boolean = null;
    PrintControlReport: Boolean = null;
    RefillsOnly: Boolean = null;
    PrintRxSig: Boolean = null;
    SortBy: string = null;
    DocumentType: string = null;
    BillType: string = null;
    billSearch: any = null;
    IsExport: boolean = false;
    DisplayTotalRxAmount: Boolean = null;
    IsLandscape: boolean = false;
}
export class AuditTrail {

DrugId: number = null;
DrugName: string = null;
DrugNdc: number = null;
}
export class RefillExpiration {
    FromDate: Date = null;
    ToDate: Date = null;
    PrescriberId: number = null;
    InsuranceType: any = null;
    FacilityId: number = null;
    ShowMaintenancedrugs: Boolean = null;
    ShowExpiredRx: Boolean = null;
    IsCurrentRxOnlyShown: Boolean = null;
    SortBy: string = null;
    drugClass: any = null;
    BillType: string = null;
    billSearch: any = null;
    IsExport: boolean = null;
    RxRefillReminder: string = null;
    PatientRefillReminder: string = null;
    drugType: any = null;
    PatientCategory: any = null;
    ExcludeZerothRefill: Boolean = null;
    DrugId: any = null;
    ShowExpired: any = null;
    IsLandscape: boolean = null;
}
export class ProfitRevenue {
    FromDate: Date = null;
    ToDate: Date = null;
    BrandCost: number = null;
    UseCostPriceAvailable: Boolean = null;
    GenericCost: number = null;
    InsuPlanCode: string = null;
    FacilityCode: string = null;
    PatientCategory: string = null;
    DrugTypeId: any = null;
    DrugBrand: string = null;
    Groupbyparam: string = null;
    BillType: string = null;
    billSearch: any = null;
    IsExport: boolean = null;
    IsLandscape: boolean = null;
}
export class DispenseReport {
    FromDate: Date = null;
    ToDate: Date = null;
    DrugId: any = null;
    InsuranceId: any = null;
    PatientType: any = null;
    FacilityId: any = null;
    PatientCategory: any = null;
    IncludeUnbilledRx: any = null;
    IncludeFiledRx: any = null;
    CompoundDrug: any = null;
    Include340B: boolean = null;
    PayorId: any = null;
    SortField: string = null;
    ShowPrice: boolean = null;
    ManageInventory: boolean = null;
    ShowPatientAddress: boolean = null;
    ShowPrescriberName: boolean = null;
    ShowInsurancePaid: boolean = null;
    ShowPatientPhone: boolean = null;
    ShowPrescriberAddress: boolean = null;
    ShowInsuranceCode: boolean = null;
    ShowDrugCost: boolean = null;
    ShowPrescriberPhone: boolean = null;
    ShowOrderedQuantity: boolean = null;
    Summary: boolean = null;
    D340B: any = null;
    DCom: any = null;
    PrintCompoundIngredients: any = null;
    ShowManufacturer: boolean = null;
    BillType: string = null;
    billSearch: any = null;
    BucketId: number = null;
    Only340B: boolean = null;
    ShowMaintenanceDrug: boolean = null;
    Payor: any = null;
    PayorIDs: any = null;
    Brand: any = null;
    TopNvalue: any = null;
    PrescriberId: number = null;
    TherapeuticConceptId: any =null;
    Drugclass: any = null;
    IsLandscape: boolean = null;
}

export class RefillDue {
    refillsDueFromDate: Date = null;
    refillsDueToDate: Date = null;
    InsuranceType: any = null;
  //  ExcludeFacilityPatients: boolean = null;
    IncludeFacilityPatients: boolean = null;
    ShowMaintenanceDrugsOnly: boolean = null;
    ShowExpiredRxOnly: boolean = null;
    sortBy: string = null;
    drugType: any = null;
    drugClass: any = null;
    DocumentType: string = null;
    RefillFillDate: any = null;
    IncludeFiledRxs: any = null;
    ShowExpired: any = null;
    RxRefillReminder: string = null;
    PatientRefillReminder: string = null;
    ShowExpiring: any = null;
    BasedOnEquiDrugs: any = null;
    Profitfrom: any = null;
    Profitto: any = null;
    PatientCategory: any = null;
    PrescriberId: any = null;
    SelectedInsList: any = null;
    ExcludeInsuranceType : any[] = null;
    PickupDate: any = null;
    ExcludeRefillRemainder: any = null;
    BillType: string = null;
    billSearch: any = null;
    AllForSinceDate: any = null;
    AllForFromDueDate: any = null;
    FromDueDateDays: number = null;
    SinceDateDays: number = null;
    ToDueDateDays: number = null;
    AllForToDueDate: any = null;
    ExcludeEarlyForRefill: any = null;
    ExcludeExpiredByTimeLimit: any = null;
    ExcludeTaggedRxs: any = null;
    IncludeFiledRx: any = null;
    FiledRxs: any = null;
    PrintRxPrice: any = null;
    zipCodes: any = null;
    ShowCompactReport: any = null;
    ShowIns: any = null;
    ShowProfit: any = null;
    IsExport: boolean = null;
    IsPartialFill: boolean = null;
    PrescriberName: string = null;
    BillStatusAllSelected = false;
    ConsumptionThreshold: any = null;
    IsLandscape: boolean = false;
}
export class DrugDispense {
    FromDate: Date = null;
    ToDate: Date = null;
    Facility: string = null;
    PatientCategory: string = null;
    PrintCompoundIngredients: Boolean = null;
    PatientType: Boolean = null;
    IncludeUnbilledRx: Boolean = null;
    Payor: Boolean = null;
    ShowPrice: Boolean = null;
    SortBy: string = null;
    DocumentType: string = null;
    BillType: string = null;
    billSearch: any = null;
    IsExport: boolean = null;
    IsLandscape: boolean = null;
}
export class ControlRx {
    FromDate: Date = null;
    ToDate: Date = null;
    DrugClasses = [];
    Prescriber: number = null;
    ReportType: string = null;
    SortBy: string = null;
    Status: number = null;
    IsExport: boolean = null;
    PrintPickUpDate: boolean = null;
    IsLandscape: boolean = null;
}

export class InventoryReconciliationReport {
    LastPhyInvFromDt: Date = null;
    LastPhyInvToDt: Date = null;
    BucketId: string = null;
    SearchBy: string = null;
    Drug: any = [];
    Drugclass: any = null;
    IsExport: boolean = false;
    PageNumber: number = 1;
    PageSize: number = 30;
    IncludeCompounds: boolean = true;
    IsIngrientLevel: boolean = false;
    ReportingLevel: string = null;
    IsLandscape: boolean = false;
}

export class ERxReport {
    FromDate: Date = null;
    ToDate: Date = null;
    ErxTransTypes: any = [];
    ErxStatusId: any = null;
    Patient: string = null;
    Prescriber: string = null;
    Drug: string = null;
    SortField: string = null;
    IsExport: any = null;
}

export class Inventory {
     DrugId: number = null ;
     LabelerCode: string = null ;
     SortBy: string = null;
     InventoryStatus: number = null;
     Drugclass: any = null;
     Drugtype: number = null ;
     BucketId: string = null;
     DrugBrand: string  = null ;
     ShowEquivalentDrugs: boolean = null ;
     GroupByEquivalentDrug: boolean = null ;
     Therapeutic: number = null ;
    TherapeuticCode: number = null;
    IsExport: boolean = null;
    IsLandscape: boolean = null;
}
export class FiledRx {
    IncludeDueDate: boolean = null;
    FromDueDate: Date = null;
    ToDueDate: Date = null;
    IncludeFillDate: boolean = null;
    FromFillDate: Date = null;
    ToFillDate: Date = null;
    FacilityId: number = null;
    PatCategory: string = null;
    ExcludeTaggedRxs: boolean = null;
    OnlyFacPatients: boolean = null;
    ExcludeExpiredRx: boolean = null;
    IsExport: boolean = null;
    IsLandscape: boolean = null;
}

export class AnalyseRxAudit {
    FromDate: Date = null;
    ToDate: Date = null;
    FromFillDate: Date = null;
    ToFillDate: Date = null;
    PharmacistId: number = null;
    TechnicianId: number = null;
    RxOriginId: number = null;
    OperationType: string = null;
    SortField: string = null;
    RxFieldname: string = null;
    DrugClass: string = null;
    Pagenumber: number = null;
    Pagesize: number = null;
    RxCreatedBy: number = null;
    RxUpdatedBy: number = null;
    includeDateFill: boolean = null;
    includeDateChange: boolean = null;
    selectionType: boolean = null;
}

export class RxByPrescriber {
    FromDate: Date = null;
    ToDate: Date = null;
    PrescriberId: number = null;
    Insurance: string = null;
    Facility: string = null;
    DrugId: number = null;
    Speciality: number = null;
    PatCategory: string = null;
    SpecialityD: number = null;
    BillType: string = null;
    billSearch: any = null;
    IsLandscape: boolean = null;
}

export class SummaryReports {
    FromDate: Date = null;
    ToDate: Date = null;
    InsuranceType: string = null;
    SortBy: string = null;
    BillType: string = null;
    billSearch: any = null;
    IsExport: boolean = false;
    IsLandscape: boolean = null;
}

export class UnbilledRx {
    FromDate: Date = null;
    ToDate: Date = null;
    CheckStatusBy: string = null;
    InsuranceId: number = null;
    ReportType: string = null;
    IsExport: boolean = null;
    IsLandscape: boolean = false;
}

export class RxByPharmacist {
    FromDate: Date = null;
    ToDate: Date = null;
    ReportType: string = null;
    SortBy: string = null;
    PharmacistId: number = null;
    TechnicianId: number = null;
    DrugId: number = null;
    PatCategory: string = null;
    BillType: string = null;
    billSearch: any = null;
    IsExport: boolean = null;
    IsLandscape: boolean = null;
}

export class RxsNotPickUp {
    Fromdate: Date = null;
    Todate: Date = null;
    InsuranceId: number = null;
    SortBy: string = null;
    DrugId: number = null;
    CategoryName: string = null;
    BillType: string = null;
    billSearch: any = null;
    IsExport: boolean = null;
    FacilityId: number = null;
    ShowProfit: any = null;
    ExcludeRxForFacilityPatients: boolean = null;
    ExcRxForFacPat: any[];
    IsLandscape: boolean = null;
    
}

export class PrivAckReports {
    Patientfromdate: Date = null;
    Patienttodate: Date = null;
    Insurance: number = null;
    Acknowledgementstatus: boolean = null;
}

export class TransferredRx {
    Fromdate: Date = null;
    Todate: Date = null;
    TransferStatus: string = null;
    PatientId: number = null;
    PrescriberId: number = null;
    DrugClass: number = null;
    PharmacyId: number = null;
    BillType: string = null;
    billSearch: any = null;
    IsExport: boolean = null;
    IsLandscape: boolean = null;
}
export class RxPickupLogReports {
    IsPickedupLog: boolean = null;
    FromDate: Date = null;
    ToDate: Date = null;
    CounsellingStatus: boolean = null;
    Insutype: string = null;
    PrescriberId: number = null;
    Drugclass: any[] = [1];
}
export class SignatureClaimsLog {
    Fromdate: Date = null;
    Todate: Date = null;
    InsuranceId: number = null;
    PatientId: number = null;
}
export const RxPickupDrugDropdowns = [{
    Id: 1,
    Name: "All"
}, {
    Id: 7,
    Name: "Exclude Controls"
}, {
    Id: 2,
    Name: "Class 2"
}, {
    Id: 3,
    Name: "Class 3"
}, {
    Id: 4,
    Name: "Class 4"
}, {
    Id: 5,
    Name: "Class 5"
}, {
    Id: 6,
    Name: "Control Drugs Only"
}];


export class RxsByInsuranceInputParams {
    StartFillDate: Date = null;
    EndFillDate: Date = null;
    IncludeCash = false;
    PayStatus = 0;
    BillStatus: any = null;
    IncludeMedcaidRxs = false;
    ForRxAmount = 0;
    OnlyRxsInsurancesPaidZero = false;
    ShowDrugCost = false;
    ShowPatientCopay = false;
    // ShowDIRfee = false;
    OrderBy: any = null;
    ReportType: any = null;
    IncludeInsIDs: any[] = [];
    ExcludeInsIDs: any[] = [];
    FacilityCode = null;
    PatientCategory = null;
    PayorIDs: any[] = [];
    IsExport: boolean = null;
    IsLandscape: boolean = null;
}

export class PartialFillReport {
    FromFillDate: DateTime = null;
    ToFillDate: DateTime = null;
    Insurance: string = null;
    DrugId: number = null;
    SortBy: Boolean = null;
    FillOrCummulative: Boolean = null;
    DetailOrSummary: Boolean = null;
    BillType: string = null;
    billSearch: any = null;
    FillFilter: string = "ALL";
    IsExport: Boolean = null;
    IsLandscape: boolean = null;
}

export class IOUReport {
    FromFillDate: DateTime = null;
    ToFillDate: DateTime = null;
    PatientId: number = null;
    DrugId: number = null;
    SortBy: Boolean = null;
    DetailOrSummary: Boolean = null;
    ShowRxsWithOweQty: Boolean = null;
    IncludeEquivalents: Boolean = null;
    IsLandscape: boolean = null;
}

export class ReportWriter {
    reportWriterElements: ReportWriterElements = null;
    reportWriterFilters: ReportWriterFilters = null;
}
export class ReportWriterElements {
    elementId: number = null;
    elementName: string = null;
    entityId: number = null;
    isDropped: boolean = null;
}
export class ReportWriterFilters {
    filterId : number =null;
    filterName: string = null;
}

export class cRReports {
    reportId: number = null;
    reportName: string = null;
    reportTypeId: number = null;
    reportFor: string = null;
    reportTitle: string = null;
    isExcludeLogo: boolean = null;
    createdBy: number = null;
    creationDate: DateTime = null;
    lastModBy: number = null;
    lastModDate: DateTime = null;
    footer: string = null;
    tenantId: number = null;
    disclaimer: string = null;
    // groupByHeaderId: number = null;
}


export class RemittanceReportInputParams {
    FromDate: Date = null;
    ToDate: Date = null;
    PayStatus = 0;
    InsCode: any = null;
    FacilityId = null;
    PayorIDs: any[] = [];
    IsExport: boolean = false;
    DefaultGroupBy: number = 0;
    IsLandscape: boolean = false;
    pageOrientation: boolean = true;
    FromAppliedDate: Date = null;
    ToAppliedDate: Date = null;
    IncludeAppliedDate: boolean = null;
    IncludeFillDate: boolean = null;
    Elements: any[] = [];
}

export class NotesReportInputParams {
    FromDate: Date = null;
    ToDate: Date = null;
    Category =  "ALL";
    UserType = 1;
    SortBy : number = 1;
    UserId = null;
    ReportTypeId : number = 1;
    IsLandscape: boolean = null;
}

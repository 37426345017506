import { Injectable } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { RxUtils } from "../utils";
import { CommonService } from "./common.service";

export let browserRefresh = false;
@Injectable({
    providedIn: "root"
})
export class PreviousRouteService {
    previousUrl: string;
    currentUrl: string;
    subscription: Subscription;

    previousUrls: string[] = [];
    history: any;

    constructor(private router: Router, private _commonServ: CommonService) {
        this.currentUrl = this.router?.url;
        router?.events?.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (!event?.url?.includes("/eprx/rxnav") && !event?.url?.includes("/eprx/commonNav")) {
                    this.previousUrl = this.currentUrl;
                    if (this.previousUrls[this.previousUrls.length - 1] !== event.url ) {
                        if (this.previousUrl !== event.url) {
                            this.previousUrls.push(this.currentUrl);
                        }
                    } else if (this.previousUrls[this.previousUrls.length - 1] !== this.previousUrl) {
                        this.previousUrls.pop();
                    }
                    this.currentUrl = event.url;
                }
            }
        });

        this.subscription = router?.events?.subscribe((event) => {
            if (event instanceof NavigationStart) {
              browserRefresh = !router.navigated;
            }
          });
    }

    public getPreviousUrl() {
        return this.previousUrl;
    }

    public routeBackToPrevScreen(FmRx?: string) {
        // if (this.isRXInProgress() && !FmRx) {
        //     this.routeToRxFromOtherScreens();
        // } else {
        // this.checkAndBackToRx();
            const urlToNavigate = this.previousUrls[this.previousUrls.length - 1];
            const actvRoute = urlToNavigate.split("?fm=")[0];
        const actvQryPrms = urlToNavigate.split("?fm=")[1];
        if (!actvRoute.includes("/eprx/rx")) {
            if (actvQryPrms) {
                this.checkForInprogressRx(actvRoute);
                this.router.navigate([actvRoute], {
                    queryParams: {
                        fm: actvQryPrms
                    }
                });
            } else {
                if (actvRoute && actvRoute.includes("Reports/customReportPreview")) {
                    const actvRprtRoute = urlToNavigate.split("?")[0];
                    const actvRprtQryPrms = urlToNavigate.split("?")[1];
                    if (actvRprtQryPrms) {
                        const paramsList = actvRprtQryPrms.split(/=/g);
                        if (paramsList?.length) {
                            const reportIdSplit = paramsList[1].split("&")[0];
                            this.router.navigate([actvRprtRoute], {
                                queryParams: { reportId: reportIdSplit, reportNm: paramsList[2].includes("%20") ? paramsList[2].replace(/%20/g, " ") : paramsList[2]}
                            });
                        } else {
                            this.router.navigate(['/eprx/Reports/ReportWriter']);
                        }
                    }
                } else {
                    this.router.navigate([actvRoute.split("?")[0]]);
                }
            }
        } else {
            this.previousUrls.pop();
            this.routeBackToPrevScreen();
        }
    }


    routeToRxFromOtherScreens() {
        const routeFrom = sessionStorage.getItem("toEdit");
        if (
            routeFrom === "nr" ||
            routeFrom === "er" ||
            routeFrom === "rf" ||
            routeFrom === "nrf" ||
            routeFrom === "brx" ||
            routeFrom === "rnr"
        ) {
            if (localStorage.getItem("InProgress")) {
                const inProgRxs = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("InProgress")));
                if (inProgRxs.length > 0) {
                    const rx = inProgRxs.pop();
                    this._commonServ.storePatientIdinBS(rx.value.Patient.patientid);
                    this._commonServ.storeRxIdinBS(rx.RxID);
                    localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rx.value)));
                    if (inProgRxs && inProgRxs.length > 0) {
                        localStorage.setItem("InProgress",  this._commonServ.encryptData(JSON.stringify(inProgRxs)));
                    } else {
                        localStorage.setItem("InProgress",  this._commonServ.encryptData(JSON.stringify([])));
                    }
                    // sessionStorage.removeItem("toEdit");
                    this._commonServ._showInProgressRx$.next(true);
                    this.router.navigate(["eprx/rx"], {
                        queryParams: { fm: routeFrom }});
                }
            }
        }
    }

    public getIsBroswerRefreshed() {
        return browserRefresh;
    }

    checkForInprogressRx(routeToNavigate) {
        if (routeToNavigate.includes("eprx/rx") &&  this.isRXInProgress()) {
            this.restoreInprogressRx();
        }
    }

    checkAndBackToRx() {

    }

    isRXInProgress() {
        const routeFrom = sessionStorage.getItem("toEdit");
        if(routeFrom) {
            return true;
        } else {
            return false;
        }
    }

    restoreInprogressRx() {
        const routeFrom = sessionStorage.getItem("toEdit");
        if (localStorage.getItem("InProgress")) {
            const inProgRxs = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("InProgress")));
            if (inProgRxs.length > 0) {
                const rx = inProgRxs.pop();
                this._commonServ.storePatientIdinBS(rx.value.Patient.patientid);
                this._commonServ.storeRxIdinBS(rx.RxID);
                localStorage.setItem("RxInfo", this._commonServ.encryptData(JSON.stringify(rx.value)));
                if (inProgRxs && inProgRxs.length > 0) {
                    localStorage.setItem("InProgress",  this._commonServ.encryptData(JSON.stringify(inProgRxs)));
                } else {
                    localStorage.setItem("InProgress",  this._commonServ.encryptData(JSON.stringify([])));
                }
                // sessionStorage.removeItem("toEdit");
            }
        }
    }

    popPreviousUrls(urlToPop) {
        let lastIndex = -1;
        this.previousUrls.forEach((singUrl, index) => {
            if (singUrl.includes(urlToPop)) {
                lastIndex = index;
            }
        });
        if (lastIndex > -1) {
            this.previousUrls = this.previousUrls.splice(0, lastIndex + 1);
        }
    }

    loadRouting(): void {
        const url = this.router?.url;
        if (
            !url.includes("/eprx/rxnav") &&
            !url.includes("/eprx/commonNav")
        ) {
            if (this.history.indexOf(url) === -1) {
                this.history = [...this.history, this.router?.url];
            }
        }
    }

    getHistory(): string[] {
        return this.history;
    }

    getPreviousUrlForRoutng(): string {
        return this.history[this.history.length - 2] || "/dashboard";
    }

    pushInitialRouting(url): void {
        this.history = [...this.history, url];
    }
}

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, OnDestroy, Injectable, OnChanges, SimpleChanges, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
// import { RegEx, MaskPatterns } from 'src/app';
import { ValidateFormFieldsUtils } from 'src/app/utils';
import { NgbDateAdapter, NgbDateStruct, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatPipe } from 'src/app/pipes';
import * as moment from "moment";
import { CommonStore } from '../../../store';
import { Router } from "@angular/router";
import { debounce, debounceTime } from 'rxjs/operators';
/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

    readonly DELIMITER = '/';

    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            value = value + "";
            let date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[1], 10),
                month: parseInt(date[0], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {
        return date ? (date.month + "").padStart(2, '0') + this.DELIMITER + (date.day + "").padStart(2, '0') + this.DELIMITER + date.year : null;
    }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[1], 10),
                month: parseInt(date[0], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? (date.month + "").padStart(2, '0') + this.DELIMITER + (date.day + "").padStart(2, '0') + this.DELIMITER + date.year : '';
    }
}

@Component({
    selector: 'eprx-date-picker',
    templateUrl: './date-picker-ref.component.html',
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
})
export class DatePickerRefComponent implements OnInit, AfterViewInit, OnDestroy {

    labelText = "";
    inputFormGroup: FormGroup;
    custContrlName: string;
    placeHolder = "";
    errorDefs: any;
    titleText: string;
    isRequired: boolean;
    isReadOnly = false;
    hasControl = true;
    tabIndex: number;
    autoFocus: boolean;
    errorMsg: string;
    helpText: any;
    maskPattern: any;
    dateValueModel: string;
    maxDate: NgbDateStruct;
    minDate: NgbDateStruct;
    datePickerPipe = new DateFormatPipe();
    buttonTabIndex: number = -1;
    isDisabled: boolean;
    rxID: any;
    isFirstElem: any;
    inputErrors: any;
    dateParser=new CustomDateParserFormatter();
    twoKeysClick = 0;
    FromOrdDate: boolean;
    errorMessageNotDisplayed = ['Order to fill date exceed Maximum Allowed of','Filled Date cannot be less than Ordered Date.','Expiry date cannot be current date or previous date.'];
    currentDate: string;
    @ViewChild('d2') datepicker: NgbInputDatepicker;

    @Input()
    set LabelText(lt: string) {
        this.labelText = lt;
    }

    @Input()
    set Title(title: string) {
        this.titleText = title;
    }

    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }

    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }

    @Input()
    set PlaceHolder(ph: string) {
        this.placeHolder = ph;
    }

    @Input()
    set ErrorDefs(ed: any) {
        this.errorDefs = ed;
    }

    @Input()
    set IsRequired(ir: boolean) {
        this.isRequired = ir;
        if (ir) {
            if(this.inputFormGroup && this.inputFormGroup.controls)
            {
            this.inputFormGroup.controls[this.custContrlName].setValidators([Validators.required]);
            this.inputFormGroup.controls[this.custContrlName].updateValueAndValidity();
            }
        }
    }

    @Input()
    set MaskPattern(mp: string) {
        // this.maskPattern = MaskPatterns[mp.toUpperCase()];
    }

    @Input()
    set ReadOnly(mp: boolean) {
        this.isReadOnly = mp;
    }

    @Input()
    set HasControl(hc: boolean) {
        this.hasControl = hc;
    }

    @Input()
    set TabIndex(ti: number) {
        this.tabIndex = ti;
    }

    @Input()
    set RxSelectId(id: any) {
        this.rxID = id;
    }

    @Input()
    set AutoFocus(ti: boolean) {
        this.autoFocus = ti;
    }

    @Input()
    set HelpText(ti: any) {
        this.helpText = ti;
    }
    @Input() isFromErx: boolean;

    @Input()
    set MarkAsTouched(mt: boolean) {
        if (mt) {
            this.validateField();
        } else {
            this.errorMsg = null;
        }
    }
    @Input()
    set fromOrdDate(val: boolean) {
        if (val) {
            this.FromOrdDate = true;
            this.validateField();
        } else {
            this.errorMsg = null;
        }
    }
    @Input()
    set FormGroupInvalid(hc: boolean) {
        if (hc) {
            this.validateField();
        } else {
            this.errorMsg = null;
        }
    }

    @Input()
    set InputValue(inpVal: any) {
        this.dateValueModel = inpVal;
    }

    @Input()
    set MaxDate(inpVal: any) {
        this.maxDate = inpVal;
    }

    @Input()
    set MinDate(inpVal: any) {
        this.minDate = inpVal;
    }

    @Input()
    set MaxDateString(inpVal: any) {
        this.maxDate = this.dateParser.parse(inpVal);
    }

    @Input()
    set MinDateString(inpVal: any) {
        this.minDate = this.dateParser.parse(inpVal);
    }

    @Input()
    set ButtonTabIndex(ti: number) {
        this.buttonTabIndex = ti;
    }

    @Input()
    set IsDisabled(id: boolean) {
        this.isDisabled = id;
    }

    @Input()
    set IsFirstElem(value: any) {
        this.isFirstElem = value;
    }

    @Input()
    set InputErrors(ie: any) {
        this.inputErrors = ie;
    }
    @Input()
    set MarkValidation(val: any) {
        if (val) {
            this.checkErrorsForDate(this.inputFormGroup.value[this.custContrlName]);
        }
    }
    @Input() cssStyle: boolean;
    @Input() IsAppendTo: boolean;
    @Input() isWholeEventRequired: boolean;

    @Output()
    EmitChangeValue = new EventEmitter();

    @Output()
    EmitBlurValue = new EventEmitter();

    @Output()
    TriggerOnDateSelect = new EventEmitter();

    @Output()
    TriggerOnTabbing = new EventEmitter<any>();

    @Output()
    TriggerOnShiftTabbing = new EventEmitter<any>();



    constructor(private _cdr: ChangeDetectorRef, private _validFormFieldsUtils: ValidateFormFieldsUtils,
        private _commonStore: CommonStore, private _router: Router, private renderer: Renderer2) {
        this.minDate = this.dateParser.parse(moment().subtract(125, 'year').format("MM/DD/YYYY"));
    }

    ngOnInit(): void {
        if (this.hasControl) {
            this.currentDate = moment(new Date()).format('MM/DD/YYYY');
            this.dateValueModel = this.datePickerPipe.transform(this.inputFormGroup.controls[this.custContrlName].value);
            this.inputFormGroup.controls[this.custContrlName].valueChanges.subscribe(date => {
                if (date && date.length > 16 &&  moment(date).isValid()) {
                    this.dateValueModel = moment(date).format("MM/DD/YYYY");
                } else {
                    this.dateValueModel = date;
                }
                if (this.custContrlName && this.inputFormGroup && this.inputFormGroup.controls[this.custContrlName]) {
                    this.inputFormGroup.controls[this.custContrlName].markAsDirty();
                }
            });
        }
    }
    ngOnChanges(changes: SimpleChanges) {
    }
    setCurrentDate() {
        if (this.datepicker) {
            this.datepicker.close();
        }
        this.dateValueModel = moment(new Date()).format("MM/DD/YYYY")
        if (this.inputFormGroup && this.inputFormGroup.controls[this.custContrlName]) {
            const formattedDate = moment(new Date()).format('MM/DD/YYYY');
            this.inputFormGroup.controls[this.custContrlName].setValue(formattedDate);
        }
    }
    emitOnKeyDown(e?: any) {
        let inputVal = e.target.value;
        inputVal = inputVal.replace(/[^0-9/]/g, "");
        if (((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) && inputVal) {
            let slashLength = inputVal.split('').filter(x => x == "/").length;
            if (inputVal && slashLength < 2) {
                const inputValArr = inputVal.replace(/[^0-9]/g, "").match(/.{1,2}/g);
                if (inputValArr.length > 3) {
                    inputVal = inputValArr[0] + "/" + inputValArr[1] + "/" + inputValArr[2] + inputValArr[3];
                } else {
                    inputVal = inputValArr.join("/");
                }
            }
        }
        e.target.value = inputVal;
        this.twoKeysClick = 0;
    }

    onModelChange(eve) {
        this.dateValueModel = eve;
        if (this.hasControl) {
            this.inputFormGroup.controls[this.custContrlName].setValue(eve);
        }
        this.EmitChangeValue.emit(eve);
    }

    checkErrorsForDate(val) {
        if (val) {
            if (this.minDate) {
                if (moment(this.minDate.month + "/" + this.minDate.day + "/" + this.minDate.year, "MM/DD/YYYY").isAfter(moment(val, "MM/DD/YYYY"))) {
                    this.inputFormGroup.controls[this.custContrlName].setErrors({ "minDate": true });
                }
            } else if (this.maxDate) {
                if (moment(this.maxDate.month + "/" + this.maxDate.day + "/" + this.maxDate.year, "MM/DD/YYYY").isAfter(moment(val, "MM/DD/YYYY"))) {
                    this.inputFormGroup.controls[this.custContrlName].setErrors({ "maxDate": true });
                }
            }
        }
    }
    async onBlurValue(eve: any) {
        const dateVal = eve.target.value;
        if (this.hasControl) {

            if (moment(dateVal, "MM/DD/YYYY", true).isValid() || moment(dateVal, "M/DD/YYYY", true).isValid() || moment(dateVal, "MM/D/YYYY", true).isValid() || moment(dateVal, "M/D/YYYY", true).isValid() || moment(dateVal, "MM/D/YYYY", true).isValid()) {
                if (this.minDate) {
                    if (moment(this.minDate.month + "/" + this.minDate.day + "/" + this.minDate.year, "MM/DD/YYYY").isAfter(moment(dateVal, "MM/DD/YYYY"))) {
                        this.inputFormGroup.controls[this.custContrlName].setErrors({ "minDate": true });
                    }
                }
                if (this.maxDate) {
                    if (moment(this.maxDate.month + "/" + this.maxDate.day + "/" + this.maxDate.year, "MM/DD/YYYY")
                        .isBefore(moment(dateVal, "MM/DD/YYYY"))) {
                        this.inputFormGroup.controls[this.custContrlName].setErrors({ "maxDate": true });
                    }
                }
            } else if (!eve.target.value || eve.target.value === "") {
                if (this.isRequired) {
                    eve.preventDefault();
                    this.inputFormGroup.controls[this.custContrlName].setErrors({ required: true });
                }
            } else {
                eve.preventDefault();
                this.inputFormGroup.controls[this.custContrlName].setErrors({ inValid: true });
                this._cdr.detectChanges();
            }
            await this.validateField();
            if (this.errorMsg) {
                eve.preventDefault();
            }
        }
        this.EmitBlurValue.emit(this.isWholeEventRequired ? eve : eve.target.value);
    }

    async onTabEvent(even, datPickr?) {
        setTimeout(async() => {
            await this.onBlurValue(even);
         }, 200);
        if(this.isWholeEventRequired){
            this.TriggerOnTabbing.emit(even);
        }
        if (this.hasControl && this.inputFormGroup.controls[this.custContrlName].valid) {
            this.TriggerOnTabbing.emit(even);
        } else {
            if (even) {
                even.preventDefault();
            }
        }
        if (datPickr) {
            datPickr.close();
        }
    }

    async validateField() {
        if (this.hasControl) {
            if(this.rxID && this.rxID === "rxExpires")
                this.checkErrorsForDate(this.inputFormGroup.value[this.custContrlName]);
            this.errorMsg = await this._validFormFieldsUtils.validateFormFields(this.inputFormGroup, this.custContrlName, this.errorDefs);
            if (this.FromOrdDate && this.isFromErx) {
                this.TriggerOnTabbing.emit(null);
            }
        }
    }

    onDateSelect(eve) {
        this.validateField();
        if (this.custContrlName && this.inputFormGroup && this.inputFormGroup.controls[this.custContrlName]) {
            this.inputFormGroup.controls[this.custContrlName].markAsDirty();
        }
        this.TriggerOnDateSelect.emit(this.dateValueModel);
    }

    onShiftTabbingEvent(event) {
        if (event.shiftKey && event.key === "Tab") {
            this.TriggerOnShiftTabbing.emit(event);
        }
    }

    foucsDatePicker() {
        if (this.hasControl) {
            this.inputFormGroup?.controls[this.custContrlName].markAsTouched();
        }
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
    }
    addClass() {
        const typeaheadDropdown = document.getElementsByClassName("dropdown-menu show")[0];
        if (typeaheadDropdown && !this.IsAppendTo) {
            this.renderer.addClass(typeaheadDropdown, 'datepicker--inline');
        } else if(this.IsAppendTo) {
            this.renderer.addClass(typeaheadDropdown, 'datepicker-auto-width');
        }
    }



}


import { Injectable } from "@angular/core";
import { AsyncReportEnum } from "../models/AsyncReports.enum";
import { StorageService } from "../services/storage.service";
import { MsgConfig } from "../app.messages";

@Injectable({
    providedIn: "root"
})
export class ReportsUtil {
    constructor( private _storageService: StorageService) {}
    IsCurrentReportGenerated(report) {
        return report.ReportName === report.curremtReportName && report.IsReportGenerated
        && report.currentRoute.includes(report.ReportName)
    }
    checkForReportGeneration(report) {
        let sessionAsyncReportId: any =  this._storageService.getSessionStorage(report.ReportName) ? JSON.parse(this._storageService.getSessionStorage(report.ReportName)) : null;
        if (report.AsyncReportId !== sessionAsyncReportId) {
            this._storageService.setSessionStorage(report.ReportName, report.AsyncReportId);
            return true;
        } else {
            return false;
        }
    }
    getReportIsInProgress(res) {
        if(res)
        {
            const message = ("Report " + (res.IsExport ? "Export" : (res.IsPrint ? "Print" : "Preview"))) + " " + MsgConfig.ASYNC_REPORT_HOVEROVER;
            return message;
        }
        else 
            return "";
    }
}

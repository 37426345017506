
import { empty as observableEmpty, Observable, Subject, Subscription } from 'rxjs';
import { AlertService } from "./../../../services/alert.service";
import { FileMaintenanceE, Rx2E, RxE } from "./../../../models/access-privs.enum";
import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    HostListener,
    AfterContentChecked,
    TemplateRef,
    ChangeDetectorRef,
    Renderer2
} from "@angular/core";
import {
    KeyboardShortcuts,
    Unlisten,
    CommonService,
    RxService,
    UserService,
    RxObservableService,
    PatPreDrugModalService,
    EditDrugService
} from "../../../services";
import { NgbModalRef, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

import {
    debounceTime,
    distinctUntilChanged,
    map,
    tap,
    switchMap,
    takeUntil
} from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    AccCategoryE,
    PatientFileE,
    PrescriberFileE,
    DrugFileE
} from "../../../models";
import { PrivMaskGuard } from "../../../guards";
import { MsgConfig } from "../../..";
import { RxUtils, PrescriberUtil, DrugUtils, NRxUtils } from "src/app/utils";
import { TypeaheadPopupDirective } from "src/app/directives";
import * as moment from "moment";
import "rxjs/add/observable/empty";
import { RxStore, CommonStore } from "src/app/store";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { FormBuilder, FormGroup } from '@angular/forms';
import * as Sentry from "@sentry/browser";
import { DrugResultCardComponent } from '../drug-result-card/drug-result-card.component';


@Component({
    selector: "app-searchbox-global",
    templateUrl: "./searchbox-global.component.html",
})
export class SearchboxGlobalComponent implements OnInit, OnDestroy, OnChanges {
    // keyboardShortcuts: KeyboardShortcuts;
    // unlisten: Unlisten;
    DrugSuggFiltFG: FormGroup;
    bucketName: any= "";
    isInventoryAdvancedMode = false;
    drugBucketList: any[];
    SuggestData: any;
    option: any;
    selectedValue: any = "Patient";
    listItems = ["Patient", "Prescriber", "Drug", "Rx", "Refill"];
    erxItems = ["Patient", "Prescriber", "Drug"];
    searching = false;
    searchFailed = false;
    hasFilters = true;
    inputDisable: boolean;
    selectDisable: boolean;
    patRouterURL: string;
    presRouterURL: string;
    drugRouterURL: string;
    erxSearch: boolean;
    elements: any;
    value = null;
    debounceTime = 1000;
    modelRef: any;
    selecInfo: string;
    selectConfig = false;
    isAddnew = true;
    placholderText = "Patient";
    inputValue : any = "";
    searchValue: string;
    isByEnter = false;
    enableBarcode = false;
    isScannedDrugNotFound: boolean = false;
    isFromDashboard : boolean;
    unsubscribe$: Subject<void> = new Subject();
    @ViewChild("searchBox")
    searchBox: ElementRef;

    @ViewChild("searchIcon")
    searchIcon: ElementRef;

    @ViewChild("instance")
    instance: NgbTypeahead;

    @ViewChild("addPatient", { static: true })
    addPatient: any;

    @ViewChild("addPrescriber", { static: true })
    addPrescriber: any;

    @ViewChild("addDrug", { static: true })
    addDrug: any;

    @ViewChild("addRx", { static: true })
    addRx: any;

    @ViewChild("DefaultActionp")
    DefaultActionp: any;

    @ViewChild("DefaultActionpr")
    DefaultActionpr: any;

    @ViewChild("DefaultActiond")
    DefaultActiond: any;

    @ViewChild("DrugSuggestFilter", { static: true })
    DrugSuggestFilter: any;

    @ViewChild("addDrugHasVal", { static: true })
    addDrugHasVal: any;

    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    disableSerchFil: boolean;
    disableRadioButtons: boolean;
    isElementFocused: boolean;
    showPatName: boolean;
    accessPrivCate: any;
    patientPrivs: any;
    drugPrivs: any;
    prescriberPrivs: any;
    filePrivs: any;
    showPrescName: boolean;
    showDrugName: boolean;
    defualtPrescId: number;
    defualtDrugId: number;
    tabIndex: number;
    rxID: any;
    actModal = "";
    openOverride: boolean;
    categoryId: string;
    privType: any;
    overrideFor: any;
    isFrmErx = false;
    isFrmRx: any;
    EqDrugs: any[];
    todayDate: any;
    showEqDrugs: boolean;
    selecteEqDrugRowId = 0;
    rxPrivs: any;
    selectedPatInfo: any;
    overrideEndpoint: any;
    pageName: any;
    hasAddPatPrivs: boolean;
    hasAddprescPrivs: boolean;
    hasAddDrugPrivs: boolean;
    showlgList: any;
    allowToAddNewDrug = true;
    notToClearSession: boolean;
    searchFrmPage: string;
    eqDrugHeading: string;
    addModalSubscription: any;
    searchTextValue = "";
    @Input() restricSearch: any;
    @Input() IsFromPkg: any;
    @Input() IsHide: boolean;
    isFetchingBarcode: boolean;
    addDrugHasValue: boolean;
    isFromPhysicalInventoryScreen: boolean;
    positionForBucketNameDisplay: string = "top";
    singleBucketMode: any;
    rxEnter: boolean = true;
    enteredCharacters: any;
    IsPrescPrivChckd: boolean;
    IsDrugPrivChckd: boolean;
    dawFromeRxY: any;
    dawY : any;
    scannedDrugNDC: any;
    isFrmBarcodeScanner : boolean = false;
    drugList: any = [];
    @Input()
    set DAWFromeRx(data: any) {
        this.dawFromeRxY = data;
    }

    @Input() isEquiAval: boolean;
    @Input()
    set HasFilters(hf: boolean) {
        this.hasFilters = hf;
    }
    @Input()
    set selectdisabled(dis: boolean) {
        this.selectDisable = dis;
    }

    @Input()
    set inputdisabled(dis: boolean) {
        this.inputDisable = dis;
    }

    @Input()
    set SearchFor(sf: string) {
        this.selectedValue = sf;
        this.option = sf;
        this.placholderText = sf;
    }


    @Input()  IsHideDrugFilters: any;

    @Input()
    set SearchFrm(sf: string) {
        this.searchFrmPage = sf;
    }

    @Input()
    set PatRouterURL(ru: string) {
        this.patRouterURL = ru;
    }

    @Input()
    set PreRouterURL(ru: string) {
        this.presRouterURL = ru;
    }

    @Input()
    set NotToClearSesion(data: boolean) {
        if (data) {
            this.notToClearSession = true;
        } else {
            this.notToClearSession = false;
        }
    }

    @Input()
    set DrugRouterURL(ru: string) {
        this.drugRouterURL = ru;
    }

    @Input()
    set ErxSearch(erx: boolean) {
        this.erxSearch = erx;
    }

    @Input()
    set DisableSerchFil(dp: boolean) {
        this.disableSerchFil = dp;
    }

    @Input()
    set DisableRadioButtons(ds: boolean) {
        this.disableRadioButtons = ds;
    }

    @Input()
    set DebounceTime(dp: number) {
        this.debounceTime = dp;
    }

    @Input()
    set IsAddNew(iad: boolean) {
        this.isAddnew = iad;
    }

    @Input()
    set IsFromPhysicalInventoryScreen(iad: boolean) {
        this.isFromPhysicalInventoryScreen = iad;
    }

    @Input()
    set IsFocused(isfoc: boolean) {
        this.isElementFocused = isfoc;
    }

    @Input()
    set InputValue(iv: string) {
        if (iv) {
            this.inputValue = iv;
        }
    }

    @Input()
    set ClearInputValue(iv: any) {
        if (iv === true) {
            this.inputValue = null;
        } else if (iv !== false) {
            this.inputValue = iv;
        }
    }


    @Input()
    set ShowLgList(data: any) {
        if (data) {
            this.showlgList = data;
        } else {
            this.showlgList = false;
        }
    }

    @Input()
    set ShowOnlyPatName(patName: boolean) {
        this.showPatName = patName;
    }

    @Input()
    set ShowOnlyPrescName(prescName: boolean) {
        this.showPrescName = prescName;
    }

    @Input()
    set ShowOnlyDrugName(drugName: boolean) {
        this.showDrugName = drugName;
    }

    @Input()
    set DefualtPrescId(prescId: number) {
        this.defualtPrescId = +prescId;
    }

    @Input()
    set DefualtDrugId(drugId: number) {
        this.defualtDrugId = +drugId;
    }

    @Input()
    set TabIndex(ti: number) {
        this.tabIndex = ti;
    }

    @Input()
    set RxSelectId(id: any) {
        this.rxID = id;
    }

    @Input()
    set IsFromERX(ie: any) {
        this.isFrmErx = ie;
    }

    @Input()
    set IsFromRx(data: any) {
        this.isFrmRx = data;
    }

    @Input()
    set ParentPage(data: any) {
        this.pageName = data;
    }

    @Input()
    set AllowToAddNewDrug(data: any) {
        this.allowToAddNewDrug = data;
    }

    @Input() emitIfNotFound: boolean;
    @Input() searchID: boolean;
    @Input() notSelectSingleMatch: boolean;
    @Input() isFrmAllergies;
    @Input() DAWBsdDrugSug : boolean;
    @Input() IsFromReset : boolean;

    @Input()
    set EnableBarcode(hf: boolean) {
        this.enableBarcode = true;
    }

    @Input() patFacilities: any[] = [];

    @Input() updateDrugSuggFilter = null;

    @Input() Is340BRx: boolean;

    @Input() bucketFrmPriceChck: number;

    @Input() disableBuck: boolean;

    @Input() IsClearIConDisable: boolean;

    @Input()
    set PositionForBucketNameDisplay(position: string) {
        this.positionForBucketNameDisplay = position;
    }

    @Input()
    set BucketNameDisplayForHoverOver(bucketNameDisplay: string) {
       if(bucketNameDisplay) {
         this.getBucketList();
       }
    }

    @Input()
    set PatienteRxInfo(patientData: string) {
       if(patientData)
            this.patienteRxData  = patientData;
    }

    patienteRxData: any;

    @Input()
    set placeHolderReport(ph: any) {
       if(ph)
            this.placholderText  = "";
    }
    @Input() notAdashBoard;
    @Output()
    SelectedPatient = new EventEmitter();

    @Output()
    SelectedPrescriber = new EventEmitter();

    @Output()
    SelectedDrug = new EventEmitter();

    @Output()
    SelectedDrugFormBarcode = new EventEmitter();

    @Output()
    OptedType = new EventEmitter();

    @Output()
    OnEnter = new EventEmitter();

    @Output()
    FocusedOutofSearch = new EventEmitter();

    @Output()
    OptedFormAddNew = new EventEmitter();

    @Output()
    clearedInput = new EventEmitter();

    @Output() emitRestrictionSucess = new EventEmitter();

    @Output() emitDrugSuggestFilterss = new EventEmitter();

    @Output() emitDrugSugstFilterFrRx = new EventEmitter();

    @Output() emitDrugSugstFilterFrERx = new EventEmitter();

    @Output() emitPatientInfoData = new EventEmitter();

    @Output() HideDashbrdStats = new EventEmitter();

    @Output() CloseDrugSearch = new EventEmitter();

    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            if (
                this.actModal === "addPatient" ||
                this.actModal === "addPrescriber" ||
                this.actModal === "addDrug" ||
                this.actModal === "addRx"
            ) {
                event.preventDefault();
                this.closeModal();
                this.actModal = null;
            }
        } else if (event.keyCode === 13) {
            if (this.showEqDrugs) {
                this.selectedEqDrug(this.EqDrugs[this.selecteEqDrugRowId]);
            }
        } else if (event.which === 39 && this.selectedValue == "Drug") {
            // right
            if (this.searchBox?.nativeElement === document.activeElement) {
                this.showEqDrugs = true;
            }
            this.EqDrugs = [];
        } else if (event.which === 37 && this.showEqDrugs) {
            if (this.EqDrugs.length > 0 && this.searchBox) {
                this.showEqDrugs = false;
                this.searchBox.nativeElement.focus();
                event.preventDefault();
            }
        } else if (event.which === 38) {
            if (this.showEqDrugs) {
                // this.searchBox.nativeElement.blur();
                event.preventDefault();
                if (this.selecteEqDrugRowId > 0) {
                    this.selecteEqDrugRowId--;
                    const ele = document.getElementsByClassName(
                        "dropdown-item active"
                    );
                    if (ele && ele[1]) {
                        ele[1].scrollIntoView({ block: "center" });
                    }
                }
            }
            // this.showEqDrugs = false;
        } else if (event.which === 40) {
            if (this.showEqDrugs) {
                // this.searchBox.nativeElement.blur();
                event.preventDefault();
                if (
                    this.EqDrugs.length > 0 &&
                    this.selecteEqDrugRowId < this.EqDrugs.length - 1
                ) {
                    this.selecteEqDrugRowId++;
                    const ele = document.getElementsByClassName(
                        "dropdown-item active"
                    );
                    if (ele && ele[1]) {
                        ele[1].scrollIntoView({ block: "center" });
                    }
                }
            }
            // this.showEqDrugs = false;
        }
    }

    @HostListener("document:keydown.alt.y", ["$event"])
    keyEventY(event: KeyboardEvent) {
        if (
            (this.selectedValue === "Patient" ||
                this.selectedValue === "Prescriber" ||
                this.selectedValue === "Drug" ||
                this.selectedValue === "Rx") &&
            !this._modalService.hasOpenModals()
        ) {
            event.preventDefault();
            if (this.selectedValue !== "Prescriber") {
                this.searchValue = null;
            }
            this.selectedValue = "Prescriber";
            this.addNewPatientPrescDrug();
        }
    }

    @HostListener("document:keydown.alt.x", ["$event"])
    keyEventaltX(event: KeyboardEvent) {
        if (
            (this.selectedValue === "Patient" ||
                this.selectedValue === "Prescriber" ||
                this.selectedValue === "Drug" ||
                this.selectedValue === "Rx") &&
            !this._modalService.hasOpenModals()
        ) {
            event.preventDefault();
            if (this.selectedValue !== "Patient") {
                this.searchValue = null;
            }
            this.selectedValue = "Patient";
            this.addNewPatientPrescDrug();
        }
    }
   @HostListener("document:keydown.alt.z", ["$event"])
    keyEventaltZ(event: KeyboardEvent) {
        if (
            (this.selectedValue === "Patient" ||
                this.selectedValue === "Prescriber" ||
                this.selectedValue === "Drug" ||
                this.selectedValue === "Rx") &&
            !this._modalService.hasOpenModals()
        ) {
            event.preventDefault();
            if (this.selectedValue !== "Drug") {
                this.searchValue = null;
            }
            this.selectedValue = "Drug";
            this.addNewPatientPrescDrug();
        }
    }
    // @HostListener("document:keydown.alt.z", ["$event"])
    // @HostListener("document:keydown.alt.y", ["$event"])
    // @HostListener("document:keydown.alt.x", ["$event"])
    // keyEventaltZ(event: KeyboardEvent) {
    //     if(this.modelRef  && this._modalService.hasOpenModals()) {
    //         event.preventDefault();
    //         event.stopPropagation();
    //         return;
    //     }
    // }
    constructor(
        private _drugBuckUtil: DrugBucketUtil,
        private _commonServ: CommonService,
        // keyboardShortcuts: KeyboardShortcuts,
        private _router: Router,
        private _modalService: NgbModal,
        private _privMaskGuard: PrivMaskGuard,
        private _alertSer: AlertService,
        private _cdr: ChangeDetectorRef,
        private _utils: RxUtils,
        private _typehead: TypeaheadPopupDirective,
        private _actvRoute: ActivatedRoute,
        private _rxObserSer: RxObservableService,
        private _prescriberUtil: PrescriberUtil,
        private _drugUtil: DrugUtils,
        private _nrxUtils: NRxUtils,
        private _rxStore: RxStore,
        private _patUtil: PatientInfoUtil,
        private _commonStore: CommonStore, private _fb: FormBuilder,
        private _drugSvc: EditDrugService,
        private _patPreDrugModalServ: PatPreDrugModalService,
        private _alertService: AlertService, private renderer: Renderer2) {
        this.accessPrivCate = AccCategoryE;
        this.patientPrivs = PatientFileE;
        this.drugPrivs = DrugFileE;
        this.prescriberPrivs = PrescriberFileE;
        this.filePrivs = FileMaintenanceE;
        this.rxPrivs = RxE;
        // this.keyboardShortcuts = keyboardShortcuts;
        // this.unlisten = null;
        this.option = this.listItems[0];
        this.todayDate = {
            year: moment(Date.now()).year(),
            month: moment(Date.now()).month() + 1,
            day: moment(Date.now()).date(),
        };
        this.DrugSuggFiltFG = this._fb.group({
            BucketId: null,
            OnlyShowDrugsAvailableForBucket: false,
            OnlyDrugsHavingQtyInStock: false,
            ShowNonSplittableEquiDrugs: false,
            Is340B: false,
            ShowDrugsBasedOnDAWSelection : false,
            IsFromReset : false,
            DrugName: null,
        });
        this._commonServ._systemSettings$.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (this.selectedValue === "Drug" && this.DrugSuggFiltFG && +this.DrugSuggFiltFG.value.BucketId) {
                this.DrugSuggFiltFG.patchValue({
                    OnlyShowDrugsAvailableForBucket:
                        this._drugBuckUtil.getInventoryStngs("Default_Only_Show_Drugs_which_are_added_to_this_Bucket"),
                    OnlyDrugsHavingQtyInStock:
                        this._drugBuckUtil.getInventoryStngs("Default_Show_Drugs_With_Available_Stock_Qty_Only")
                });
            }
        });
    }

    ngOnInit() {
        if (this.accessPrivCate) {
            this.hasAddPatPrivs = this.checkHasPrivsToAccess(
                this.accessPrivCate.PatientFile,
                this.patientPrivs.Add
            );
            this.hasAddprescPrivs = this.checkHasPrivsToAccess(
                this.accessPrivCate.PrescriberFile,
                this.prescriberPrivs.Add
            );
            this.hasAddDrugPrivs = this.checkHasPrivsToAccess(
                this.accessPrivCate.DrugFile,
                this.drugPrivs.Add
            );
        }
        this.placholderText = this.searchFrmPage === "edi810" ? '/Scan Drug' : this.showDrugName ? 'Drug/NDC' : this.placholderText;
        this.singleBucketMode = this._drugBuckUtil.getInventoryStngs("Single_Bucket_Mode");
        if(this.selectedValue === "Drug") {
            this.SelectedDrugFormBarcode.emit(false);
            this.getBucketList(true);
        }
        this.checkInventoryMode();
        this.createDrugSugFilAndPatFG();
    }

    ngAfterViewInit() {
        if (this.isElementFocused && this.searchBox) {
            this.searchBox.nativeElement.focus();
        }
    }

    ngOnChanges(changes?: any): void {
        let isFromPrinter =  sessionStorage.getItem('FocusOnprinter') === 'true' ? true : false;
        if (this.isElementFocused && this.searchBox && !isFromPrinter) {
            this.searchBox.nativeElement.focus();
        } 
        if (this.selectedValue === "Drug" && this.patFacilities && this.patFacilities.length && this.isFrmRx &&
            this.DrugSuggFiltFG && this.DrugSuggFiltFG.value.BucketId) {
            this.patchPatFacilityBucket();
        } else if(this.selectedValue === "Drug" && this.isFrmRx && this.Is340BRx &&
            this.DrugSuggFiltFG && this.DrugSuggFiltFG.value.BucketId) {
            this.DrugSuggFiltFG.patchValue({
                BucketId: this._drugBuckUtil.getDefault340BBucketId(),
            });
        }
        if (this.selectedValue === "Drug" && changes.updateDrugSuggFilter &&
            changes.updateDrugSuggFilter.currentValue && changes.updateDrugSuggFilter.currentValue.BucketId) {
            this.DrugSuggFiltFG.patchValue(this.updateDrugSuggFilter);
            this.emitDrugSuggestFilters();
        }
        if (this.selectedValue === "Drug" && this.isFrmRx && this.Is340BRx &&
            this.DrugSuggFiltFG && this.DrugSuggFiltFG.value.BucketId &&
            !this._drugBuckUtil.getInvSetForPatPrescDrg("Require_Drug_as_340B") &&
            this._drugBuckUtil.getInvSetForPatPrescDrg("Force_340B_NDC_for_340B_Rx")) {
            this.DrugSuggFiltFG.patchValue({
                Is340B: true
            });
            this.emitDrugSuggestFilters();
        } else {
            this.emitDrugSuggestFilters();
        }

        if (changes && changes.bucketFrmPriceChck && changes.bucketFrmPriceChck.currentValue) {
            this.DrugSuggFiltFG.patchValue({
                BucketId: this.bucketFrmPriceChck
            });
        }
       this.displayBucketName();
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line:no-unused-expression
        // this.unlisten && this.unlisten();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    displayBucketName() {
        if(this.drugBucketList && this.drugBucketList.length) {
            this.bucketName = this.drugBucketList.filter((item: any) => {
                return (item.Id === this.DrugSuggFiltFG.value["BucketId"]);
            });
        }
    }
    getValueFromDropdown(value) {
        if (value) {
            this.selectedValue = value;
            this.OptedType.emit(this.selectedValue);
            this.value = null;
            this.inputValue = null;
            this.placholderText = value;
            this.drugList = [];
            if(value != "Drug") this.HideDashbrdStats.emit(true);
            setTimeout(() => {
                if (this.searchBox) {
                    this.searchBox.nativeElement.focus();
                }
            }, 100);
            // document.getElementById("iSearchBox").focus();
        }
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(this.debounceTime),
            distinctUntilChanged(),
            tap(() => (this.searching = true)),
            switchMap((term) => {
                if (this.restricSearch) {
                    this.emitRestrictionSucess.emit(null);
                    return this._commonServ.getEmptyObservable();
                } else if (term && term.trim().length > 2) {
                    return this.searchByKeyWord(term);
                } else {
                    if (this.selectedValue === "Patient") {
                        this.SelectedPatient.emit(null);
                    } else if (this.selectedValue === "Prescriber") {
                        this.SelectedPrescriber.emit(null);
                    } else if (this.selectedValue === "Drug") {
                        this.SelectedDrug.emit(null);
                    }
                    return this._commonServ.getEmptyObservable();
                }
            }),
            tap(() => (this.searching = false))
        );

    checkIsNullValue(val) {
        this.enteredCharacters = val;
        if (val === null || val === undefined || val === "") {
            if (this.selectedValue === "Patient") {
                this.SelectedPatient.emit(null);
            } else if (this.selectedValue === "Prescriber") {
                this.SelectedPrescriber.emit(null);
            } else if (this.selectedValue === "Drug") {
                this.SelectedDrug.emit(null);
            }
        }
    }

    searchByKeyWord(value: any) {
        if (typeof value === "string") {
            value = value.trim();
            if (value === "" || value === " ") {
                value = null;
            }
            this.inputValue = value;
        }
        let newSuggestData;
        this.SuggestData = [];
        this.drugList = [];
        this.selecInfo = null;
        let type = null;
        this.searchValue = value;
        this._typehead.isCompleted = false;
        if (!this.isByEnter) {
            if (value && value.length > 0) {
                if (value.indexOf(":") >= 0) {
                    type = value.split(":")[0];
                    type = type.replace(/[^A-Z0-9]/gi, "");
                    const typeVal = type.toLowerCase();
                    if (
                        typeVal === "pr" ||
                        typeVal === "d" ||
                        typeVal === "p" ||
                        typeVal === "rx" ||
                        typeVal === "rf"
                    ) {
                        value = value.split(":")[1];
                        if (typeVal === "pr") {
                            this.placholderText = "Prescriber";
                            this.option = this.listItems[1];
                            this.selectedValue = this.listItems[1];
                        } else if (typeVal === "d") {
                            this.placholderText = "Drug";
                            this.option = this.listItems[2];
                            this.selectedValue = this.listItems[2];
                        } else if (typeVal === "p") {
                            this.placholderText = "Patient";
                            this.option = this.listItems[0];
                            this.selectedValue = this.listItems[0];
                        } else if (typeVal === "rx") {
                            this.placholderText = "Rx";
                            this.option = this.listItems[3];
                            this.selectedValue = this.listItems[3];
                        }
                        else if (typeVal === "refill") {
                            this.placholderText = "Refill";
                            this.option = this.listItems[4];
                            this.selectedValue = this.listItems[4];
                        }
                        this.searchTextValue = value;
                    }
                    this.inputValue = value;
                    if (value) {
                        if (type.toLowerCase().indexOf("pr") >= 0) {
                            // this.placholderText = "Prescriber";
                            // this.option = this.listItems[1];
                            // this.selectedValue = this.listItems[1];
                            const prescName = value.split("_");
                            let prescVal = null;
                            if (prescName[1]) {
                                prescVal =
                                    prescName[0].trim() +
                                    "_" +
                                    prescName[1].trim();
                            } else {
                                prescVal = prescName[0].trim();
                            }
                            this.SuggestData = this._commonServ.getPrescriberSuggest(
                                prescVal
                            );
                            this.openAddNewPrescriberModal(this.SuggestData);
                        } else if (type.toLowerCase().indexOf("d") >= 0) {
                            // this.placholderText = "Drug";
                            // this.option = this.listItems[2];
                            // this.selectedValue = this.listItems[2];
                            if(this.IsHideDrugFilters) {
                                this.DrugSuggFiltFG.value["OnlyDrugsHavingQtyInStock"] = false;
                                this.DrugSuggFiltFG.value["OnlyShowDrugsAvailableForBucket"] = false;
                                this.DrugSuggFiltFG.value["Is340B"] = false;
                            }
                            if(this.IsFromReset){
                                this.DrugSuggFiltFG.patchValue({
                                    IsFromReset : false,
                                    ShowDrugsBasedOnDAWSelection : false
                                });
                            }
                            this.SuggestData = this._commonServ.getGlobalSearchDrugSuggest(value, this.DrugSuggFiltFG.value || null);
                            this.openAddNewDrugModal(this.SuggestData);
                        } else if (type.toLowerCase().indexOf("rx") >= 0) {
                            // this.placholderText = "Rx";
                            // this.option = this.listItems[3];
                            // this.selectedValue = this.listItems[3];
                            this.routeToEditRx(+value);
                            setTimeout(() => {
                                document.getElementById("iSearchBox").focus();
                            }, 300);
                        } else if (type.toLowerCase().indexOf("refill") >= 0) {
                            // this.placholderText = "Refill";
                            // this.option = this.listItems[4];
                            // this.selectedValue = this.listItems[4];
                            this._commonServ
                                .getRxDetailsByPrescNos([value], null, false)
                                .pipe(takeUntil(this.unsubscribe$))
                                .subscribe((rxInfo) => {
                                    if (rxInfo && rxInfo.length > 0) {
                                        const data = {
                                            selectedRxs: [+value],
                                            frm: "SideNav",
                                            completeData: [+value],
                                        };
                                        value = null;
                                        this.inputValue = null;
                                        this.searchValue = null;
                                        this._commonStore.storeRefInfo(data);
                                        this._commonStore.storeRfStage("Start");
                                        this.SuggestData = [];
                                        this.drugList = [];
                                    } else {
                                        this.modelRef = this._modalService.open(
                                            this.addRx,
                                            {
                                                centered: true,
                                                backdrop: false,
                                                keyboard: false,
                                                windowClass:"small--content"
                                            }
                                        );
                                    }
                                });
                            setTimeout(() => {
                                document.getElementById("iSearchBox").focus();
                            }, 300);
                        } else if (
                            type.toLowerCase().indexOf("p") >= 0 ||
                            this.selectedValue === "Patient"
                        ) {
                            // this.placholderText = "Patient";
                            // this.option = this.listItems[0];
                            // this.selectedValue = this.listItems[0];
                            const patName = value.split("_");
                            let patVal = null;
                            if (patName[1]) {
                                patVal =
                                    patName[0].trim() + "_" + patName[1].trim();
                            } else {
                                patVal = patName[0].trim();
                            }
                            // Sentry.captureException([
                            //     {name:  type.toLowerCase().indexOf("p")},
                            //     {name: this.selectedValue}
                            // ]);
                            this.SuggestData = this._commonServ.getPatientsuggest(
                                patVal
                            );
                            this.SuggestData = this._commonServ.getPatientsuggest(
                                patVal
                            );
                            this.openAddNewPatientModal(this.SuggestData);
                        }
                    }
                } else if (value && this.erxSearch) {
                    if (this.selectedValue === "Patient") {
                        this.SelectedPatient.emit(value);
                    } else if (this.selectedValue === "Prescriber") {
                        this.SelectedPrescriber.emit(value);
                    } else {
                        this.SelectedDrug.emit(value);
                    }
                } else if (this.selectedValue) {
                    // value = value.replace(",", "_");
                    this.searchTextValue = value;
                    if (value.length > 0) {
                        if (this.selectedValue === this.listItems[0]) {
                            const patName = value.split("_");
                            let patVal = null;
                            if (patName[1]) {
                                patVal =
                                    patName[0].trim() + "_" + patName[1].trim();
                            } else {
                                patVal = patName[0].trim();
                            }
                            // Sentry.captureException([
                            //     {name: this.listItems[0]},
                            //     {name:this.selectedValue }
                            // ]);
                            this.SuggestData = this._commonServ.getPatientsuggest(
                                patVal
                            );
                            this.openAddNewPatientModal(this.SuggestData);
                        } else if (this.selectedValue === this.listItems[1]) {
                            const prescName = value.split(",");
                            let prescVal = null;
                            if (prescName[1]) {
                                prescVal =
                                    prescName[0].trim() +
                                    "_" +
                                    prescName[1].trim();
                            } else {
                                prescVal = prescName[0].trim();
                            }
                            let checkFromDashboard = this.searchFrmPage ? true : false;
                            this.SuggestData = this._commonServ.getPrescriberSuggest(
                                prescVal, checkFromDashboard
                            );
                            this.openAddNewPrescriberModal(this.SuggestData);
                        } else if (this.selectedValue === this.listItems[2]) {
                            this.EqDrugs = [];
                            this.showEqDrugs = false;
                            if(this.IsHideDrugFilters) {
                                this.DrugSuggFiltFG.value["OnlyDrugsHavingQtyInStock"] = false;
                                this.DrugSuggFiltFG.value["OnlyShowDrugsAvailableForBucket"] = false;
                                this.DrugSuggFiltFG.value["Is340B"] = false;
                            }
                            if(this.IsFromReset){
                                this.DrugSuggFiltFG.patchValue({
                                    IsFromReset : false,
                                    ShowDrugsBasedOnDAWSelection : false
                                });
                            }
                            if (!this.isFetchingBarcode) {
                                this.SuggestData = this._commonServ.getGlobalSearchDrugSuggest(value, this.DrugSuggFiltFG.value || null);
                                this.openAddNewDrugModal(this.SuggestData);
                            } else {
                                this._commonServ.getEmptyObservable();
                            }
                        } else if (this.selectedValue === this.listItems[3] && this.rxEnter) {
                            this.rxEnter = false;
                            this.SuggestData = [];
                            this.drugList = [];
                            sessionStorage.removeItem("ActiveId");
                            let rxArr =  value ? value.split("-") : [];
                            if(rxArr && rxArr.length){
                                let prescNum = rxArr && rxArr[0] ? rxArr[0] : null;
                                let refNum =   rxArr[1];
                                let parNum = rxArr && rxArr[2] ? rxArr[2] : null;
                                this.routeToEditRx(+prescNum,null,"false",+refNum,+parNum)
                            }
                            this.SuggestData = [];
                            this.drugList = [];
                        } else if (this.selectedValue === this.listItems[4] && this.rxEnter) {
                            this.rxEnter = false;
                            this.SuggestData = [];
                            this.drugList = [];
                            sessionStorage.removeItem("ActiveId");
                            this._commonServ
                                .getRxDetailsByPrescNos([value], null, false)
                                .pipe(takeUntil(this.unsubscribe$))
                                .subscribe((rxInfo) => {
                                    this.rxEnter = true;
                                    if (rxInfo && rxInfo.length > 0) {
                                        this._commonStore._prevoiusScreenInRefill$.next("FromDashBoard");
                                        const data = {
                                            selectedRxs: [+value],
                                            frm: "SideNav",
                                            completeData: [+value],
                                        };
                                        value = null;
                                        this.inputValue = null;
                                        this.searchValue = null;
                                        this._commonStore.storeRefInfo(data);
                                        this._commonStore.storeRfStage("Start");
                                        this.SuggestData = [];
                                        this.drugList = [];
                                    } else {
                                        this.modelRef = this._modalService.open(
                                            this.addRx,
                                            {
                                                centered: true,
                                                backdrop: false,
                                                keyboard: false,
                                                windowClass:"small--content"
                                            }
                                        );
                                    }
                                });
                        }
                    }
                } else {
                    this.SuggestData = this._commonServ.getEmptyObservable();
                    this.SelectedPatient.emit(null);
                }
            } else {
                if (this.selectedValue === "Patient") {
                    this.SelectedPatient.emit(value);
                } else if (this.selectedValue === "Prescriber") {
                    this.SelectedPrescriber.emit(value);
                } else {
                    this.SelectedDrug.emit(value);
                }
            }
        }
        newSuggestData = this.SuggestData;
        let isSinglData = false;
        if (newSuggestData && newSuggestData.length > 0) {
                newSuggestData.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
                    if (data && data.length > 0) {
                        if (data.length === 1) {
                            isSinglData = true;
                            this.formatter(data[0]);
                        }
                    }
                })
        }
         this.isByEnter = false;
        if (isSinglData) {
            return [];
        } else {
            return this.SuggestData;
        }
        
    }

    async routeToEditRx(value, rfId?: any, isNPPatientValue?,refillNo?,parRefNum?) {
        let rxInfo: any = await this._nrxUtils.getandStoreRxInfo(+value, rfId ? rfId : null, false, refillNo, null, null, true, false,null,parRefNum?parRefNum:null);
        this.rxEnter = true;
        if (rxInfo) {
            rxInfo = this._nrxUtils.editRxPatchValues(rxInfo);
            this._nrxUtils.createPatchStoreRxFG(rxInfo);
            this._nrxUtils.getCommonEndPoints(rxInfo);
            this._patPreDrugModalServ._patPreDrugModal$.next("Rx");
            const modalRef = await this._patPreDrugModalServ.modalInstanceRef;
            modalRef.componentInstance.CloseEditMode.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
                modalRef.close();
                this._patPreDrugModalServ._patPreDrugModal$.next(null);
                this.inputValue = null;
            });
            modalRef.componentInstance.SelectedValue = ((isNPPatientValue && isNPPatientValue === "false")) ? isNPPatientValue : "true";
            modalRef.componentInstance.CloseAndReopen
            .pipe(takeUntil(this.unsubscribe$))
                .subscribe(d => {
                    modalRef.close();
                    if (d) {
                        this.routeToEditRx(d["rxNo"], d["rfId"],  d["isNPPatientValue"]);
                    } else {
                        this._patPreDrugModalServ._patPreDrugModal$.next(null);
                        this.inputValue = null;
                    }
                });
        } else {
            this.modelRef = this._modalService.open(this.addRx, {
                centered: true,
                backdrop: false,
                keyboard: false,
                windowClass:"small--content"
            });
        }
    }

    formatter = (x: any) => {
        let name = null;
        if (this.selectedValue === "Patient") {
            if (
                this.searchFrmPage === "header" ||
                this.searchFrmPage === "Dash"
            ) {
                this.reDirectToRx(x);
            } else {
                this.inputValue = true;
                setTimeout(()=> {
                    this.inputValue = x.fullname ? ((x.fullname).toUpperCase()).replace("_", ",") : "";;
                },0);
                this.SelectedPatient.emit(x);
                name = x.lastname + " " + x.firstname;
            }
            if (this.isFrmAllergies)
                setTimeout(() =>{
                    this.inputValue = null;
                })
            this.searchValue = null;
            return name;
        } else if (this.selectedValue === "Prescriber") {
            this.inputValue = true;
            setTimeout(()=> {
                this.inputValue =  x.prescriberlastname + ", " + x.prescriberfirstname;
            },0);
            this.SelectedPrescriber.emit(x);
            this.selecInfo = x.prescriberlastname + " " + x.prescriberfirstname;
            if (this.isFrmAllergies)
                setTimeout(() =>{
                    this.inputValue = null;
                })
            this.searchValue = null;
            return this.searchFrmPage === "header" ||
                this.searchFrmPage === "Dash"
                ? null
                : x.prescriberlastname + " " + x.prescriberfirstname;
        } else if (this.selectedValue === "Drug") {
            this.inputValue = true;
            setTimeout(()=> {
                this.inputValue = x.drugname;
            },0);
            this.SelectedDrug.emit(x);
            this.SelectedDrugFormBarcode.emit(false);
            this.selecInfo = x.drugname;
            if (this.isFrmAllergies)
                setTimeout(() =>{
                    this.inputValue = null;
                })
            this.searchValue = null;
            return this.searchFrmPage === "header" ||
                this.searchFrmPage === "Dash"
                ? null
                : x.drugname;
        }
    };

    // formatter = (x: any) => {
    //     if (this.selectedValue === "Patient") {
    //         /* Clear local storage as selecting new patient */
    //         this.selectedPatientSuggest(x);
    //         return x.lastname + " " + x.firstname;
    //     } else if (this.selectedValue === "Prescriber") {
    //         if (this.pageName === "header") {
    //             this._router.navigate([this.presRouterURL, "prescriber" , x.prescriberid]);
    //         } else if (this.presRouterURL) {
    //             this._router.navigate([
    //                 this.presRouterURL,
    //                 x.prescriberid,
    //                 "prescriberinfo"
    //             ]);
    //         }
    //         this.SelectedPrescriber.emit(x);
    //         this.selecInfo = x.prescriberlastname + " " + x.prescriberfirstname;
    //         // this.inputValue = null;
    //         return x.prescriberlastname + " " + x.prescriberfirstname;
    //     } else if (this.selectedValue === "Drug") {
    //         if (this.pageName == "header") {
    //             this._router.navigate([this.drugRouterURL,"drug" , x.id]);
    //         } else if (this.drugRouterURL) {
    //             this._router.navigate([this.drugRouterURL, x.id, "druginfo"]);
    //         }
    //         this.SelectedDrug.emit(x);
    //         this.selecInfo = x.drugname;
    //        // this.inputValue = null;
    //         return x.drugname;
    //     }
    // };

    async reDirectToRx(data?: any) {
        if (this._router.url === "/eprx/rx?fm=nr") {
            this._router.navigate(["eprx/dashboard"]);
        }
        localStorage.removeItem("IsPatient340");
        if (this._privMaskGuard.canActivate(AccCategoryE.Rx, RxE.RxEntry)) {
            const rxFG = await this._nrxUtils.createPatchStoreRxFG();
            rxFG.controls["Patient"].patchValue(data);
            this._rxStore.StoreRxFG(rxFG);
            this._router.navigate(["/eprx/rx"], {
                queryParams: { fm: "nr" },
            });
        } else {
            this._alertService.error(MsgConfig.NO_PRIVS_ACCESS);
        }
    }

    selectedPatientSuggest(x) {
        localStorage.removeItem("patientId");
        localStorage.removeItem("RxInfo");
        localStorage.removeItem("RxID");
        localStorage.removeItem("PatientInfo");
        sessionStorage.removeItem("ErxType");
        sessionStorage.removeItem("Insurance");
        sessionStorage.removeItem("InsuranceType");
        sessionStorage.removeItem("isTransMSG");
        sessionStorage.removeItem("isPatHistEnabled");
        sessionStorage.removeItem("toEdit");
        localStorage.removeItem("NewPatientInfo");
        if (!this.notToClearSession) {
            window.sessionStorage.clear();
        }
        this._rxObserSer.clearAllRxOtherInfoBS();

        if (this.patRouterURL) {
            if (
                this.checkHasPrivsToAccess(
                    this.accessPrivCate.Rx,
                    this.rxPrivs.RxEntry
                )
            ) {
                this.reDirectToRx(x);
            } else {
                this.openOveride("Rx", "RxEntry", "POST_Rx");
            }
        }
        this._commonServ.storePatientIdinBS(x.patientid);
        this.SelectedPatient.emit(x);
        localStorage.setItem("PatientInfo", this._commonServ.encryptData(JSON.stringify(x)));
        this.selecInfo = x.lastname + " " + x.firstname;
        // this.inputValue = null;
        sessionStorage.removeItem("ActiveId");
        sessionStorage.removeItem("CreatingNewFile");
    }

    clearFocus() {
        if (this.searchBox) {
            this.searchBox.nativeElement.blur();
        }
    }
    clearValue() {
        if (this.searchBox) {
            this.searchBox.nativeElement.value = "";
        }   
        this.searchValue = null;
        if (this.selectedValue === "Drug") {
             this.SelectedDrug.emit(null);
             this.getBucketList();
        }
    }

    openAddNewPatientModal(patientObserv: Observable<any>) {
        patientObserv.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
            if (resp && resp.length === 0 && !this.isByEnter) {
                if (this.inputValue === this.searchValue) {
                    this.modelRef = this._modalService.open(this.addPatient, {centered: true,
                        keyboard: false, windowClass:"large--content"
                    });
                    this.actModal = "addPatient";
                } else {
                    this.modelRef = this._modalService.open(this.addPatient, {centered: true,
                        keyboard: false, windowClass:"large--content"
                    });
                    this.actModal = "addPatient";
                }
            } else if (
                !this.notSelectSingleMatch &&
                resp &&
                resp.length === 1 &&
                this._commonServ.getSetttingValue(
                    "RxSettings",
                    "DISP_SINGLE_MATCH_FILE_SEARCH"
                ) === "0"
            ) {
                this.formatter(resp[0]);
            }
        });
    }

    openAddNewPrescriberModal(presObserv: Observable<any>) {
        presObserv.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
            if (resp && resp.length === 0 && !this.isByEnter) {
                if (this.searchValue) {
                    if (this.searchValue.substring(0, 3) === "pr:") {
                        this.searchValue = this.searchValue.substring(
                            3,
                            this.searchValue.length + 1
                        );
                    }
                }
                if (this.inputValue === this.searchValue) {
                    this.modelRef = this._modalService.open(
                        this.addPrescriber,
                        { keyboard: false, windowClass:"large--content", centered: true}
                    );
                    this.actModal = "addPrescriber";
                } else {
                    this.modelRef = this._modalService.open(
                        this.addPrescriber,
                        { keyboard: false, windowClass:"large--content", centered: true }
                    );
                    this.actModal = "addPrescriber";
                    this.isElementFocused = true;
                }
            } else if (
                !this.notSelectSingleMatch &&
                resp &&
                resp.length === 1 &&
                this._commonServ.getSetttingValue(
                    "RxSettings",
                    "DISP_SINGLE_MATCH_FILE_SEARCH"
                ) === "0"
            ) {
                this.formatter(resp[0]);
            }
        });
    }
    openAddNewDrugModal(drugObserv: Observable<any>) {
        drugObserv.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
            if (resp && resp?.length) {                                    
                    if(this.searchFrmPage != "Dash") this.openDrugResultModal(resp);
                    else this.drugList = resp;                    
                    this.DrugSuggFiltFG.controls['DrugName'].patchValue(this.searchValue);
                    this._cdr.detectChanges();
                    this.inputValue = null;
                    this.searchValue = null;
                    if(this.searchFrmPage === "Dash") this.HideDashbrdStats.emit(false);
            }
            else if ((!resp || (resp && resp.length === 0)) && !this.isByEnter) {
                this.HideDashbrdStats.emit(true);
                const hasSuggVal = this._commonServ.drugSuggVal$["source"]["value"];
                if (this.inputValue === this.searchValue) {
                    if (this.defualtDrugId) {
                        this.addDrugHasValue = false;
                        this.getDrugById(this.defualtDrugId);
                    } else {
                        if (hasSuggVal) {
                            this.getBucketList();
                            // this.addDrugHasValue = true;
                            // this.modelRef = this._modalService.open(this.DrugSuggestFilter, {
                            //     keyboard: false, backdrop: false, centered : true
                            // });
                            this.actModal = "addDrug";
                        } else {
                                this.addDrugHasValue = false;
                                    this.modelRef = this._modalService.open(this.addDrug, {
                                        centered: true,
                                        keyboard: false,
                                        backdrop: false,
                                        windowClass: "large--content",
                                    });
                                this.actModal = "addDrug";
                            }
                    }
                } else {
                    if (hasSuggVal) {
                        this.getBucketList();
                        // this.addDrugHasValue = true;
                        // this.modelRef = this._modalService.open(this.DrugSuggestFilter, {
                        //     keyboard: false, backdrop: false, centered :true
                        // });
                        this.actModal = "addDrug";
                    } else {
                        this.addDrugHasValue = false;
                        if (this.searchValue) {
                            if (this.searchValue.substring(0, 2) === "d:") {
                                this.searchValue = this.searchValue.substring(
                                    2,
                                    this.searchValue.length + 1
                                );
                            }
                            sessionStorage.setItem("AddDrugInfo", this.searchValue);
                        }
                        this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                            keyboard: false, backdrop: false, windowClass: "large--content"
                        });
                        this.actModal = "addDrug";
                    }
                }
            } else if (
                !this.notSelectSingleMatch &&
                resp &&
                resp.length === 1 &&
                this._commonServ.getSetttingValue(
                    "RxSettings",
                    "DISP_SINGLE_MATCH_FILE_SEARCH"
                ) === "0"
            ) {
                this.addDrugHasValue = false;
                this.formatter(resp[0]);
            }
        });
    }

    closeModal() {
        sessionStorage.setItem("RouteFromSearchPrescToSearchMMS", "false");
        this.actModal = "";
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.isElementFocused = false;
        this.inputValue = "s";
        this.searchValue = "s";

        if (this.selectedValue === "Patient") {
            this.SelectedPatient.emit(null);
        } else if (this.selectedValue === "Prescriber") {
            this.SelectedPrescriber.emit(null);
        } else {
            this.SelectedDrug.emit(null);
        }
        setTimeout(() => {
            if (this.searchBox) {
                this.searchBox.nativeElement.focus();
            }
            this.inputValue = null;
            this.searchValue = null;
        }, 50);
        // document.getElementById("iSearchBox").focus();
        this.isByEnter = false;
    }

    searchForMMSPopupRoute() {
        sessionStorage.setItem("RouteFromSearchPrescToSearchMMS", "true");
        this.addNewPatientPrescDrugRx();
    }

    routeToNewDrugMMS() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        let curntRoute = null;
        this._actvRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
            if (params && params.fm) {
                curntRoute = params.fm;
            }
        });
        sessionStorage.setItem("AddDrugInfo", this.searchValue);
        if (this.searchFrmPage === "ErxChange") {
            this.OptedFormAddNew.emit("DrugMMS");
        } else {
            if (window.location.hash.includes("dashboard")) {
                this._router.navigate(["eprx/drug/druginfo"], {
                    queryParams: { openMMS: true },
                });
            } else {
                if (curntRoute === "nr") {
                    this._router.navigate(["eprx/drug/druginfo"], {
                        queryParams: { fm: "nr", openMMS: true },
                    });
                } else if (curntRoute === "er") {
                    this._router.navigate(["eprx/drug/druginfo"], {
                        queryParams: { fm: "er", openMMS: true },
                    });
                }
            }
        }
    }

    async addNewPatientPrescDrug(openMMS?: boolean,event?) {
        this.isScannedDrugNotFound = false;
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        if (this.emitIfNotFound) {
            this.OptedFormAddNew.emit(this.selectedValue);
        } else {
            if (this.selectedValue === "Patient") {
                if (this.isFrmErx) {
                    this.OptedFormAddNew.emit("Patient");
                } else {
                    if (!this.hasAddPatPrivs) {
                        this.openOveride('PatientFile', 'Add');
                    } else {
                        this.continueAddPatient();
                    }
                }
            }
            else if (this.selectedValue === "Prescriber") {

                if (this.isFrmErx && !openMMS) {
                    this.OptedFormAddNew.emit("Prescriber");
                } else if (this.isFrmErx && openMMS) {
                    this.OptedFormAddNew.emit(this.searchValue);
                    
                } else {
                    if (!this.hasAddprescPrivs) {
                        this.IsPrescPrivChckd = true;
                        this.openOveride('PrescriberFile', 'Add');
                    } else {
                        this.continueAddPrescriber(openMMS);
                    }
                }
            }
             else if (this.selectedValue === "Drug") {                
                if (this.isFrmErx) {
                    this.OptedFormAddNew.emit("Drug");
                } else {
                    if (!this.hasAddDrugPrivs) {
                        this.IsDrugPrivChckd = true;
                        this.openOveride('DrugFile', 'Add');
                    } else {
                        this.continueAddDrug(openMMS);
                    }
            }
            }
        }
    }

    continueAddPatient() {
        this.placholderText = "Patient";
        this._patPreDrugModalServ._patPreDrugModal$.next("patient");
                    const modalRef = this._patPreDrugModalServ.modalInstanceRef;
                    let FG = this._patUtil.generatePatientInfoFG();
                    if (this.searchValue && !this.searchValue.endsWith("#")) {
                        FG = this._patUtil.patchPatFrmGlobalSearch(
                            this.searchValue,
                            FG
                        );
                    }
                    modalRef.componentInstance.patientID = null;
                    modalRef.componentInstance.addPatientModel = FG;
                    modalRef.componentInstance.isFromDashboard = true;
                    modalRef.componentInstance.ClosePatientModal
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                        async (resp) => {
                            if (resp["PatientId"]) {
                                const response = await this._commonServ
                                    .getRxPatientDetailsById(resp["PatientId"])
                                    .toPromise();
                                if (
                                    (this.searchFrmPage === "header" ||
                                        this.searchFrmPage === "Dash") && !this.isFrmRx
                                ) {
                                    this.reDirectToRx(
                                        response ? response : null
                                    );
                                } else {
                                    this.SelectedPatient.emit(
                                        response ? response : null
                                    );
                                }
                            }
                            modalRef.close();
                            this._patPreDrugModalServ._patPreDrugModal$.next(
                                null
                            );
                            this.inputValue = null;
                            this.searchValue = null;
                        }
                    );
    }

    continueAddPrescriber(openMMS) {
        this.placholderText = "Prescriber";
        let formGroupName = null;
                    this._patPreDrugModalServ._patPreDrugModal$.next(
                        "prescriber"
                    );
                    const modalRef = this._patPreDrugModalServ.modalInstanceRef;
                    if (this.searchValue) {
                        formGroupName = this._prescriberUtil.generatePrescriberFG();
                        if (/^([0-9]{10})$/.test(this.searchValue)) {
                            formGroupName.controls["Person"].patchValue({
                                NPINum: this.searchValue
                                    ? this.searchValue
                                    : null,
                            });
                        } else if (
                            /^([a-zA-Z]{2})([0-9]{7})$/.test(this.searchValue)
                        ) {
                            formGroupName.controls["Person"].patchValue({
                                DEANum: this.searchValue,
                            });
                        } else if (this.searchValue.indexOf("/") === -1) {
                            const patientNames = this.searchValue.split(",");
                            formGroupName.controls["Person"].patchValue({
                                LastName: patientNames[0]
                                    ? patientNames[0].trim()
                                    : null,
                                FirstName: patientNames[1]
                                    ? patientNames[1].trim()
                                    : null,
                            });
                        }
                    }
                    modalRef.componentInstance.prescriberID = null;
                    modalRef.componentInstance.openMMS = openMMS;
                    modalRef.componentInstance.IsFromGlobalSearch = true;
                    modalRef.componentInstance.addPrescriberFG = formGroupName;
                    modalRef.componentInstance.ClosePrescriberModal
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                        (resp) => {
                            if (
                                resp["prescriberId"] &&
                                !(
                                    (this.searchFrmPage === "header" ||
                                        this.searchFrmPage === "Dash") && !this.isFrmRx
                                )
                            ) {
                                this.getPrescriberById(resp["prescriberId"]);
                            }
                            modalRef.close();
                            this._patPreDrugModalServ._patPreDrugModal$.next(
                                null
                            );
                            this.inputValue = null;
                            this.searchValue = null;
                        }
                    );

    }

    continueAddDrug(openMMS) {
        this.placholderText = "Drug";
        this._patPreDrugModalServ._patPreDrugModal$.next("drug");
        const modalRef = this._patPreDrugModalServ.modalInstanceRef;
        modalRef.componentInstance.drugId = null;
        modalRef.componentInstance.drugFlow = "Add";
        modalRef.componentInstance.frmAction = true;
        const fG = this._drugUtil.createDrugDataModal();
        if (this.searchValue) {
            if (this.searchValue.substring(0, 2) === "d:") {
                this.searchValue = this.searchValue.substring(
                    2,
                    this.searchValue.length + 1
                );
            }
            if (/^([0-9]{11})$/.test(this.searchValue) || (/^\d+[\.,\,,\-]?\d+$/.test(this.searchValue))) {
                if (
                    this.searchValue != null &&
                    this.searchValue !== undefined
                ) {
                    fG.controls["Drug"].patchValue({
                        DrugCode: this.searchValue
                            ? this.searchValue
                            : null,
                    });
                }
            } else {
                const drugNames = this.searchValue.split(/[\s,]+/);
                fG.controls["Drug"].patchValue(this.isFrmBarcodeScanner ? 
                    { DrugCode : this.scannedDrugNDC } : {
                    Name: drugNames[0] ? drugNames[0].trim() : null,
                    Strength: drugNames[1] ? drugNames[1] : null,
                });
            }
        }
        this.isFrmBarcodeScanner = false;
        modalRef.componentInstance.openMMS = openMMS;
        modalRef.componentInstance.addDrugModal = fG;
        modalRef.componentInstance.CloseDrugModal
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
            (resp) => {
                modalRef.close();
                if (
                    resp["drugId"] &&
                    !(
                        (this.searchFrmPage === "header" ||
                            this.searchFrmPage === "Dash") && !this.isFrmRx
                    )
                ) {
                    this.getDrugById(resp["drugId"]);
                }
                this._patPreDrugModalServ._patPreDrugModal$.next(
                    null
                );
                this.inputValue = null;
                this.searchValue = null;
            }
        );
    }

    addNewPatientPrescDrugRx() {
        sessionStorage.removeItem("ActiveId");
        let curntRoute = null;
        this._actvRoute.queryParams
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((params) => {
            if (params && params.fm) {
                curntRoute = params.fm;
            }
        });
        if (this.modelRef) {
            this.modelRef.close();
        }
        if (this.selectedValue === "Patient") {
            if (this.isFrmErx || this.searchFrmPage === "Family") {
                this.OptedFormAddNew.emit("Patient");
            } else if (this.pageName == "header" && !this.isFrmRx) {
                sessionStorage.setItem(
                    "AddPatientInfo",
                    this.searchValue ? this.searchValue : ""
                );
                this._router.navigate([this.patRouterURL, "patient", "false"]);
            } else {
                sessionStorage.setItem(
                    "AddPatientInfo",
                    this.searchValue ? this.searchValue : ""
                );
                if (curntRoute === "nr") {
                    this._router.navigate(["eprx/editpatient/patientinfo"], {
                        queryParams: { AddPatient: true, fm: "nr" },
                    });
                } else if (curntRoute === "er") {
                    this._router.navigate(["eprx/editpatient/patientinfo"], {
                        queryParams: { AddPatient: true, fm: "er" },
                    });
                } else {
                    this._router.navigate(["eprx/editpatient/patientinfo"], {
                        queryParams: { AddPatient: true },
                    });
                }
            }
        } else if (
            this.selectedValue === "Prescriber" ||
            this.selectedValue === "Drug"
        ) {
            if (this.isFrmErx) {
                this.OptedFormAddNew.emit(this.selectedValue);
            } else {
                this.hasAddDrugPrivs = this.IsDrugPrivChckd ? true : this.hasAddDrugPrivs;
                this.hasAddprescPrivs = this.IsPrescPrivChckd ? true : this.hasAddprescPrivs;;
                this.addNewPatientPrescDrug();
            }
        }

        // else if (this.selectedValue === "Prescriber") {
        //     if (this.isFrmErx) {
        //         this.OptedFormAddNew.emit("Prescriber");
        //     } else if (this.pageName == "header") {
        //         if (this.searchValue) {
        //             sessionStorage.setItem("AddPrescriberInfo", this.searchValue ? this.searchValue : "");
        //         }
        //         this._router.navigate([this.presRouterURL, "prescriber","0"]);
        //     } else {
        //         if (this.searchValue) {
        //             sessionStorage.setItem("AddPrescriberInfo", this.searchValue ? this.searchValue : "");
        //         }
        //         if (window.location.hash.includes("dashboard")) {
        //             this._router.navigate(["eprx/prescriber/prescriberinfo"]);
        //         } else {
        //             if (curntRoute === "nr") {
        //                 this._router.navigate(["eprx/prescriber/prescriberinfo"], {
        //                     queryParams: { fm: "nr" }
        //                 });
        //             } else if (curntRoute === "er") {
        //                 this._router.navigate(["eprx/prescriber/prescriberinfo"], {
        //                     queryParams: { fm: "er" }
        //                 });
        //             }
        //         }
        //     }
        // } else if (this.selectedValue === "Drug") {
        //     sessionStorage.setItem("AddDrugInfo", this.searchValue ? this.searchValue : "");
        //     if (this.isFrmErx || this.searchFrmPage === "ErxChange") {
        //         this.OptedFormAddNew.emit("Drug");
        //     } else if (this.pageName == "header") {
        //         this._router.navigate([this.drugRouterURL, "drug", "0"]);
        //     } else {
        //         if (window.location.hash.includes("dashboard")) {
        //             this._router.navigate(["eprx/drug/druginfo"]);
        //         } else {
        //             if (curntRoute === "nr") {
        //             this._router.navigate(["eprx/drug/druginfo"], {
        //                 queryParams: { fm: "nr" }
        //             });
        //         } else if (curntRoute === "er") {
        //             this._router.navigate(["eprx/drug/druginfo"], {
        //                 queryParams: { fm: "er" }
        //             });
        //         }
        //         }
        //     }
        // } else {
        // }
    }

    onKeydown(va) { }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val && this.privType !== "RxEntry") {
            this.addNewPatientPrescDrugRx();
        } else {
            this.reDirectToRx();
        }
    }

    openOveride(category, type, endpoint?) {
        this.categoryId = category;
        this.privType = type;
        this.openOverride = true;
        this.overrideFor = category;
        this.overrideEndpoint = endpoint;
    }

    focusedOutofSearch() {
        this.FocusedOutofSearch.emit();
    }

    searchRx = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(this.debounceTime),
            distinctUntilChanged(),
            tap(() => (this.searching = true)),
            switchMap((term) => {
                if (term) {
                    let value = term;
                    if (typeof value === "string") {
                        value = value.trim();
                        if (value === "" || value === " ") {
                            value = null;
                        }
                    }
                    let type = null;
                    if (value && value.length > 0) {
                        if (value.indexOf(":") >= 0) {
                            type = value.split(":")[0];
                            type = type.replace(/[^A-Z0-9]/gi, "");
                            const typeVal = type.toLowerCase();
                            if (typeVal === "p") {
                                this.selectedValue = this.listItems[0];
                            } else if (typeVal === "pr") {
                                this.selectedValue = this.listItems[1];
                            } else if (typeVal === "d") {
                                this.selectedValue = this.listItems[2];
                            } else if (typeVal === "rx") {
                                this.selectedValue = this.listItems[3];
                            } else if (typeVal === "rf") {
                                this.selectedValue = this.listItems[4];
                            }

                            if (
                                typeVal === "pr" ||
                                typeVal === "d" ||
                                typeVal === "p" ||
                                typeVal === "rx" ||
                                typeVal === "rf"
                            ) {
                                value = value.split(":")[1];
                            }
                            this.inputValue = value;
                        }
                    }
                }
                setTimeout(() => {
                    document.getElementById("iSearchBox").focus();
                }, 10);
                return observableEmpty();
            }),
            tap(() => (this.searching = false))
        );

    getPrescriberById(presId: any) {
        this._commonServ
            .getRxPrescriberDetailsById(presId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp) => {
                if (resp) {
                    this.searchValue = null;
                    this.SelectedPrescriber.emit(resp);
                } else {
                    this.modelRef = this._modalService.open(
                        this.addPrescriber,
                        { keyboard: false, windowClass:"large--content", centered: true}
                    );
                    this.actModal = "addPrescriber";
                    // document.getElementById("addPrescrDefActn").focus();
                }
            });
    }

    getDrugById(drugId: any) {
        this._commonServ.getRxDrugDetailsById(drugId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp) => {
            if (resp) {
                this.searchValue = null;
                this.SelectedDrug.emit(resp);
            } else {
                this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                    keyboard: false,backdrop: false, windowClass:"large--content"
                });
                this.actModal = "addDrug";
                // document.getElementById("addDrugDefActn").focus();
            }
        });
    }

    keyTab($event) {
        this.OnEnter.emit($event);
    }

    searchByKeyWordOnEnter(e) {
        const data = this.searchByKeyWord(e.target.value);
        this.search = data;
        this.keyTab(e);
    }

    checkIsDOBorPatRx(rxNo) {
        let type = null;
        if (rxNo) {
            if (rxNo.indexOf(":") >= 0) {
                type = rxNo.split(":")[0];
                type = type.replace(/[^A-Z0-9]/gi, "");
                rxNo = rxNo.split(":")[1];
                if (type.toLowerCase().indexOf("p") >= 0) {
                    this.placholderText = "Patient";
                    this.option = this.listItems[0];
                    this.selectedValue = this.listItems[0];
                }
            }

            if (
                /^([0-9]{1,12})(\/)$/.test(rxNo) &&
                this.selectedValue === "Patient"
            ) {
                this.inputValue = rxNo;
                this.isByEnter = true;
                this._commonServ
                    .getPatientsuggestByRxNo(rxNo)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((resp) => {
                        if (resp) {
                            if (this.modelRef) {
                                this.modelRef.close();
                            }
                            this.reDirectToRx(resp);
                            // this.selectedPatientSuggest(resp);
                        }
                    });
            }
        }
    }

    getDrugStatus(drugInfo: any) {
        if (
            drugInfo.discontdt &&
            moment(drugInfo.discontdt).isSameOrBefore(this.todayDate) &&
            drugInfo.IsPreferred
        ) {
            return "card drug-card-red drug-card-brown";
        } else if (drugInfo["lastdispenseddate"] && drugInfo.ispreferred) {
            return "card drug-card-green drug-card-brown";
        } else if (
            drugInfo.discontdt &&
            moment(drugInfo.discontdt).isSameOrBefore(this.todayDate)
        ) {
            return "card drug-card-red";
        } else if (drugInfo["lastdispenseddate"]) {
            return "card drug-card-green";
        } else if (drugInfo.ispreferred) {
            return "card drug-card-brown";
        } else {
            return "card";
        }
    }

    equivalentDrugs(eqDrugs: any[], isEq: boolean) {
        if (isEq) {
            this.eqDrugHeading = "Equivalent Drugs";
        } else {
            this.eqDrugHeading = "Generic Equivalent Drugs";
        }
        if (eqDrugs && eqDrugs.length > 0 && this.searchBox) {
            this.showEqDrugs = true;
            this.EqDrugs = eqDrugs;
            this.searchBox.nativeElement.blur();
            this.selecteEqDrugRowId = 0;
        } else {
            this.EqDrugs = [];
            this.showEqDrugs = false;
        }
    }
    selectedEqDrug(drug) {
        if (this.pageName == "header") {
            this._router.navigate([this.drugRouterURL, "drug", drug.id]);
        } else if (this.drugRouterURL) {
            this._router.navigate([this.drugRouterURL, drug.id, "druginfo"]);
        }
        this.SuggestData = [];
        this.searchValue = null;
        this.SelectedDrug.emit(drug);
        this.selecInfo = drug &&  drug.drugname ? drug.drugname : "";
        this.inputValue = null;
        this.EqDrugs = [];
        this.showEqDrugs = false;
        if (this.instance) {
            this.instance.dismissPopup();
        }
    }

    inputClearedBySearchIcon() {
        this.inputValue = "";
        this.clearedInput.emit(this.inputValue);
        if (this.selectedValue === "Patient") {
            this.SelectedPatient.emit(null);
        } else if (this.selectedValue === "Prescriber") {
            this.SelectedPrescriber.emit(null);
        } else if (this.selectedValue === "Drug") {
            this.SelectedDrugFormBarcode.emit(false);
            this.SelectedDrug.emit(null);
        }
    }


    getBarcodeInfo(barocodeText) {
        if (barocodeText && barocodeText.length) {
            this.isFetchingBarcode = true;
            this._commonServ.getDrugByBarcode(barocodeText)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(barcodeDrug => {
                this.isFetchingBarcode = false;
                this.inputValue = "";
                if (barcodeDrug && barcodeDrug.length === 1) {
                    if (barcodeDrug[0].id) {
                        this.SelectedDrug.emit(barcodeDrug[0]);
                        this.SelectedDrugFormBarcode.emit(true);
                    } else {
                        this.isScannedDrugNotFound = false;
                        this.searchValue = barocodeText;
                        this.isFrmBarcodeScanner = true;
                        this.scannedDrugNDC = barcodeDrug?.[0]?.ndc;
                        this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                            keyboard: false, backdrop: false, windowClass: "large--content"
                        });
                        this.actModal = "addDrug";
                    }    
                } else {
                    this.isScannedDrugNotFound = true;
                    this.inputValue = null;
                    this.searchValue = null;
                    this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                        keyboard: false, backdrop: false, windowClass: "large--content"
                    });
                    this.actModal = "addDrug";
                }
            });
        }
    }

    checkInventoryMode() {
        this.isInventoryAdvancedMode = this._drugBuckUtil.isAdvancedMode();
    }

    async getBucketList(isFromInit: boolean = false) {
        this.drugBucketList = isFromInit ? await this._commonServ.bucketList$["source"]["value"] : await this._drugBuckUtil.getDrugBucketList(null);
        if (this.drugBucketList && this.drugBucketList.length) {
            this.bucketName = this.drugBucketList.filter((item: any) => {
                return (item.Id === this.DrugSuggFiltFG.value["BucketId"]);
            });
        }
    }

    createDrugSugFilAndPatFG() {
        this.DrugSuggFiltFG = this._drugBuckUtil.createDrugSuggestFilterFG();
        this._drugBuckUtil.patchDefaultDrugSuggFiltFG(this.DrugSuggFiltFG);
        if (this.isFrmErx) {
            this._drugBuckUtil.patchDefaultDrugFiltInErx(this.DrugSuggFiltFG);
        }
        this.emitDrugSuggestFilters();
    }

    patchPatFacilityBucket() {
    if (!this.singleBucketMode) {
        if (this.Is340BRx && +this.patFacilities[0]["Is340BucketId"]) {
            this.DrugSuggFiltFG.patchValue({
                BucketId: +this.patFacilities[0]["Is340BucketId"]
            });
        } else if (!this.Is340BRx && +this.patFacilities[0]["GeneralBucketId"]) {
            this.DrugSuggFiltFG.patchValue({
                BucketId: +this.patFacilities[0]["GeneralBucketId"]
            });
        } else if (this.Is340BRx) {
            this.DrugSuggFiltFG.patchValue({
                BucketId: this._drugBuckUtil.getDefault340BBucketId()
            });
        } else if (!this.Is340BRx) {
            this.DrugSuggFiltFG.patchValue({
                BucketId: this._drugBuckUtil.getDefaultBucketId()
            });
        }
    } else {
        this.DrugSuggFiltFG.patchValue({
            BucketId: this._drugBuckUtil.getDefaultBucketId()
        });
    }
        this.emitDrugSuggestFilters();
    }

    openDrugFilter() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        if(!this.isFromPhysicalInventoryScreen) {
            this.clearValue();
        }
        // this.closeDrugFilter();
       if(!this.IsHideDrugFilters) {
            this.getBucketList();
            this.modelRef = this._modalService.open(
                this.DrugSuggestFilter,
                {
                    backdrop: false,
                    keyboard: false,
                    centered : true
                }
            );
       }
    }

    closeAddDrugHasValue() {
        this.closeModal();
        this.addDrugHasValue = false;
        this.closeDrugFilter();
    }

    closeDrugFilter() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        if(!this.DrugSuggFiltFG.value["BucketId"]) {
            this.DrugSuggFiltFG.controls["BucketId"].patchValue(this._drugBuckUtil.getDefaultBucketId());
            this.displayBucketName();
        }
        this.modelRef = null;
        if(!this.isFromPhysicalInventoryScreen) {
            this.clearValue();
        }
        setTimeout(() => {
            if (this.searchBox) {
                this.searchBox.nativeElement.focus();
            }
        }, 200);
        this.emitDrugSuggestFilters();
    }

    emitDrugSuggestFilters() {
        if (this.isFrmErx) {
            this.emitDrugSugstFilterFrERx.emit(this.DrugSuggFiltFG.getRawValue());
        }
        if (this.isFrmRx) {
            this.emitDrugSugstFilterFrRx.emit(this.DrugSuggFiltFG.getRawValue());
        }
        this.emitDrugSuggestFilterss.emit(this.DrugSuggFiltFG.getRawValue());
    }

    checkInvPrivs() {
        return this._privMaskGuard.canActivate(AccCategoryE.Rx2, Rx2E.AllowChangingInventoryBucket);
    }
    toggleColorLegend() {
        this._alertSer.showHelpText({RxStatus: false, DrugName: false, Status: false, DrugForDrugFile : true,isNotAssociate: true});
    }
    saveAsDefault(){
       let post = {
        ShowDrugsOnlyWhichAreAddedToThisBucket: this.DrugSuggFiltFG.value["OnlyShowDrugsAvailableForBucket"] ? "1" : "0",
        ShowDrugsOnlyWithAvailableStockQty : this.DrugSuggFiltFG.value["OnlyDrugsHavingQtyInStock"] ? "1" : "0"
       }
        this._drugSvc.saveDrugSuggestFiltersAsDefault(post)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp=>{
            if(resp){
                this.closeDrugFilter();
            }
        })
    }
    refillWizardPopupClosed(){
        this.selectedValue = "Patient";
        this.placholderText = "Patient";
        setTimeout(() => {
            if (document.getElementById("Patient")) {
                document.getElementById("Patient").focus();
            }
        }, 1);
    }

    getSelectedDrug(x) {
        if (this.selectedValue === "Drug") {
            this.inputValue = true;
            setTimeout(()=> {
                this.inputValue = x.drugname;
            },0);
            this.SelectedDrug.emit(x);
            this.SelectedDrugFormBarcode.emit(false);
            this.selecInfo = x.drugname;
            if (this.isFrmAllergies)
                setTimeout(() =>{
                    this.inputValue = null;
                })
            this.searchValue = null;
            return this.searchFrmPage === "header" || this.searchFrmPage === "Dash" ? null : x.drugname;
        }
    }

    addNewDrug(event) {
        this.drugList = [];
        this.searchValue = event.searchValue;
        if (this.searchBox) {
            this._cdr.detectChanges();
            this.searchBox.nativeElement.focus();
        }
        if(this.searchFrmPage === "Dash") this.HideDashbrdStats.emit(true);        
        if(event.fromMMS) this.addNewPatientPrescDrug(event.fromMMS);
        else this.addNewPatientPrescDrug()
    }

    openDrugResultModal(result) {
        const modalRef = this._modalService.open(DrugResultCardComponent, {
            backdrop: false,
            size: "lg",
            keyboard: false,
            centered:true,
            windowClass: this.searchFrmPage === "commonDrugSearch" ? "md-xx-lg" : "mode_width_85" 
        });
        modalRef.componentInstance.DrugResultData = result;
        modalRef.componentInstance.IsPopUp = true;
        modalRef.componentInstance.IsFrmErx = this.isFrmErx;
        modalRef.componentInstance.DrugSuggestFilterFG = this.DrugSuggFiltFG;
        modalRef.componentInstance.bucketFrmPriceChck = this.bucketFrmPriceChck; 
        modalRef.componentInstance.searchFrmPage = this.searchFrmPage;
        modalRef.componentInstance.allowToAddNewDrug = this.allowToAddNewDrug;
        modalRef.componentInstance.searchTerm = this.searchTextValue;
        modalRef.componentInstance.updateDrugSuggFilter = this.updateDrugSuggFilter;
        modalRef.componentInstance.DrugBucketList = this.drugBucketList;
        modalRef.componentInstance.disableBuck = this.disableBuck;
        modalRef.componentInstance.DrugBucketList = this.drugBucketList;
        modalRef.componentInstance.SingleBucketMode = this.singleBucketMode;
        modalRef.componentInstance.IsScannedDrugNotFound = this.isScannedDrugNotFound;
        modalRef.componentInstance.GetSelectedDrug.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) this.getSelectedDrug(resp);
            if(this.searchFrmPage != "header" && this.searchFrmPage != "Dash")  modalRef.close();            
        });          
        modalRef.componentInstance.AddNewDrug.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) this.addNewDrug(resp);
            modalRef.close();
        });
        modalRef.componentInstance.GetDrugBarcodeInfo.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) this.getBarcodeInfo(resp);
            modalRef.close();
        });
        modalRef.componentInstance.CloseDrugResultModal.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) modalRef.close();
            if(this.searchFrmPage === "commonDrugSearch") this.CloseDrugSearch.emit(true);
        });
    }

    closeDrugPage(e){        
        if(e) {
            if (this.searchBox) {
                this._cdr.detectChanges();
                this.searchBox.nativeElement.focus();
            }
            this.drugList = [];
            this.HideDashbrdStats.emit(e);
        }
    }
}

  <div 
  [ngClass]="[(cssStyle ||IsAppendTo) ? 'custom--date default': 'form-group custom--input custom--date',
  errorMsg ? 'has-error' : ''
    ]">
    <label class="control-label" *ngIf="labelText">
        <span>{{labelText}}
            <span *ngIf="isRequired" class="help--text__danger">*</span>
            <span *ngIf="errorMsg && (errorMsg.includes('Order to fill date exceed Maximum Allowed of') ||
            errorMsg.includes('Filled Date cannot be less than Ordered Date.') || (errorMsg.includes('Expiry date cannot be current date or previous date.')))" class="help--text__danger ml-2" title="{{errorMsg}}"><i class="anime-shake far fa-exclamation-circle font_size_1_2rem"></i></span>
        </span>
    </label>
    <span *ngIf="isDisabled">
        <input class="form-control inactive font_weight_250" [ngClass]="cssStyle ? 'height_2_2rem pl-1':''"
        [ngModel]="dateValueModel" placeholder="MM/DD/YYYY" [tabindex]="tabIndex" [readonly]="true">
    </span>
    <span class="date-picker" *ngIf="!isDisabled" [ngClass]="IsAppendTo? 'expiration_date' : ''">
        <input type="text" [id]="rxID" [placeholder]="'MM/DD/YYYY'" autocomplete="off"
          [title]="titleText ? titleText : labelText" [required]="isRequired" [tabindex]="tabIndex"
          name="d2" #c2="ngModel" [ngModel]="dateValueModel" (ngModelChange)="onModelChange($event)"
          ngbDatepicker #d2="ngbDatepicker" headless=true [footerTemplate]="customFooter"
           (blur)="onBlurValue($event);" [maxDate]="maxDate" [minDate]="minDate"
           (keyup)="emitOnKeyDown($event)" [markDisabled]="isDisabled"
           [appAutofocus]="autoFocus"  [readOnly]="isReadOnly" maxlength=10 (focus)="foucsDatePicker()" (keyup.shift.tab)="onShiftTabbingEvent($event)"
           (dateSelect)="onDateSelect($event)" (keydown.Tab)="onTabEvent($event, d2)" (keyup.enter)="onTabEvent($event)" (keydown.alt.s)="onTabEvent($event, d2)"  (keydown.alt.t)="onTabEvent($event, d2)" (keydown.alt.f)="onTabEvent($event, d2)" (keydown.alt.h)="onTabEvent($event, d2)" (keydown.alt.a)="onTabEvent($event, d2)" (keydown.control.s)="onTabEvent($event, d2)" (keydown.control.d)="onTabEvent($event, d2)" (keydown.control.u)="onTabEvent($event, d2)" (keydown.control.o)="onTabEvent($event, d2)" [ngClass]="cssStyle ? 'form-control height_2_2rem pl-1' : 'form-control'"
         [container]="(cssStyle ||IsAppendTo) ? 'body' : ''">
        <div class="input-btn-group-append">
            <button [tabindex]="buttonTabIndex" class="btn btn-outline-secondary calendar" [ngClass]="cssStyle ? 'line_height_normal' : ''" (click)="d2.toggle()" (click)="(cssStyle || IsAppendTo) ? addClass() : ''" type="button">
                <i class="far fa-calendar-alt"></i>
            </button>
        </div>
    </span>
    <ng-template #customFooter let-date="date" let-disabled="disabled">
        <div class="text-center pb-1">
          <button class="btn btn-primary btn-sm" [disabled]="disabled" (click)="setCurrentDate()">Today : {{currentDate}}</button>
        </div>
      </ng-template>
    <span class="help-block line_height_0_9" *ngIf="errorMsg && !(errorMsg.includes('Order to fill date exceed Maximum Allowed of') ||
    errorMsg.includes('Filled Date cannot be less than Ordered Date.') || errorMsg.includes('Expiry date cannot be current date or previous date.'))">
        {{errorMsg === 'inValid'?'Invalid date':(errorMsg == "minDate" ? "Min date" : errorMsg)}}
    </span>
</div>

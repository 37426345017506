<div class="exprx--common-block erx--body insurance--body">
        <div class="eprx--block__header">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-9">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <span>Drug File</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-11 text-right">
                                            <button *ngIf="!hasAddDrugFilePrivs" id="addDrugbut" (click)="openOveride(null, null, 'Add','POST_Drug_New')" class="hotkey_success  hotkey_primary--Override"
                                            title="user has no privilege" appShortcutKey [AltKey]="'a'"><span>A</span>ADD DRUG</button>
                                            <button *ngIf="hasAddDrugFilePrivs" id="addDrugbut" class="hotkey_success hotkey_primary--Override" (click)="addDrug('Add')" title="Add Drug" appShortcutKey [AltKey]="'a'"><span>A</span>ADD DRUG</button>
                                        </div>
                                        <div class="col-1 padding--left__0">
                                            <app-user-guide-icon [ActiveTabId]= "'DrugFile'"></app-user-guide-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="eprx--block__content">
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <eprx-input [AutoFocus]="true" [RxSelectId]="'DrugName'" [LabelText]="'Drug Name'" [PlaceHolder]="'Search Drug Name'" [ControlName]="'drugname'"
                            [FormGroupName]="DrugSearchFG" [Title]="'First Name'" (TriggerOnEnterValue)="searchDrugData()">
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <eprx-input [LabelText]="'NDC'" [PlaceHolder]="'Search NDC'" [ControlName]="'ndc'" [FormGroupName]="DrugSearchFG"
                            [Title]="'NDC'" (TriggerOnEnterValue)="searchDrugData()"
                            >
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <eprx-input [LabelText]="'Quick Code'" [PlaceHolder]="'Search Quick Code'" [ControlName]="'quickcode'" [FormGroupName]="DrugSearchFG"
                            [Title]="'Quick Code'" (TriggerOnEnterValue)="searchDrugData()">
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 align-button text-right">
                        <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" *ngIf = "loadResp" (click)="searchDrugData()"  appShortcutKey [AltKey]="'f'">
                            <span>F</span>
                            Search
                        </button>
                        <button type="submit" class="inactive" *ngIf = "!loadResp" id="Preview" title="Search / Refresh" appShortcutKey [AltKey]="'f'">
                            <span>F</span>
                            Search
                      </button>
                        <button type="button" (keydown.tab)="focusOutFmCan($event)" class="hotkey_success pull-right" (click)="clear()" appShortcutKey [AltKey]="'l'">
                            <span>L</span>
                            clear
                        </button>
                    </div>
                    <div class="col-12 row">
                        <div class="col-3" *ngIf="(Is340BPharm$ | async)">
                            <eprx-check-box [LabelText]="'Show 340B Drugs Only'" [ControlName]="'Is340B'" [FormGroupName]="DrugSearchFG">
                            </eprx-check-box>
                        </div>
                        <div class="col-3">
                            <eprx-check-box [LabelText]="'Show Drugs With Available Stock Qty Only'" [FormGroupName]="DrugSearchFG"
                                [ControlName]="'OnlyDrugsHavingQtyInStock'">
                            </eprx-check-box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="loader" class="progress-bar-container" >
            <div class="progress-bar--common" [style.width]="progressValue + '%'"></div>
        </div>
        <div class="eprx--block__content" *ngIf="displayGrid" [ngClass]="loading ? 'mt-0' : ''">
            <div class="row">
                <div class="col-12 text-right" *ngIf="totalCount">
                    <button  class="submit print-med-guide background_orange" (click)="toggleColorLegend()">Color Legend</button>
                </div>
            </div>
            <wj-flex-grid #SearchDrug [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="searchDrugList" [isReadOnly]="true" [columnPicker]="'drugListWJ'" id="drugListId"
              [itemFormatter]="itemFormatter"  [selectionMode]="'Row'" [allowSorting]="true" [ngClass]="[isDrugSelectable ? 'wjm-grd-custom-height-48' : 'wjm-grd-custom-height-60']" (keydown)="addEventOnWijimo($event)" (keydown.enter)="editEventOnWijimo($event, SearchDrug)">

              <wj-flex-grid-column [header]="'Actions'" [width]="64" >
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                    <div *ngIf="!isDrugSelectable">
                        <i *ngIf="!hasChangDrugFilePrivs" (click)="openOveride(null, item.Id, 'Edit','PUT_Drug')" class="far  fa-edit  actions mr-2" title="user has no privilege"></i>
                        <i *ngIf="hasChangDrugFilePrivs" class="far  fa-edit  actions mr-2" title="Edit" (click)="openDrugInModal('edit',item.Id)"></i>
                        <i *ngIf="!hasDeltDrugFilePrivs" (click)="openOveride(content, item, 'Delete','DELETE_Drug')" class="far  fa-edit  actions " class="far fa-trash  actions danger-actve"
                            title="user has no privilege"></i>
                        <i *ngIf="hasDeltDrugFilePrivs" class="far fa-trash-alt  actions danger-actve" title="Delete" (click)="openPopUpModal(content, item);showDelete = true"></i>
                    </div>
                    <div *ngIf="isDrugSelectable">
                        <input type="radio" [id]="row.index" [name]="'PatientSelect'" (change)="
                        drugSelected( $event?.target?.checked, item, row.index ) " value="{{ row.index }}"  />
                    </div>
                </ng-template>
            </wj-flex-grid-column>

              <wj-flex-grid-column (initialized)="init(SearchDrug)" [header]="rxheader['hName']" [binding]="rxheader['hName']"
              [visible]="rxheader['isVisible']"
             [width]="rxheader['width']"
              *ngFor="let rxheader of wjHeaders; let i = index" >
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                        <div *ngIf="!(rxheader['hName'] === 'Actions')" [title]= "((item[rxheader.hName]+'')|uppercase)">
                            {{ item[rxheader['hName']] }}
                        </div>
                    </ng-template>
                    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                        <div title={{rxheader.hName}}>
                            {{ rxheader.hName }}
                        </div>
                    </ng-template>
                </wj-flex-grid-column>
                
                <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
            </wj-flex-grid>
            <app-eprx-pagination *ngIf="totalCount" [TotalCount]="totalCount" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)" [GridName]="'DrugSearchGrid'"
                [WijmoName]="flex" (OnFocusOut)="focusToFirst($event)" [PageNumber]="pageNumber" [PageSize]="pageSize"></app-eprx-pagination>
        </div>
    </div>
    <ng-template #content let-c="close" let-d="dismiss">
            <app-custom-modal (oncrossBtnClick)="c('Close click');showDelete = false">
                    <h4 header>{{warningText?.deleteHeaderTxt}}</h4>
                    <span body>{{warningText?.deleteConfrmMsg}}
                    </span>
                    <button footer autofocus  type="button" class="hotkey_primary" (click)="c('Close click');showDelete = false;" (click)="deleteDrug()" appShortcutKey InputKey="y"><b>Y</b> YES</button>
                    <button footer type="button" class="hotkey_primary" (click)="c('Close click');showDelete = false" appShortcutKey InputKey="n"> <b>N</b> NO</button>
                </app-custom-modal>

    </ng-template>


    <app-override-log *ngIf="openOverride"  [OverrideEndpoint]="overrideEndpoint" [PrivMask]="privType" [CatId]="categoryId" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>


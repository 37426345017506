import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
    Phone, Address, Organization, OrgAdds, OrgPh, InsuCarrier, InsuPlan,
    InsuTransmission, SpecialProgram, City, State, InsuPlanPrice, InsuPlanOther, InsuTransOther, InsuBillFileSetting, OtherPayor
} from "../models";
import { InsuranceStore } from "../store/insurance.store";
import { CollectionView } from "@grapecity/wijmo";
import { Observable, forkJoin } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { cntrlsNotToPatch } from "../modules/insurance-ref/insurance.constants";
import { InsuranceService } from "../services/insurance.service";
import { NewAuditUtils } from "./new-audit.util";
import { AlertService } from "../services/alert.service";
import { CommonUtil } from "./common.util";
import { CommonService } from "../services";
import { InsuranceAuditModel } from "src/app/models";

@Injectable({
    providedIn: "root"
})

export class InsuranceUtil {
    stateCityCntrlNames = {
        State: { StateId: "Id", StateName: "Name", CityId: "CityId" },
        City: { StateId: "StateId", CityName: "Name", CityId: "Id" }
    };
    constructor(private _fb: FormBuilder, private _insuService: InsuranceService,
        private _insuStore: InsuranceStore, private _altSvc: AlertService, private _commonUtil: CommonUtil, private _commonServ: CommonService, private _newAuditUtils: NewAuditUtils) { }

    createInsuFG() {
        return this._fb.group({
            Phone: this._fb.group(new Phone()),
            Address: this._fb.group(new Address()),
            Organization: this._fb.group(new Organization()),
            OrgAdds: this._fb.group(new OrgAdds()),
            OrgPh: this._fb.group(new OrgPh()),
            InsuCarrier: this._fb.group(new InsuCarrier()),
            InsuPlan: this._fb.group(new InsuPlan()),
            InsuTransmission: this._fb.group(new InsuTransmission()),
            SpecialProgram: this._fb.group(new SpecialProgram()),
            IsDocumentExists: [false]
        });
    }

    async getDropDownData() {
        const data = this._insuService.dropdownData$["source"]["value"];
        return data ? data : await this._insuService.getInsuranceDropdowns().toPromise();
    }

    async getInsuranceInfo(Id) {
        return await this._insuService.getEditInsuranceInformation(Id).toPromise();
    }

    patchPlanInfoDefVal(formGroupName) {
        const insuTransFG: any = formGroupName.controls["InsuTransmission"];
        const insuPlanFG: any = formGroupName.controls["InsuPlan"];
        insuTransFG.patchValue({
            InsuXmitSubCatId: 2,
            NCPDPVer: "D0"
        });
        insuPlanFG.patchValue({
            IsMedicareSup: false,
            IsMedigapSup: false,
            IsMedicaidSup: false,
            IsActive: true
        });
        this.makeStateCityNull();
    }

    async patchPlanInfo(InsuInfo, formGroupName) {
        for (const key in InsuInfo) {
            if (key && InsuInfo[key]) {
                if (key === "InsuTransmission") {
                    InsuInfo[key]["InsuXmitSubCatId"] = InsuInfo[key]["InsuXmitSubCatId"] ? InsuInfo[key]["InsuXmitSubCatId"] : 2;
                    InsuInfo[key]["NCPDPVer"] = InsuInfo[key]["NCPDPVer"] ? InsuInfo[key]["NCPDPVer"] : "D0";
                }
                    formGroupName.controls[key].patchValue(InsuInfo[key]);
            }
        }
        InsuInfo.Address ? this.patchCityAndStateValue(InsuInfo.Address, formGroupName) : this.makeStateCityNull();
    }

    makeStateCityNull() {
        this._insuStore.storeInsuState(null);
        this._insuStore.storeInsuCity(null);
    }

    async patchCityAndStateValue(data, formGroup, type?: any) {
        const resp = await this._commonUtil.patchStateAndCity(data, formGroup, type ? type : null);
        if (!type || type === "State") {
            this._insuStore.storeInsuState(resp["state"]);
        }
        this._insuStore.storeInsuCity(resp["city"]);
    }

    async getInsuMMSInfo(data) {
        return await this._insuService.getMMSData(data.BinNum, data.ProcessorNum).toPromise();
    }

    generateMMSWIJIMO(data) {
        return new CollectionView(
            data.map((insurance, i) => {
                const j = {};
                j["row"] = i;
                j["Ins.Code"] = insurance.IC_CODE;
                j["Ins.Name"] = insurance.IC_NAME;
                j["Address"] = insurance.ADDRESS;
                j["Phone#"] = insurance.PHONE_V;
                j["Pharamacy Id#"] = insurance.PHARM_NO;
                j["Processor Ctrl"] = insurance.MAG_CODE;
                j["Bin#"] = insurance.BIN_NO;
                j["Notes"] = insurance.NOTES;
                return j;
            })
        );
    }

    createInsuStngFG() {
        return this._fb.group({
            InsuPlanPrice: this._fb.group(new InsuPlanPrice()),
            InsuPlanOther: this._fb.group(new InsuPlanOther()),
            IsOrderToLastfillchanged: false,
            IsOrderToFirstfillchanged: false
        });
    }

    async getInsuStngInfo(Id) {
        return await this._insuService.getInsSettingsInfo(Id).toPromise();
    }

    patchInsuStngsInfo(stngInfo, formGroupName) {
        const cntrlsFrStringCnvt = ["GenPricePer", "BrandPricePer", "CoPayBrandAmnt",
            "TaxRatePer", "MaxCoPayAmnt", "ProFee", "CoPayGenAmnt", "FlatSalesTaxAmt"];
        cntrlsFrStringCnvt.map(subcntrl => {
            if (stngInfo && stngInfo["InsuPlanPrice"]) {
                const data = stngInfo["InsuPlanPrice"][subcntrl];
                stngInfo["InsuPlanPrice"][subcntrl] = data ? (parseFloat(data)).toFixed(2) : "0.00";
            }
        });
        if (stngInfo["InsuPlanPrice"]) {
            stngInfo["InsuPlanPrice"]["IsPartialFillAllowed"] = stngInfo["InsuPlanPrice"]["IsPartialFillAllowed"] === null ?
            false : stngInfo["InsuPlanPrice"]["IsPartialFillAllowed"];
        }
        stngInfo["InsuPlanOther"]["MaxQty"] = stngInfo["InsuPlanOther"] && stngInfo["InsuPlanOther"]["MaxQty"] ?
            (parseFloat(stngInfo["InsuPlanOther"]["MaxQty"])).toFixed(3) : "0.000";
        for (const key in stngInfo) {
            if (key && stngInfo[key] && formGroupName.controls[key]) {
                formGroupName.controls[key].patchValue(
                    stngInfo[key]
                );
            }
        }
    }

    createTransStngFG() {
        return this._fb.group(new InsuTransOther());
    }

    async getTransStngInfo(Id) {
        return await this._insuService.getTransSettingsInfo(Id).toPromise();
    }

    patchTransStngInfo(transStngData, formGroupName) {
        if (transStngData) {
            formGroupName.patchValue(transStngData);
        }
    }

    createInsuBillFG() {
        return this._fb.group(new InsuBillFileSetting());
    }

    async getInsuBillInfo(Id) {
        return await this._insuService.getInsBillingInfo(Id).toPromise();
    }

    patchInsuBillInfo(insuBillData, formGroupName) {
        formGroupName.patchValue(insuBillData);
    }

    async getOtherInsuList(value) {
        return await this._insuService.getOtherInsInfo(value).toPromise();
    }

    async getOtherInsuData(value) {
        const response: any = await this.getOtherInsuList(value);
        let itemSource = null;
        let totalCount = 0;
        if (response && response["length"] > 0) {
            totalCount = response[0].TotalRecords;
            itemSource = new CollectionView(
                response.map((insurance) => {
                    const j = {};
                    j["Id"] = insurance.Id;
                    j["InsuPlanId"] = insurance.InsuPlanId;
                    j["Other Insurance"] = insurance.OtherInsuId;
                    j["Payer Id"] = insurance.PayerCode;
                    j["Qualifier"] = insurance.Qualifier;
                    j["QualifierId"] = insurance.PayerQualifId;
                    j["CreatedDtTm"] = insurance.CreatedDtTm;
                    return j;
                })
            );
        }
        return { result: response, wjSource: itemSource, count: totalCount };
    }

    createOtherInsuFG() {
        return this._fb.group(new OtherPayor());
    }

    searchOtherInsu = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(750),
            distinctUntilChanged(),
            switchMap(term => this.searchOtherInsuID(term))
        );

    async searchOtherInsuID(value: string) {
        const searchTerms = value.split(",");
        let filteredData = [];
        let SuggestData: any;
        if (value) {
            SuggestData = await this._insuService.OtherInsuSuggest(value).toPromise();
            if (SuggestData) {
                let PayerIdData = SuggestData.filter(v => {
                    let val = v["PayerId"];
                    return (val.toString().toLowerCase().startsWith(searchTerms[0].toLocaleLowerCase()))
                });
                PayerIdData.sort((a, b) => (a.PayerId > b.PayerId) ? 1 : ((b.PayerId > a.PayerId) ? -1 : 0));
                filteredData = filteredData.concat(PayerIdData);
            } else {
                filteredData = [];
            }
            if (!filteredData || (filteredData && filteredData.length === 0)) {
                filteredData = [];
                this._altSvc.inputFocus = "insSearchInp";
                this._altSvc.error("Insurance does not exist. Please select another one.");
            }
        } else {
            filteredData = [];
        }
        return filteredData;
    }

    async addOtherInsu(data) {
        return await this._insuService.addOtherInsuPayer(data).toPromise();
    }

    async editOtherInsu(data) {
        if (data) {
            data["PayerQualifId"] = !data["PayerQualifId"] ? null : data["PayerQualifId"];
        }
        return await this._insuService.editOtherInsuPayer(data).toPromise();
    }

    async deleteOtherInsu(data) {
        return await this._insuService.deleteOtherInsInfo(data).toPromise();
    }

    async CompleteInsuInfo(Id, planInfoFG, stngFG, transStngFG) {
        let resp = await forkJoin([this.getInsuranceInfo(Id), this.getInsuStngInfo(Id), this.getTransStngInfo(Id)]).toPromise();
        let response = null;
        resp = this.resetIdToPrev(resp, [planInfoFG.value, stngFG.value, transStngFG.value]);
        if (resp) {
            response = await forkJoin([this.patchPlanInfo(resp[0], planInfoFG), this.patchInsuStngsInfo(resp[1], stngFG),
            this.patchTransStngInfo(resp[2], transStngFG)]);
        }
        return response;
    }

    resetIdToPrev(data, prevData) {
        data.map((fg, i) => {
            if (fg) {
                Object.keys(fg).map(Cntrl => {
                    if (!(Cntrl === "IsDocumentExists" || i === 2) && fg[Cntrl]) {
                        Object.keys(fg[Cntrl]).map(subCntrl => {
                            if (cntrlsNotToPatch.indexOf(subCntrl) > -1) {
                                fg[Cntrl][subCntrl] = prevData[i][Cntrl][subCntrl];
                            }
                        });
                    } else if (i === 2) {
                        if (cntrlsNotToPatch.indexOf(Cntrl) > -1) {
                        fg[Cntrl] = prevData[i][Cntrl];
                        }
                    } else if (!fg[Cntrl]) {
                        let dataFg;
                        if(Cntrl === "Address" ) {
                            dataFg = this._fb.group(new Address());
                        } else if(Cntrl === "OrgAdds" ) {
                            dataFg = this._fb.group(new OrgAdds());
                        } else if(Cntrl === "OrgPh" ) {
                            dataFg = this._fb.group(new OrgPh());
                        } else if(Cntrl === "Phone" ) {
                            dataFg = this._fb.group(new Phone());
                        }
                        fg[Cntrl] = (dataFg) ? dataFg.value : null;
                    }
                });
            }
        });
        return data;
    }

    async updateInsuranceInfo(updateSvc) {
        return await forkJoin(updateSvc).toPromise();
    }

    async addinsuranceInfo(data, dropdownsData) {
        const newData = this.checkAndRemoveParam(data);
        const resp = await this._insuService.addInsuranceInfo(newData).toPromise();
        if (resp > 0 && !(resp === 1 || resp === 2)) {
            const insuDataModel = new InsuranceAuditModel().planInfo;
            this._newAuditUtils.saveAuditOnAdd(
                newData,
                "Add Insurance",
                "Insurance",
                resp,
                insuDataModel,
                dropdownsData
            );
        }
        return resp;
    }

    callAuditMethod(updatedSeq, values, insuId, dropdownsData) {
        const insuDataModel = new InsuranceAuditModel().planInfo;
        updatedSeq.map(async tabName => {
            if (tabName === "transStng" || tabName === "insuBill") {
                let oldObj = (tabName === "transStng") ? { "InsuTransOther": values[tabName][2] } : { "InsuBillFileSetting": values[tabName][2] };
                let newObj = (tabName === "transStng") ? { "InsuTransOther": values[tabName][0].value } : { "InsuBillFileSetting": values[tabName][0].value };
                this._newAuditUtils.saveAuditChange(
                    oldObj, // original
                    newObj, // changed
                    "Edit Insurance",
                    "Insurance",
                    insuId,
                    insuDataModel,
                    false,
                    dropdownsData
                );
            } else {
                this._newAuditUtils.saveAuditChange(
                    values[tabName][2], // original
                    values[tabName][0].value, // changed
                    "Edit Insurance",
                    "Insurance",
                    insuId,
                    insuDataModel,
                    false,
                    dropdownsData
                );
            }
        });
    }

    insuCodeExist(formGroupName) {
        formGroupName.controls["InsuCarrier"].patchValue({ InsurerCode: null });
        this._altSvc.error("Insurance Code Already Exists. Please enter a different one.", false, "long");
    }
    checkAndRemoveParam(data){
        data.Address.TenantId = null;
        data.Address.IsActive = null;
        data.Address.ModifiedDtTm = null;
        data.Address.CreatedDtTm = null;
        data.InsuCarrier.TenantId = null;
        data.InsuPlan.TenantId = null;
        data.InsuPlan.ModifiedDtTm = null;
        data.InsuPlan.CreatedDtTm = null;
        data.Organization.TenantId = null;
        data.Organization.IsActive = null;
        data.Organization.ModifiedDtTm = null;
        data.Organization.CreatedDtTm = null;
        data.Phone.TenantId = null;
        data.Phone.IsActive = null;
        data.Phone.ModifiedDtTm = null;
        data.Phone.CreatedDtTm = null;
        data.SpecialProgram.TenantId = null;
        data.SpecialProgram.IsActive = null;
        return data;
    }

    patchStngMMSValue(mmsInfo, dropdownValue, priceDrop) {
        const data = {
            stngValue : [
                {
                    MMsvalue: 5,
                    ControlName: "PrescIdCatId",
                    keyName: "InsuPlanOther"
                },
                {
                    MMsvalue: 5,
                    ControlName: "PrimePrescIdCatId",
                    keyName: "InsuPlanOther"
                },
                {
                    MMsvalue: mmsInfo.MDSUPPLY ? mmsInfo.MDSUPPLY : 30,
                    ControlName: "MaxDaysSupply",
                    keyName: "InsuPlanOther"
                },
                {
                    MMsvalue: mmsInfo.MAXQTY ? mmsInfo.MAXQTY : 99,
                    ControlName: "MaxQty",
                    keyName: "InsuPlanOther"
                },
                {
                    MMsvalue: mmsInfo.MDOTOF ? mmsInfo.MDOTOF : 180,
                    ControlName: "OrderToFillDays",
                    keyName: "InsuPlanOther"
                },
                {
                    MMsvalue: mmsInfo.MREFILL ? mmsInfo.MREFILL : 5,
                    ControlName: "MaxRefills",
                    keyName: "InsuPlanOther"
                },
                {
                    MMsvalue: mmsInfo.MDREFILL ? mmsInfo.MDREFILL : 180,
                    ControlName: "OrderToLastRefill",
                    keyName: "InsuPlanOther"
                },
                {
                    MMsvalue: mmsInfo.REPRICE_RF && mmsInfo.REPRICE_RF === "Y" ? true : false,
                    ControlName: "IsRefillRepriced",
                    keyName: "InsuPlanOther"
                },
                {
                    MMsvalue: (mmsInfo.SORTORDER && mmsInfo.SORTORDER === "R") || !mmsInfo.SORTORDER ||
                    (mmsInfo.SORTORDER && mmsInfo.SORTORDER.trim() === "") ? true : false,
                    ControlName: "IsSortByRx",
                    keyName: "InsuPlanOther"
                },
                {
                    MMsvalue: mmsInfo.WORKMAN_C && mmsInfo.WORKMAN_C === "Y" ? true : false,
                    ControlName: "IsWorkmanComp",
                    keyName: "InsuPlanOther"
                },
                // {
                //     MMsvalue: mmsInfo.RXSERREQ && mmsInfo.RXSERREQ === "Y" ? true : false,
                //     ControlName: "IsRxSNReqd",
                //     keyName: "InsuPlanOther"
                // },
                {
                    MMsvalue: mmsInfo.RXSERREQ && mmsInfo.RXSERREQ === "Y" ? true : false,
                    ControlName: "CompoundIngrcandiffer",
                    keyName: "InsuPlanOther"
                },
                {
                    MMsvalue: (mmsInfo.RPHIDTOSUBMIT && mmsInfo.RPHIDTOSUBMIT === "N") || !(mmsInfo.RPHIDTOSUBMIT)
                    ||  (mmsInfo.RPHIDTOSUBMIT && mmsInfo.RPHIDTOSUBMIT.trim() === "") ?
                     this.getIdFromCode(dropdownValue, "PharmacistIdCat", "Name", "NPI#") : null,
                    ControlName: "RPhIdCatId",
                    keyName: "InsuPlanOther"
                },

                {
                    MMsvalue: mmsInfo.COPAY ? (parseFloat(mmsInfo.COPAY)).toFixed(2) : "0.00",
                    ControlName: "CoPayBrandAmnt",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.COPAY_G ? (parseFloat(mmsInfo.COPAY_G)).toFixed(2) : "0.00",
                    ControlName: "CoPayGenAmnt",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.PROF_FEE ? (parseFloat(mmsInfo.PROF_FEE)).toFixed(2) : "0.00",
                    ControlName: "ProFee",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.INSPERCOFF ? (parseFloat(mmsInfo.INSPERCOFF)).toFixed(2) : "0.00",
                    ControlName: "BrandPricePer",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.INSPERCOFF_G ? (parseFloat(mmsInfo.INSPERCOFF_G)).toFixed(2) : "0.00",
                    ControlName: "GenPricePer",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.TaxRate ? (parseFloat(mmsInfo.TaxRate)).toFixed(2) : "0.00",
                    ControlName: "TaxRatePer",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.COSTBASIS ?
                     this.getIdFromCode(dropdownValue, "CostBasis", "Name", mmsInfo.COSTBASIS) : null,
                    ControlName: "RPhIdCatId",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: this.getIdFromCode(null, priceDrop, "PriceCode",
                     (["1" , "1 "].indexOf(mmsInfo.PRICE_CD) > -1 || !mmsInfo.PRICE_CD ||
                     (mmsInfo.PRICE_CD && mmsInfo.PRICE_CD.trim() === "")) ? "01" : mmsInfo.PRICE_CD),
                    ControlName: "BrandPriceSchId",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: this.getIdFromCode(null, priceDrop, "PriceCode",
                     (["1" , "1 "].indexOf(mmsInfo.PRICE_CDG) > -1 || !mmsInfo.PRICE_CDG ||
                     (mmsInfo.PRICE_CDG && mmsInfo.PRICE_CDG.trim() === "")) ? "01" : mmsInfo.PRICE_CDG),
                    ControlName: "GenPriceSchId",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: this.getIdFromCode(dropdownValue, "SalesTaxBasis", "Remarks",
                      mmsInfo.PercSalesTaxBasis ? mmsInfo.PercSalesTaxBasis : "02"),
                    ControlName: "SalesTaxBasisId",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.COPAYTYPE && mmsInfo.COPAYTYPE === "R" ? 1 : 2,
                    ControlName: "CoPayCatId",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.AcceptAssignments && mmsInfo.AcceptAssignments === "Y" ? true : false,
                    ControlName: "IsAssigntAccepted",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.PFEE_OTC && mmsInfo.PFEE_OTC === "Y" ? true : false,
                    ControlName: "IsPFForOTC",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.CalculateTaxOnOTC && mmsInfo.CalculateTaxOnOTC === "Y" ? true :
                    mmsInfo.CalculateTaxOnOTC && mmsInfo.CalculateTaxOnOTC === "N" ? false : true,
                    ControlName: "IsTaxOnOTC",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.CalculateTaxOnRX && mmsInfo.CalculateTaxOnRX === "Y" ? true :
                    mmsInfo.CalculateTaxOnRX && mmsInfo.CalculateTaxOnRX === "N" ? false : true,
                    ControlName: "IsTaxOnRx",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: false,
                    ControlName: "IsUNCSame",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.AllowZeroBilling && mmsInfo.AllowZeroBilling === "Y" ? true : false,
                    ControlName: "IsZeroBillAllowed",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.AllowZeroUC && mmsInfo.AllowZeroUC === "Y" ? true : false,
                    ControlName: "IsZeroUNC",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: mmsInfo.MAXPATPAYAMOUNT ? (parseFloat(mmsInfo.MAXPATPAYAMOUNT)).toFixed(2) : "0.00",
                    ControlName: "MaxCoPayAmnt",
                    keyName: "InsuPlanPrice"
                },
                {
                    MMsvalue: this.getIdFromCode(dropdownValue, "CostBasis", "Name",
                    mmsInfo.COSTBASIS ? mmsInfo.COSTBASIS : "02"),
                    ControlName: "CostBasisId",
                    keyName: "InsuPlanPrice"
                }

            ],
            trans : [
                {
                    MMsvalue: mmsInfo.SET51ID ?
                    this.getIdFromCode(dropdownValue, "NCPDP", "Code", mmsInfo.SET51ID.trim()) : null,
                    ControlName: "NCDPId",
                },
                {
                    MMsvalue: mmsInfo.SVENDOR_CC ? mmsInfo.SVENDOR_CC.trim() : null,
                    ControlName: "VendCert",
                },
                {
                    MMsvalue: mmsInfo.TBATCHQTY ? mmsInfo.TBATCHQTY : null,
                    ControlName: "BatchQty",
                },
                {
                    MMsvalue: mmsInfo.COBPAYAPDQ ? mmsInfo.COBPAYAPDQ : null,
                    ControlName: "COBHCReqQualif",
                },
                {
                    MMsvalue: mmsInfo.DEFOTHCOVCODE ? mmsInfo.DEFOTHCOVCODE : null,
                    ControlName: "DefOthrCovCode",
                },
                {
                    MMsvalue: mmsInfo.PLACEOFSERVICE ? mmsInfo.PLACEOFSERVICE : null,
                    ControlName: "DefServPlace",
                },
                {
                    MMsvalue: mmsInfo.FORCECOBFORSECBILL ? mmsInfo.FORCECOBFORSECBILL : null,
                    ControlName: "ForceCoBSeg",
                },
                {
                    MMsvalue: mmsInfo.OTHPAYERCOVCODE ? mmsInfo.OTHPAYERCOVCODE : null,
                    ControlName: "OthrPayorCovCode",
                },
                {
                    MMsvalue: mmsInfo.PAYOR ? mmsInfo.PAYOR : null,
                    ControlName: "PayorNum",
                },
                {
                    MMsvalue: mmsInfo.RECV_ID ? mmsInfo.RECV_ID : null,
                    ControlName: "ReceiverNum",
                },
                {
                    MMsvalue: mmsInfo.SendLZForC8 && mmsInfo.SendLZForC8 === "Y" ? true : false,
                    ControlName: "IsLeadingZeroSent",
                },
                {
                    MMsvalue: mmsInfo.OCC8OPPRA && mmsInfo.OCC8OPPRA === "Y" ? true : false,
                    ControlName: "IsOPPRAFoced",
                },
                {
                    MMsvalue: mmsInfo.SendC8WhenBlank && mmsInfo.SendC8WhenBlank === "Y" ? true : false,
                    ControlName: "IsOthConvCodeSent",
                },
                {
                    MMsvalue: mmsInfo.PART_FILL && mmsInfo.PART_FILL === "Y" ? true : false,
                    ControlName: "IsPartialFill",
                },
                {
                    MMsvalue: mmsInfo.PA_SUPTYPE && mmsInfo.PA_SUPTYPE === "Y" ? true : false,
                    ControlName: "IsPASupport",
                },
                {
                    MMsvalue: mmsInfo.PATPAIDASCOPAY && mmsInfo.PATPAIDASCOPAY === "Y" ? true : false,
                    ControlName: "IsPatPaidAsCopay",
                },
                {
                    MMsvalue: mmsInfo.OPPRAAmtQ06 && mmsInfo.OPPRAAmtQ06 === "Y" ? true : false,
                    ControlName: "IsQualifForced",
                },
                {
                    MMsvalue: mmsInfo.B3SUPPORT && mmsInfo.B3SUPPORT === "Y" ? true : false,
                    ControlName: "IsRebillSupport",
                },
                {
                    MMsvalue: mmsInfo.SendDKWhenBlank && mmsInfo.SendDKWhenBlank === "Y" ? true : false,
                    ControlName: "IsSubClarifCodeSent",
                },
                {
                    MMsvalue: mmsInfo.COBProcMethod ?
                    this.getIdFromCode(dropdownValue, "COBMethod", "Name", mmsInfo.COBProcMethod) : null,
                    ControlName: "COBMethodId",
                },
            ]
        };
        return data;
    }

    getIdFromCode(table, tName, CName, value) {
        let result = null;
        if (table && table[tName] && table[tName].length) {
            const filterVal = table[tName].find(v => v[CName] === value);
            result = filterVal ? filterVal["Id"] : null;
        } else if (!table && tName && tName.length) {
            const filterVal = tName.find(v => v[CName] === value);
            result = filterVal ? filterVal["Id"] : null;
        }
        return result;
    }

    generateInsuAddModel(stngInfo, TransInfo, mmsInfo) {
        return [
            {
                currentvalue: stngInfo.InsuPlanPrice ? stngInfo.InsuPlanPrice.IsAssigntAccepted : false,
                MMsvalue: mmsInfo.AcceptAssignments && mmsInfo.AcceptAssignments.trim() === "Y" ? true : false,
                ControlName: "IsAssigntAccepted",
                keyName: "InsuPlanPrice"
            },
            {
                currentvalue: stngInfo.InsuPlanPrice ? stngInfo.InsuPlanPrice.IsZeroBillAllowed : false,
                MMsvalue: mmsInfo.AllowZeroBilling && mmsInfo.AllowZeroBilling.trim() === "Y" ? true : false,
                ControlName: "IsZeroBillAllowed",
                keyName: "InsuPlanPrice"
            },
            {
                currentvalue: stngInfo.InsuPlanPrice ? stngInfo.InsuPlanPrice.CoPayBrandAmnt : 0,
                MMsvalue: mmsInfo.COPAY,
                ControlName: "CoPayBrandAmnt",
                keyName: "InsuPlanPrice"
            },
            {
                currentvalue: stngInfo.InsuPlanPrice ? stngInfo.InsuPlanPrice.CoPayGenAmnt : 0,
                MMsvalue: mmsInfo.COPAY_G,
                ControlName: "CoPayGenAmnt",
                keyName: "InsuPlanPrice"
            },
            {
                currentvalue: stngInfo.InsuPlanPrice ? stngInfo.InsuPlanPrice.MaxCoPayAmnt : 0,
                MMsvalue: mmsInfo.MAXPATPAYAMOUNT,
                ControlName: "MaxCoPayAmnt",
                keyName: "InsuPlanPrice"
            },
            {
                currentvalue: stngInfo.InsuPlanPrice ? stngInfo.InsuPlanPrice.ProFee : 0,
                MMsvalue: mmsInfo.PROF_FEE,
                ControlName: "ProFee",
                keyName: "InsuPlanPrice"
            },
            {
                currentvalue: stngInfo.InsuPlanPrice ? stngInfo.InsuPlanPrice.IsTaxOnRx : false,
                MMsvalue: mmsInfo.CalculateTaxOnRX && mmsInfo.CalculateTaxOnRX.trim() === "Y" ? true : false,
                ControlName: "IsTaxOnRx",
                keyName: "InsuPlanPrice"
            },
            {
                currentvalue: stngInfo.InsuPlanPrice ? stngInfo.InsuPlanPrice.IsTaxOnOTC : false,
                MMsvalue: mmsInfo.CalculateTaxOnOTC && mmsInfo.CalculateTaxOnOTC.trim() === "Y" ? true : false,
                ControlName: "IsTaxOnOTC",
                keyName: "InsuPlanPrice"
            },
            {
                currentvalue: stngInfo.InsuPlanPrice ? stngInfo.InsuPlanPrice.IsPFForOTC : false,
                MMsvalue: mmsInfo.PFEE_OTC && mmsInfo.PFEE_OTC.trim() === "Y" ? true : false,
                ControlName: "IsPFForOTC",
                keyName: "InsuPlanPrice"
            },
            {
                currentvalue: stngInfo.InsuPlanOther ? stngInfo.InsuPlanOther.CompoundIngrcandiffer : false,
                MMsvalue: mmsInfo.CompValDifferClaims && mmsInfo.CompValDifferClaims.trim() === "Y" ? true : false,
                ControlName: "CompoundIngrcandiffer",
                keyName: "InsuPlanOther"
            },
            {
                currentvalue: stngInfo.InsuPlanOther ? stngInfo.InsuPlanOther.MaxQty : 0,
                MMsvalue: mmsInfo.MAXQTY,
                ControlName: "MaxQty",
                keyName: "InsuPlanOther"
            },
            {
                currentvalue: stngInfo.InsuPlanOther ? stngInfo.InsuPlanOther.OrderToFillDays : 0,
                MMsvalue: mmsInfo.MDOTOF,
                ControlName: "OrderToFillDays",
                keyName: "InsuPlanOther"
            },
            {
                currentvalue: stngInfo.InsuPlanOther ? stngInfo.InsuPlanOther.OrderToLastRefill : 0,
                MMsvalue: mmsInfo.MDREFILL,
                ControlName: "OrderToLastRefill",
                keyName: "InsuPlanOther"
            },
            {
                currentvalue: stngInfo.InsuPlanOther ? stngInfo.InsuPlanOther.MaxDaysSupply : 0,
                MMsvalue: mmsInfo.MDSUPPLY,
                ControlName: "MaxDaysSupply",
                keyName: "InsuPlanOther"
            },
            {
                currentvalue: stngInfo.InsuPlanOther ? stngInfo.InsuPlanOther.MaxRefills : 0,
                MMsvalue: mmsInfo.MREFILL,
                ControlName: "MaxRefills",
                keyName: "InsuPlanOther"
            },
        ];
    }

    generateInsuMMSModel(insuInfo, mmsInfo) {
        return  [
            {
                head: "INSUCARRIER CODE",
                currentvalue: insuInfo.InsuCarrier ? insuInfo.InsuCarrier.InsurerCode : " ",
                MMsvalue: mmsInfo.IC_CODE,
                link: mmsInfo.IC_CODE ? "USE NAME" : " ",
                ControlName: "InsurerCode",
                keyName: "InsuCarrier"
            },
            {
                head: "INSUCARRIER NAME",
                currentvalue: insuInfo.Organization ? insuInfo.Organization.Name : " ",
                MMsvalue: mmsInfo.IC_NAME,
                link: mmsInfo.IC_NAME ? "USE NAME" : " ",
                ControlName: "Name",
                keyName: "Organization"
            },
            {
                head: "ADDRESS",
                currentvalue: insuInfo.Address ? insuInfo.Address.AddressLine1 : " ",
                MMsvalue: mmsInfo.ADDRESS,
                link: mmsInfo.ADDRESS ? "USE ADDRESS" : " ",
                ControlName: "AddressLine1",
                keyName: "Address"
            },
            {
                head: "TELEPHONE VOICE",
                currentvalue: insuInfo.Phone ? insuInfo.Phone.xPhone : "",
                MMsvalue: mmsInfo.PHONE_V ? ((mmsInfo.PHONE_V.trim().replace(/-/g,"").length > 10) ? mmsInfo.PHONE_V.slice(1) : mmsInfo.PHONE_V) : "",
                link: mmsInfo.PHONE_V ? "USE VOICE" : " ",
                ControlName: "xPhone",
                keyName: "Phone"
            },
            {
                head: "PHARMACY ID",
                currentvalue: insuInfo.InsuPlan
                    ? insuInfo.InsuPlan.PharmacyNum
                    : "",
                MMsvalue: this._commonServ.getSetttingValue("TenantSettings", "TNT_NPI_NO") ?
                        (this._commonServ.getSetttingValue("TenantSettings", "TNT_NPI_NO")).trim() : "",
                link: mmsInfo.PHARM_NO ? "USE PHARMACY" : " ",
                ControlName: "PharmacyNum",
                keyName: "InsuPlan"
            },
            {
                head: "PROCESSOR ID #",
                currentvalue: insuInfo.InsuPlan ? insuInfo.InsuPlan.ProcessorNum : "",
                MMsvalue: mmsInfo.MAG_CODE,
                link: mmsInfo.MAG_CODE ? "USE PROCESSOR" : " ",
                ControlName: "ProcessorNum",
                keyName: "InsuPlan"
            },
            {
                head: "Bin ID",
                currentvalue: insuInfo.InsuCarrier ? insuInfo.InsuCarrier.BINNum : "",
                MMsvalue: mmsInfo.BIN_NO,
                link: mmsInfo.BIN_NO ? "USE Bin" : " ",
                ControlName: "BINNum",
                keyName: "InsuCarrier"
            },
            {
                head: "NCPDP VERSION",
                currentvalue: insuInfo.InsuTransmission
                    ? insuInfo.InsuTransmission.NCPDPVer
                    : " ",
                MMsvalue: mmsInfo.NCPDP_VER,
                link: mmsInfo.NCPDP_VER ? "USE NCPDP" : " ",
                ControlName: "NCPDPVer",
                keyName: "InsuTransmission"
            },
            {
                head: "COMM MODE",
                currentvalue: insuInfo.InsuTransmission
                    ? insuInfo.InsuTransmission.CommModeId
                    : " ",
                MMsvalue: mmsInfo.COMMMODE,
                link: mmsInfo.COMMMODE ? "USE COMM" : " ",
                ControlName: "CommModeId",
                keyName: "InsuTransmission"
            }
        ];
    }

    getMMSModelFrEdit(insuInfo, mmsInfo) {
        return [
            {
                head: "INSUCARRIER NAME",
                currentvalue: insuInfo.Organization ? insuInfo.Organization.Name : " ",
                MMsvalue: mmsInfo.IC_NAME,
                link: mmsInfo.IC_NAME ? "USE NAME" : " ",
                ControlName: "Name",
                keyName: "Organization",
                display: mmsInfo.IC_NAME
                    ? mmsInfo.IC_NAME === insuInfo.Organization.Name
                        ? false
                        : true
                    : false
            },
            {
                head: "ADDRESS",
                currentvalue: insuInfo.Address ? insuInfo.Address.AddressLine1 : " ",
                MMsvalue: mmsInfo.ADDRESS,
                link: mmsInfo.ADDRESS ? "USE ADDRESS" : " ",
                ControlName: "AddressLine1",
                keyName: "Address",
                display: mmsInfo.ADDRESS
                    ? mmsInfo.ADDRESS ===
                      insuInfo.Address.AddressLine1
                        ? false
                        : true
                    : false
            },
            {
                head: "TELEPHONE VOICE",
                currentvalue: insuInfo.Phone ? insuInfo.Phone.xPhone : "",
                MMsvalue: mmsInfo.PHONE_V,
                link: mmsInfo.PHONE_V ? "USE VOICE" : " ",
                ControlName: "xPhone",
                keyName: "Phone",
                display: mmsInfo.PHONE_V
                    ? mmsInfo.PHONE_V === insuInfo.Phone.xPhone
                        ? false
                        : true
                    : false
            },
            {
                head: "PROCESSOR ID #",
                currentvalue: insuInfo.InsuPlan ? insuInfo.InsuPlan.ProcessorNum : "",
                MMsvalue: mmsInfo.MAG_CODE,
                link: mmsInfo.MAG_CODE ? "USE PROCESSOR" : " ",
                ControlName: "ProcessorNum",
                keyName: "InsuPlan",
                display: mmsInfo.MAG_CODE
                    ? mmsInfo.MAG_CODE ===
                      insuInfo.InsuPlan.ProcessorNum
                        ? false
                        : true
                    : false
            },
            {
                head: "NCPDP VERSION",
                currentvalue: insuInfo.InsuTransmission
                    ? insuInfo.InsuTransmission.NCPDPVer
                    : " ",
                MMsvalue: mmsInfo.NCPDP_VER,
                link: mmsInfo.NCPDP_VER ? "USE NCPDP" : " ",
                ControlName: "NCPDPVer",
                keyName: "InsuTransmission",
                display: mmsInfo.NCPDP_VER
                    ? mmsInfo.NCPDP_VER ===
                      insuInfo.InsuTransmission.NCPDPVer
                        ? false
                        : true
                    : false
            },
        ];
    }
}

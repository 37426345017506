<app-drug-tab *ngIf="currTab" [resetToFirst]="resetToFirst" [ActiveTabId]="currTab" [drugId]="drugId" [drugInfo]="drugInfo$ | async" [drugFG]="drugFG" (addDrugResponse)="saveDrug($event)" (DrugActiveTab)="changDrugActiveTab($event)"></app-drug-tab>
<div class="editpatient--body newrx--body edit--drug insurance--body">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-9">
                <div class="row">
                    <div class="icon padding-0">
                        <img src="assets/dist/Icons/Icon_rx2.png" />
                    </div>
                    <div class="col-md-8 padding-0">
                        <div class="content--heading__title">
                            {{ headerTitle[currTab ? currTab : 'DRUGINFO'] }}
                        <span class="content--name" *ngIf="drugId">
                            | &nbsp; {{ drugFG?.value?.Drug?.Name | uppercase}}
                            {{ drugFG?.value?.Drug?.Strength ?drugFG?.value?.Drug?.Strength : "" | uppercase  }}
                            {{ drugFG?.value?.Drug?.DrugFormName ? drugFG?.value?.Drug?.DrugFormName : "" | uppercase}}
                        </span>
                        <span class="content--name" *ngIf="!drugId"> | &nbsp; Add New Drug </span>
                        </div>
                    </div>
                    <div class="col-md-3 edit-patient--group--icon padding-0">
                        <app-drug-common-header *ngIf="currTab" [openMMS]="openMMS" [systemData]="systemData$ | async" [dropDownValue]="dropDownValues"
                        [drugFg]="drugFG" [drugId]="drugId" [drugInfo]="drugInfo$ | async" (OpenCompndDrug)="openCompWithData($event)" [miscFG]="drugMiscFG" [miscInfo]="miscInfo" (ManFactForDrug)="manFactForDrug($event)"  [openNPatchFrmMMS]="openFromMMS" [MMSDataDPV]="MMSDataDPV"
                        (preferredStatus)="drugPreferred($event)"></app-drug-common-header>
                    </div>
                </div>
            </div>
            <div class="text-right pull-right col-md-3 padding-0 commonbut" *ngIf="currTab">
                <button *ngIf="drugFG?.value?.Drug?.DrugTypeId === 2" class="hotkey_success" title="Alt+G" appShortcutKey [AltKey]="'g'" (click)="openCompoundDrug($event)"><span>G</span> Comp. Drug </button>
                <button class="hotkey_success" id="saveButton" (click)="saveDrug()" appShortcutKey [AltKey]="'s'"><span>S</span> Save</button>
                <button class="hotkey_success mr-0"  (click)="onCancel('cancel')" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel</button>
            </div>
        </div>
        <div [ngSwitch]="currTab">
            <div *ngSwitchCase="tabEnum?.Patient1">
                <app-drug-info #DrugInfoTab (EmitOpenCompndDrug)="openCompoundDrug($event)" [formGroupInvalid]="formGroupInvalid" [drugId]="drugId" [systemData]="systemData$ | async" [drugInfo]="drugInfo$ | async"
                [drugInfoFG]="drugFG" [focusOn]="FocusOn" [dropDownValues]="dropDownValues" [checkPayRec]="checkPayRec" [rxBillStatusId]="rxBillStatusId" [isOriginalRx]="isOriginalRx" [islatestrefill]="islatestrefill" [isPartialRefStatus]="isPartialRefStatus" [InsuRestrictInfo]="insuResData"
                [doAddDrugToBucket]="doAddDrugToBucket" [frmAction]="frmAction" (EmitaddDrugToBuckComplete)="afterAddDrugToBuckComplete($event)" [drugFlow] = "drugFlow" [manuFactDrug]="manuFactDrug" [enableDrugClassFromEDI]="enableDrugClassFromEDI" [isDrugPreferred]="drugPreferredStatus"></app-drug-info>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient2">
                <app-drug-pricing [drugId]="drugId" [systemData]="systemData$ | async" [drugInfo]="drugInfo$ | async"
                [drugInfoFG]="drugFG" [priceSchedule]="priceSchedule$ | async"></app-drug-pricing>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient3">
                <app-drug-inventory [drugId]="drugId" [drugInfo]="drugInfo$ | async" [drugFG]="drugFG" [dropDownValues]="dropDownValues"
                (preferredStatus)="drugPreferred($event)"></app-drug-inventory>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient4">
                <app-drug-disp-hist [drugId]="drugId" [systemData]="systemData$ | async" [drugInfo]="drugInfo$ | async"></app-drug-disp-hist>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient5">
                <app-drug-misc-info [isFromCompound]="isFmComp" [drugId]="drugId" [miscFG]="drugMiscFG" [miscInfo]="miscInfo"></app-drug-misc-info>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient6">
                <app-edit-drug-counselling [drugId]="drugId" [resetDrugInfo]="resetDrugInfo" [systemData]="systemData$ | async" [drugFG]="drugFG"></app-edit-drug-counselling>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient7">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="row">
                            <div class="col">
                                <span class="eprx--header__heading">
                                    Notes
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="notes--block">
                            <div>
                                <div class="row">
                                    <div class="col-lg-12 padding--right__7" [formGroup]="drugFG?.get('Drug')">
                                        <div class="form-group">
                                            <textarea class="label--text__area col-md-12" autofocus formControlName="Remarks" value="{{(drugInfo$ | async)?.Drug?.Remarks}}"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient8">
                <app-drug-ins-restriction [drugId]="drugId" [drugInfoFG]="drugFG" [systemData]="systemData$ | async" [imgUrl]="drugFG?.value['imgURL']" [dropDownValues]="dropDownValues"
                [VaccineId]="drugFG?.value?.Drug?.VaccineGroupId"></app-drug-ins-restriction>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient9">
                <app-document-ref [documentFr]="{From: 'Drug', Id: this.drugId}" (RefreshParentEntity)="getDrugInfo('Patient9',true)"></app-document-ref>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient10">
                <app-drug-price-update [drugId]="drugId"></app-drug-price-update>
            </div>
            <div *ngIf="currTab === 'DRUGCATEGORY' && drugCategoryList?.['userDrugCats']?.length">
                <app-drug-category [systemData]="systemData$ | async" [drugCatFG]="drugCatFG" [drugCategoryList]="drugCategoryList"></app-drug-category>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient12">
                <app-notes-log [DrugInfo]="drugInfo$ | async" [NotesFr]="'Drug'"></app-notes-log>
            </div>
           <!-- <div *ngSwitchCase="tabEnum?.Patient13">
                <app-drug-bucket-pricing [drugId]="drugId"></app-drug-bucket-pricing>
            </div> -->
            </div>
    </div>
</div>
<app-cancel-pop-up *ngIf="popUpType === 'cancel'" (IsPopUpClosed)="onCancel($event)"></app-cancel-pop-up>

<ng-template #COMPDRUGPATCH let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="modalRef.close();modalRef=null">
        <h4 header>Confirmation</h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    This Compound's Unit AWP or Unit Cost Price has changed.
                </div>
                <div class="col-md-6">
                    Current AWP : ${{drugFG?.value?.Drug?.UnitPriceAWP}}
                </div>
                <div class="col-md-6">
                    New AWP : ${{(compoundDrugData?.totalAwp / compoundDrugData?.totalCount).toFixed(3)}}
                </div>
                <div class="col-md-6">
                    Current Cost : ${{drugFG?.value?.Drug?.UnitPriceCost}}
                </div>
                <div class="col-md-6">
                    New Cost : ${{(compoundDrugData?.totalCost / compoundDrugData?.totalCount).toFixed(3)}}
                </div>
                <div class="col-md-12">
                    Do you wish to update the prices?
                </div>
            </div>
        </span>
        <button autofocus footer class="hotkey_primary" (click)="patchCompValuesToDrug()" appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" (click)="modalRef.close();modalRef=null" appShortcutKey InputKey="n">
          <b>N</b>  NO
        </button>
    </app-custom-modal>
</ng-template>
<ng-template #MarkAsPrefDrug let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="cancelMarkPref();c('Close click')">
        <h4 header>Confirmation</h4>
        <span body>
            <span *ngIf="DrugList && DrugList?.length">
                The following NDC(s)
                <b>{{DrugList.join(", ") }}</b>
                is(are) already marked as preferred. Each group can only have one preferred NDC. Would you like to remove this NDC(s) and mark
                <b>{{selectedDrugItem}}</b> as preferred drug?
            </span>
        </span>
        <span footer>
            <button class="hotkey_primary" ngbAutofocus appShortcutKey InputKey="y"
            (click)="continueMarkPref()"><b>Y</b>YES</button>
            <button class="hotkey_primary" appShortcutKey InputKey="n"
            (click)="cancelMarkPref(); c('Close click')"><b>N</b>NO</button>
        </span>
    </app-custom-modal>
</ng-template>
